import React, { useState, useEffect, Fragment, memo } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import moment from "moment-timezone";
import "./next10.scss";
import Timer from "../Timer";
/* REDUX */
import actions from "../../redux/actions/selections";

import { CircularProgress } from "@material-ui/core";

import Lock from "../locks/Lock";
import { fullTrackCond, trackColor } from "../../config/racesUtils";

import SkeletonText from "../skeleton/SkeletonText";
import { Responsive } from "semantic-ui-react";
import { checkRouteDate } from "../../config/utils";
import { Row, Col } from "reactstrap";
import { country } from "../../config/constants";

const Next10 = (props) => {
  const [width, setWidth] = useState(window.innerWidth);


  const updateDimensions = () => {
    setWidth(width);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    props.getNextTen(props.showNextNR);
    let interval = setInterval(() => { props.getNextTenNoReload(props.showCTCN) }, 60000);
    return () => {
      window.removeEventListener("resize", updateDimensions);
      clearInterval(interval)
    };
  }, []);

  function Next10Wide({
    TName,
    TNum,
    RTime,
    HNumber,
    Percent,
    Addpercent,
    UBW,
    UBP,
    SBW,
    SBP,
    PID,
    Rname,
    Rdate,
    Rnum,
    dark,
    finalTrackCondition,
    isNR,
    trackCondition,
    showCTCN,
    BbUrl,
  }) {
    const nrBadgeRender = (isNR) => {
      if (isNR) {
        return (
          <div
            style={{
              backgroundColor: "#F64E60",
              borderRadius: 5,
              color: "white",
              fontSize: 12,
              textAlign: "center",
              minWidth: 20,
            }}>
            NR
          </div>
        );
      }
      return "";
    };
    if (country === 'aus') {
      return (

        <div
          key={PID}
          style={{
            cursor: 'pointer',
            marginTop: 8,
            padding: "2px 0px",
            backgroundColor: dark ? "#1D1D1C" : "white",
            borderRadius: "4px",
            minWidth: window?.innerWidth < 1200 ? "230px" : "180px",
          }}>
          <Col xs="12" md="12" lg="12" style={{ padding: "4px" }}>
            <Link
              style={{ textDecoration: 'none' }}
              to={`/horse-racing-tips/${checkRouteDate(
                moment(Rdate)?.format("DD-MM-YYYY")
              )}/${Rname}/R${Rnum}/${PID}`}>
              <Row style={{ margin: 0, padding: 0, paddingRight: 5 }}>
                <Col xs={8} style={{
                  margin: 0, padding: 0, color: '#0F72B5',
                  fontWeight: 700,
                  fontSize: '13px'
                }}>
                  {TName}
                </Col>
                <Col xs={4} style={{ margin: 0, padding: 0 }}>
                  <Row style={{ margin: 0, padding: 0 }}>
                    <Col
                      xs={5}
                      style={{
                        textAlign: "left",
                        fontSize: 13,
                        color: "#0f72b5",
                        fontWeight: "500",
                      }}>
                      R{TNum}
                    </Col>
                    <Col
                      xs={7}
                      style={{ textAlign: "right", margin: 0, padding: 0 }}>
                      {RTime}
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row style={{ margin: 0, padding: 0, paddingRight: 5 }}>
                <Col xs={6} style={{ margin: 0, padding: 0 }}>
                  <Row style={{ margin: 0, padding: 0 }}>
                    <Col
                      xs={8}
                      style={{ margin: 0, padding: 0, textAlign: "left" }}>
                      {HNumber}
                    </Col>
                    <Col xs={4}>
                      {showCTCN && (
                        <div
                          style={{
                            textAlign: "center",
                            background: trackColor(trackCondition),
                            fontWeight: 600,
                            fontSize: 12,
                            color: "white",
                            borderRadius: 5,
                            minWidth: 25,
                          }}>
                          {finalTrackCondition}
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col xs={6} style={{ margin: 0, padding: 0 }}>
                  <Row style={{ margin: 0, padding: 0 }}>
                    <Col xs={4} style={{ textAlign: "left" }}>
                      {nrBadgeRender(isNR)}
                    </Col>
                    <Col
                      xs={4}
                      style={{
                        textAlign: "left",
                        color: "#0f72b5",
                        fontSize: 12,
                      }}>
                      {Percent}
                    </Col>
                    <Col xs={4} style={{ textAlign: "left" }}>
                      {Addpercent}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <hr
                style={{
                  backgroundColor: "gray",
                  opacity: 0.4,
                  marginTop: "2px",
                }}
              />
            </Link>

            <Row style={{ margin: 0, padding: 0, paddingRight: 5 }}>
              {props.currentUser ?
                <Col xs={6} style={{ margin: 0, padding: 0 }}>
                  <a style={{ textDecoration: 'none' }} target="_blank" href={BbUrl} rel="noreferrer">
                    <Row style={{ margin: 0, padding: 0 }}>
                      <Col xs={4} style={{ margin: 0, padding: 0 }}>
                        <div
                          style={{
                            backgroundColor: "#e12b80",
                            color: "white",
                            fontWeight: 400,
                            borderRadius: "5px",
                            padding: "2px 4px",
                            fontSize: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginRight: "8px",
                            height: "20px",

                          }}>
                          BB
                        </div>
                      </Col>
                      <Col
                        xs={4}
                        style={{ margin: 0, padding: 0, textAlign: "center" }}>
                        {SBW}
                      </Col>
                      <Col
                        xs={4}
                        style={{ margin: 0, padding: 0, textAlign: "center" }}>
                        {SBP}
                      </Col>
                    </Row>
                  </a>
                </Col>
                : <Col xs={6} style={{ margin: 0, padding: 0 }}>
                  <Row style={{ margin: 0, padding: 0 }}>
                    <Col xs={4} style={{ margin: 0, padding: 0 }}>
                      <div
                        style={{
                          backgroundColor: "#e12b80",
                          color: "white",
                          fontWeight: 400,
                          borderRadius: "5px",
                          padding: "2px 4px",
                          fontSize: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: "8px",
                          height: "20px",
                        }}>
                        BB
                      </div>
                    </Col>
                    <Col
                      xs={4}
                      style={{ margin: 0, padding: 0, textAlign: "center" }}>
                      {SBW}
                    </Col>
                    <Col
                      xs={4}
                      style={{ margin: 0, padding: 0, textAlign: "center" }}>
                      {SBP}
                    </Col>
                  </Row>
                </Col>}
              {props.currentUser ?
                <Col xs={6} style={{ margin: 0, padding: 0 }}>
                  <a
                    style={{ textDecoration: 'none' }}
                    target="_blank"
                    rel="noreferrer"
                    href="https://b1.trickyrock.com/redirect.aspx?pid=31144281&bid=21418">
                    <Row style={{ margin: 0, padding: 0 }}>
                      <Col xs={4}>
                        <input
                          type="image"
                          style={{ width: '25px', height: '20px' }}
                          src={
                            "https://dnu5embx6omws.cloudfront.net/Logos/unibet/30x30@2x.png"
                          }
                          alt="Ul"
                        />
                      </Col>
                      <Col
                        xs={4}
                        style={{ margin: 0, padding: 0, textAlign: "center" }}>
                        {UBW}
                      </Col>
                      <Col
                        xs={4}
                        style={{ margin: 0, padding: 0, textAlign: "center" }}>
                        {UBP}
                      </Col>
                    </Row>
                  </a>
                </Col>
                : <Col xs={6} style={{ margin: 0, padding: 0 }}>
                  <Row style={{ margin: 0, padding: 0 }}>
                    <Col xs={4}>
                      <input
                        type="image"
                        style={{ width: '25px', height: '20px' }}
                        src={"https://dnu5embx6omws.cloudfront.net/Logos/unibet/30x30@2x.png"}
                        alt="Ul"
                      />
                    </Col>
                    <Col
                      xs={4}
                      style={{ margin: 0, padding: 0, textAlign: "center" }}>
                      {UBW}
                    </Col>
                    <Col
                      xs={4}
                      style={{ margin: 0, padding: 0, textAlign: "center" }}>
                      {UBP}
                    </Col>
                  </Row>
                </Col>}
            </Row>
          </Col>
        </div>
      );
    } else {
      return (
        <Link
          style={{ textDecoration: 'none' }}
          to={`/horse-racing-tips/${checkRouteDate(
            moment(Rdate)?.format("DD-MM-YYYY")
          )}/${Rname}/R${Rnum}/${PID}`}>
          <div
            key={PID}
            className="box-shadow"
            style={{
              cursor: 'pointer',
              marginTop: 8,
              padding: "5px 5px",
              backgroundColor: dark ? "#1D1D1C" : "white",
              borderRadius: "4px",
              minWidth: window?.innerWidth < 1200 ? "230px" : "180px",
              height: "75px"
            }}>

            <Col xs="12" md="12" lg="12" style={{ padding: "4px" }}>
              <Row style={{ margin: 0, padding: 0, paddingRight: 5 }}>
                <Col xs={8} style={{ margin: 0, padding: 0 }}>
                  <small style={{
                    color: '#0f72b5',
                    fontWeight: 500,
                    fontSize: '13px'
                  }}>{TName}</small>
                </Col>
                <Col xs={4} style={{ margin: 0, padding: 0 }}>
                  <Row style={{ margin: 0, padding: 0 }}>
                    <Col
                      xs={5}
                      style={{
                        textAlign: "left",
                        fontSize: 13,
                        color: "#0f72b5",
                        fontWeight: "500",
                      }}>
                      R{TNum}
                    </Col>
                    <Col
                      xs={7}
                      style={{ textAlign: "right", margin: 0, padding: 0 }}>
                      {RTime}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </div>
        </Link>
      );
    }
  }

  const TimerCheck = (RaceTime, formatted, racetime, rStatus) => {
    let currentTime = moment
      .duration(moment().format("HH:mm"))
      .asMilliseconds();
    var timeComp = RaceTime - currentTime;

    if (timeComp > 1 * 60 * 60 * 1000) {
      return formatted;
    } else {
      return (
        <div style={{ marginTop: "2px" }}>
          <Timer
            raceTimer={RaceTime}
            raceTime={racetime}
            raceStatus={rStatus}
          />
        </div>
      );
    }
  };

  const DateCheck = (CD, RD, RaceTime, formatted, racetime, rStatus) => {
    if (CD < RD) {
      return formatted;
    } else {
      return TimerCheck(RaceTime, formatted, racetime, rStatus);
    }
  };

  const equalselec = (first, second, third, fourth, nb1, nb2, nb3, nb4) => {
    if (
      Math.round(first) === Math.round(second) &&
      Math.round(second) === Math.round(third) &&
      Math.round(third) === Math.round(fourth)
    ) {
      return <span style={{ color: 'green' }}> {nb1 + " & " + nb2 + " & " + nb3 + " & " + nb4}</span>
    } else if (
      Math.round(first) === Math.round(second) &&
      Math.round(second) === Math.round(third)
    ) {
      return <span style={{ color: 'green' }}>{nb1 + " & " + nb2 + " & " + nb3}</span>
    } else if (Math.round(first) === Math.round(second)) {
      return <span style={{ color: 'green' }}>{nb1 + " & " + nb2}</span>
    } else {
      return <span style={{ color: 'green' }}>{nb1}</span>
    }
  };

  const equalrender = (first, second) => {
    if (Math.round(first - second) === 0) {
      return (
        <div
          style={{
            backgroundColor: "green",
            borderRadius: "4px",
            color: "white",
            fontSize: "11px",
            width: "20px",
            display: "flex",
            justifyContent: "center",
            marginTop: "2px",
            alignItems: "baseline",
            padding: "0px",
          }}>
          EQ
        </div>
      );
    } else
      return (
        <small style={{
          color: '#0f72b5 !important',
          fontWeight: 500,
          fontSize: '11px'
        }}>
          {"+" + Math.round(Number(first) - Number(second)) + "%"}
        </small>
      );
  };

  const equalodds = (odd) => {
    if (odd) {
      return odd;
    } else {
      return "--";
    }
  };

  const NextRender = () => {
    var next = props?.next10Tips?.map((zone, i) => {
      var curentDate = moment().format("YYYYMMDD");
      let d = moment(zone[0]?.date).format("YYYY-MM-DD");

      let zoneDate = moment(d, "YYYY-MM-DD").format("YYYY-MM-DD");

      let zonetime = moment(zone[0]?.date).format("HH:mm");

      var rDate =
        zoneDate?.split("-")[0] +
        zoneDate?.split("-")[1] +
        zoneDate?.split("-")[2];
      // moment(zoneDate, "YYYY-MM-DD").format("YYYYMMDD");
      var RTime = moment.duration(zonetime).asMilliseconds();

      var racetime = zonetime;
      var rStatus = zone[0]?.status;
      var finalTrackCondition = fullTrackCond(zone[0]?.track_condition);

      var Rname = zone[0]?.venue_full_name;
      var Rnum = zone[0]?.race_num;

      var selectionfirst;
      var selectionsecond;
      var selectionthird;
      var selectionfourth;

      var horsenb1;
      var horsenb2;
      var horsenb3;
      var horsenb4;

      var SBW;
      var SBP;

      var UBW;
      var UBP;
      var percentage;
      zone[0]?.horse?.map((index, i) => {
        if (zone[0]?.track_condition?.substring(0, 2) === "Go") {
          percentage = index?.points_per_good;
        } else if (zone[0]?.track_condition?.substring(0, 2) === "Fi") {
          percentage = index?.points_per_firm;
        } else if (zone[0]?.track_condition?.substring(0, 2) === "He") {
          percentage = index?.points_per_heavy;
        } else if (zone[0]?.track_condition?.substring(0, 2) === "So") {
          percentage = index?.points_per_soft;
        } else if (zone[0]?.track_condition === "Synthetic") {
          percentage = index?.points_per_synth;
        }

        if (i === 0) {
          selectionfirst = percentage;
          horsenb1 = index?.horse_no;
          if (index.bb_win) {
            SBW = Number(index?.bb_win).toFixed(2);
            SBP = Number(index?.bb_place).toFixed(2);
          } else {
            SBW = "-,-";
            SBP = "-,-";
          }
          if (index.ub_win) {
            UBW = Number(index?.ub_win).toFixed(2);
            UBP = Number(index?.ub_place).toFixed(2);
          } else {
            UBW = "-,-";
            UBP = "-,-";
          }
        } else if (i === 1) {
          selectionsecond = percentage;
          horsenb2 = index?.horse_no;
        } else if (i === 2) {
          selectionthird = percentage;
          horsenb3 = index?.horse_no;
        } else if (i === 3) {
          selectionfourth = percentage;
          horsenb4 = index?.horse_no;
        }
      });
      let BbId = zone[0]?.bb_id;
      let BbUrl = "https://www.boombet.com.au/racing/" + BbId + "/?Referrer=PTPTips";

      var formatted = moment(zonetime, "HH:mm").format("HH:mm");
      if (country === 'aus') {
        if (props?.showNextNR === true) {
          i === 0 ? zone : props?.next10Tips[i - 1], zone;
          return (
            <div
              className="next10card"
              key={i}
              style={{ marginRight: window.innerWidth < 1200 ? 8 : 0 }}>
              <Next10Wide
                BbUrl={BbUrl}
                TName={zone[0]?.venue_full_name}
                TNum={zone[0]?.race_num}
                RTime={
                  <small style={{
                    fontWeight: 500,
                    color: '#008000'
                  }}>
                    {DateCheck(
                      curentDate,
                      rDate,
                      RTime,
                      formatted,
                      racetime,
                      rStatus
                    )}
                  </small>
                }
                HNumber={
                  <Lock
                    data={
                      <small
                        style={{
                          fontSize: 13,
                          display: "flex",
                          alignItems: "baseline",
                          fontWeight: 500,
                          color: '#008000',
                        }}>
                        No.
                        {equalselec(
                          selectionfirst,
                          selectionsecond,
                          selectionthird,
                          selectionfourth,
                          horsenb1,
                          horsenb2,
                          horsenb3,
                          horsenb4
                        )}
                      </small>
                    }
                  />
                }
                Percent={
                  <Lock
                    data={
                      Math.round(selectionfirst)
                        ? Math.round(selectionfirst) + "%"
                        : "0%"
                    }
                  />
                }
                isNR={zone[0]?.is_na}
                Addpercent={
                  <Lock data={equalrender(selectionfirst, selectionsecond)} />
                }
                UBW={
                  <Lock
                    data={
                      <Responsive>
                        <small style={{ fontWeiht: 700 }} >
                          <span
                            style={{
                              color: 'black',
                              fontWeight: 'bold',
                              minWidth: '3px',
                              minHeight: '3px'
                            }}
                          >
                            ${equalodds(UBW)}
                          </span>
                        </small>
                      </Responsive>
                    }
                  />
                }
                UBP={
                  <Lock
                    data={
                      <Responsive>
                        <small style={{ fontWeiht: 700 }}>
                          <span
                            style={{
                              color: 'black',
                              fontWeight: 'bold',
                              minWidth: '3px',
                              minHeight: '3px'
                            }}>
                            ${equalodds(UBP)}
                          </span>
                        </small>
                      </Responsive>
                    }
                  />
                }
                SBW={
                  <Lock
                    data={
                      <Responsive>
                        <small style={{ fontWeiht: 700 }}>
                          <span
                            style={{
                              color: '#e12b80',
                              fontWeight: 'bold',
                              minWidth: '3px',
                              minHeight: '3px'
                            }}>
                            ${equalodds(SBW)}
                            {/* <b>N/A</b> */}
                          </span>
                        </small>
                      </Responsive>
                    }
                  />
                }
                SBP={
                  <Lock
                    data={
                      <Responsive>
                        <small style={{ fontWeiht: 700 }}>
                          <span
                            style={{
                              color: '#e12b80',
                              fontWeight: 'bold',
                              minWidth: '3px',
                              minHeight: '3px'
                            }}>
                            ${equalodds(SBP)}
                          </span>
                        </small>
                      </Responsive>
                    }
                  />
                }
                PID={zone[0]?.uuid}
                Rname={Rname}
                rDate={zone[0]?.date}
                Rnum={Rnum}
                pros={props}
                dark={props?.dark}
                finalTrackCondition={finalTrackCondition}
                trackCondition={zone[0]?.track_condition}
                showCTCN={props?.showCTCN}
                eq={equalselec(
                  zone[0]?.horse[0],
                  zone[0]?.horse[1]
                )}
              />
            </div>
          );
        } else if (props?.showNextNR === false && zone[0]?.is_na === false) {
          return (
            <div
              className="next10card"
              key={i}
              style={{ marginRight: window.innerWidth < 1200 ? 8 : 0 }}>
              <Next10Wide
                BbUrl={BbUrl}
                TName={zone[0]?.venue_full_name}
                TNum={zone[0]?.race_num}
                RTime={
                  <small style={{ fontWeight: 500, color: '#008000' }}>
                    {DateCheck(
                      curentDate,
                      rDate,
                      RTime,
                      formatted,
                      racetime,
                      rStatus
                    )}
                  </small>
                }
                HNumber={
                  <Lock
                    data={
                      <small
                        style={{
                          fontSize: 13,
                          display: "flex",
                          alignItems: "baseline",
                          fontWeight: 500,
                          color: '#008000'
                        }}>
                        No.
                        {equalselec(
                          selectionfirst,
                          selectionsecond,
                          selectionthird,
                          selectionfourth,
                          horsenb1,
                          horsenb2,
                          horsenb3,
                          horsenb4
                        )}
                      </small>
                    }
                  />
                }
                Percent={<Lock data={Math.round(selectionfirst) + "%"} />}
                isNR={zone[0]?.is_na}
                Addpercent={
                  <Lock data={equalrender(selectionfirst, selectionsecond)} />
                }
                UBW={
                  <Lock
                    data={
                      <small style={{ fontWeiht: 700 }}>
                        <span style={{
                          color: 'black',
                          fontWeight: 'bold',
                          minWidth: '3px',
                          minHeight: '3px'
                        }}
                        >
                          ${equalodds(UBW)}
                        </span>
                      </small>
                    }
                  />
                }
                UBP={
                  <Lock
                    data={
                      <small style={{ fontWeiht: 700 }}>
                        <span style={{
                          color: 'black',
                          fontWeight: 'bold',
                          minWidth: '3px',
                          minHeight: '3px'
                        }}>
                          ${equalodds(UBP)}
                        </span>
                      </small>
                    }
                  />
                }
                SBW={
                  <Lock
                    data={
                      <small style={{ fontWeiht: 700 }}>
                        <span style={{
                          color: '#e12b80',
                          fontWeight: 'bold',
                          minWidth: '3px',
                          minHeight: '3px'
                        }}>
                          ${equalodds(SBW)}
                          {/* <b>N/A</b> */}
                        </span>
                      </small>
                    }
                  />
                }
                SBP={
                  <Lock
                    data={
                      <small style={{ fontWeiht: 700 }}>
                        <span style={{
                          color: '#e12b80',
                          fontWeight: 'bold',
                          minWidth: '3px',
                          minHeight: '3px'
                        }}>
                          ${equalodds(SBP)}
                          {/* <b>N/A</b> */}
                        </span>
                      </small>
                    }
                  />
                }
                PID={zone[0]?.uuid}
                Rname={Rname}
                Rdate={zone[0]?.date}
                Rnum={Rnum}
                pros={props}
                dark={props?.dark}
                finalTrackCondition={finalTrackCondition}
                trackCondition={zone[0]?.track_condition}
                showCTCN={props?.showCTCN}
                eq={equalselec(
                  zone[0]?.horse[0],
                  zone[0]?.horse[1]
                )}
              />
            </div>
          );
        }
      } else {


        i === 0 ? zone : props?.next10Tips[i - 1], zone;
        return (
          <div
            className="next10card"
            key={i}
            style={{ marginRight: window.innerWidth < 1200 ? 8 : 0 }}>
            <Next10Wide

              TName={zone[0]?.venue_full_name}
              TNum={zone[0]?.race_num}
              RTime={
                <small style={{ fontWeight: 500, color: '#0F72B5 !important' }}>
                  {DateCheck(
                    curentDate,
                    rDate,
                    RTime,
                    formatted,
                    racetime,
                    rStatus
                  )}
                </small>
              }

              PID={zone[0]?.uuid}
              Rname={Rname}
              rDate={zone[0]?.date}
              Rnum={Rnum}
              pros={props}
              dark={props?.dark}
              finalTrackCondition={finalTrackCondition}
              trackCondition={zone[0]?.track_condition}
              showCTCN={props?.showCTCN}

            />
          </div>
        );
      }


    });
    return next;
  };
  return (
    <Fragment>
      {!props?.loadingNext10 ? (
        NextRender()
      ) : window.innerWidth < 1200 ? (
        <div
          style={{
            width: "100%",
            minHeight: 80,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <CircularProgress style={{ color: "green" }} size={34} />
        </div>

      ) : (
        <SkeletonText dark={props?.dark} />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  next10Tips: state.selectionReducer.dataNext,
  loadingNext10: state.selectionReducer.loadingNext,
  showNextNR: state.selectionReducer.showNextNR,
  currentUser: state.auth.currentUser,
  showCTCN: state.selectionReducer.showCTCN,
});

const mapDispatchToProps = (dispatch) => ({
  getSelectionsForDate: (date) => dispatch(actions.getSelectionsForDate(date)),
  getNextTenNoReload: (showNextNR) =>
    dispatch(actions.getNextTenNoReload(showNextNR)),
  getNextTen: (boolean) => dispatch(actions.getNextTen(boolean)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(memo(Next10)));
