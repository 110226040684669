import React, { Fragment, memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
/* REDUX */
import actions from "../../redux/actions/selections";
import { CircularProgress } from "@material-ui/core";
import { fullTrackCond, trackColor } from "../../config/racesUtils";
import SkeletonText from "../skeleton/SkeletonText";
import { checkRouteDate } from "../../config/utils";
import { Col, Row } from "reactstrap";
import "./last10.scss";
import { country } from "../../config/constants";

export const Last10 = (props) => {
  const [width, setWidth] = useState(window.innerWidth);


  const updateDimensions = () => {
    setWidth(width);
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);

    props.getLastTen(props.showLastNR);
    let interval = setInterval(() => {
      props.getLastTenNoReload(props.showLastNR)
    }, 120000)
    return () => {
      window.removeEventListener("resize", updateDimensions);
      clearInterval(interval)
    };
  }, []);

  const equalselec = (first, second, third, fourth, nb1, nb2, nb3, nb4) => {
    if (
      Math.round(first) === Math.round(second) &&
      Math.round(second) === Math.round(third) &&
      Math.round(third) === Math.round(fourth)
    ) {
      return nb1 + " & " + nb2 + " & " + nb3 + " & " + nb4;
    } else if (
      Math.round(first) === Math.round(second) &&
      Math.round(second) === Math.round(third)
    ) {
      return nb1 + " & " + nb2 + " & " + nb3;
    } else if (Math.round(first) === Math.round(second)) {
      return nb1 + " & " + nb2;
    } else {
      return nb1;
    }
  };

  const equalrender = (first, second) => {
    if (Math.round(first - second) === 0) {
      return (
        <div
          style={{
            backgroundColor: "green",
            borderRadius: "4px",
            color: "white",
            fontSize: "11px",
            width: "20px",
            display: "flex",
            justifyContent: "center",
            marginTop: "2px",
            alignItems: "baseline",
            padding: "0px",
          }}>
          EQ
        </div>
      );
    } else
      return (
        <small style={{
          color: '#0f72b5',
          fontWeight: 500,
          fontSize: '11px'
        }}>
          {"+" + Math.round(Number(first) - Number(second)) + "%"}
        </small>
      );
  };

  const TBACheck = (value1, value2, result) => {
    if (result === "TBA" || result === null) {
      return value1;
    } else {
      return value2;
    }
  };

  const resultCheck = (value) => {
    if (value) {
      if (value === "WON") {
        return "textwon";
      } else if (value === "2ND") {
        return "textSecond";
      } else if (value === "3RD") {
        return "textThird";
      } else if (value === "LOST") {
        return "textLost";
      }
    } else return "textLost";
  };

  const resultTextCheck = (value) => {
    if (value === "WON") {
      return "1st";
    } else if (value) {
      return value;
    } else return "TBA";
  };

  const nrBadgeRender = (isNR) => {
    if (isNR) {
      return (
        <div
          style={{
            backgroundColor: "#F64E60",
            borderRadius: 5,
            color: "white",
            fontSize: 12,
            textAlign: "center",
            minWidth: 20,
          }}>
          NR
        </div>
      );
    }
    return "";
  };

  const last = () => {
    var last = props.last10Tips?.map((zone, i) => {
      var selectionfirst;
      var selectionsecond;
      var selectionthird;
      var selectionfourth;
      var finalTrackCondition = fullTrackCond(zone[0]?.track_condition);

      var horsenb1;
      var horsenb2;
      var horsenb3;
      var horsenb4;

      var SBW;
      var SBP;

      var UBW;
      var UBP;

      var percentage;
      zone[0]?.horse?.map((index, i) => {
        if (zone[0]?.track_condition?.substring(0, 2) === "Go") {
          percentage = index?.points_per_good;
        } else if (zone[0]?.track_condition?.substring(0, 2) === "Fi") {
          percentage = index?.points_per_firm;
        } else if (zone[0]?.track_condition?.substring(0, 2) === "He") {
          percentage = index?.points_per_heavy;
        } else if (zone[0]?.track_condition?.substring(0, 2) === "So") {
          percentage = index?.points_per_soft;
        } else if (zone[0]?.track_condition === "Synthetic") {
          percentage = index?.points_per_synth;
        }
        if (i === 0) {
          selectionfirst = percentage;
          horsenb1 = index?.horse_no;
          if (index.bb_win) {
            SBW = Number(index?.bb_win)?.toFixed(2);
            SBP = Number(index?.bb_place)?.toFixed(2);
          } else {
            SBW = Number(0.0).toFixed(2);
            SBP = Number(0.0).toFixed(2);
          }
          if (index.ub_win) {
            UBW = Number(index?.ub_win)?.toFixed(2);
            UBP = Number(index?.ub_place)?.toFixed(2);
          } else {
            UBW = Number(0.0).toFixed(2);
            UBP = Number(0.0).toFixed(2);
          }
        } else if (i === 1) {
          selectionsecond = percentage;
          horsenb2 = index?.horse_no;
        } else if (i === 2) {
          selectionthird = percentage;
          horsenb3 = index?.horse_no;
        } else if (i === 3) {
          selectionfourth = percentage;
          horsenb4 = index?.horse_no;
        }
      });
      var Rname = zone[0]?.venue_full_name;
      var Rnum = zone[0]?.race_num;
      let BbId = zone[0]?.bb_id;
      let BbUrl =
        "https://www.boombet.com.au/racing/" + BbId + "/?Referrer=PTPTips";
      if (country === 'aus') {
        if (props.showLastNR) {
          return (
            <div
              key={i}
              className="last10card"
              style={{
                marginTop: 8,
                padding: "2px 0px",
                backgroundColor: props.dark ? "#1D1D1C" : "white",
                borderRadius: "4px",
                // padding: 2,
                minWidth: window?.innerWidth < 1200 ? "230px" : "180px",
                marginRight: window?.innerWidth < 1200 ? 8 : 0,
              }}>
              <Col
                xs="12"
                md="12"
                lg="12"
                style={{ padding: "4px" }}
                rel="nofollow">
                <Link
                  style={{ textDecoration: 'none' }}
                  to={`/horse-racing-tips/${checkRouteDate(
                    moment(zone[0]?.date)?.format("DD-MM-YYYY")
                  )}/${Rname?.split(" ").join("-")}/R${Rnum}/${zone[0]?.uuid}`}
                  replace
                  rel="nofollow">
                  <Row style={{ margin: 0, padding: 0, paddingRight: 5 }}>
                    <Col xs={8} style={{
                      margin: 0, padding: 0, fontWeight: 700,
                      fontSize: '13px',
                      color: '#0F72B5'
                    }}>
                      {zone[0]?.venue_full_name}
                    </Col>
                    <Col xs={4} style={{ margin: 0, padding: 0 }}>
                      <Row style={{ margin: 0, padding: 0 }}>
                        <Col
                          xs={6}
                          style={{
                            textAlign: "left",
                            fontSize: 13,
                            color: "#0f72b5",
                            fontWeight: "500",
                          }}>
                          R{zone[0]?.race_num}
                        </Col>
                        <Col xs={6} style={{ textAlign: "left" }}>
                          <small className={resultCheck(zone[0]?.ptp_result)}>
                            {resultTextCheck(zone[0]?.ptp_result)}
                          </small>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row style={{ margin: 0, padding: 0, paddingRight: 5 }}>
                    <Col xs={6} style={{ margin: 0, padding: 0 }}>
                      <Row style={{ margin: 0, padding: 0 }}>
                        <Col
                          xs={7}
                          style={{
                            margin: 0,
                            padding: 0,
                            textAlign: "left",
                            color: "green",
                            fontWeight: 600,
                          }}>
                          No.
                          {TBACheck(
                            equalselec(
                              selectionfirst,
                              selectionsecond,
                              selectionthird,
                              selectionfourth,
                              horsenb1,
                              horsenb2,
                              horsenb3,
                              horsenb4
                            ),
                            horsenb1,
                            zone[0]?.ptp_result
                          )}
                        </Col>
                        <Col xs={5}>
                          {props.showCTCL && (
                            <div
                              style={{
                                textAlign: "center",
                                background: trackColor(zone[0]?.track_condition),
                                fontWeight: 600,
                                fontSize: 12,
                                color: "white",
                                borderRadius: 5,
                                minWidth: 25,
                              }}>
                              {finalTrackCondition}
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={6} style={{ margin: 0, padding: 0 }}>
                      <Row style={{ margin: 0, padding: 0 }}>
                        <Col xs={4} style={{ textAlign: "left" }}>
                          {nrBadgeRender(zone[0]?.is_na)}
                        </Col>
                        <Col
                          xs={4}
                          style={{
                            textAlign: "left",
                            color: "#0f72b5",
                            fontSize: 12,
                          }}>
                          {Math.round(selectionfirst) + "%"}
                        </Col>
                        <Col xs={4} style={{ textAlign: "left" }}>
                          {
                            <small style={{
                              color: '#0f72b5',
                              fontWeight: 500,
                              fontSize: '11px'
                            }}>
                              <span style={{ textAlign: "left" }}>
                                {TBACheck(
                                  equalrender(selectionfirst, selectionsecond),
                                  "+" +
                                  Math.round(selectionfirst - selectionsecond) +
                                  "%",
                                  zone[0]?.ptp_result
                                )}
                              </span>
                            </small>
                          }
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr
                    style={{
                      backgroundColor: "gray",
                      opacity: 0.4,
                      marginTop: "2px",
                    }}
                  />
                </Link>

                <Row style={{ margin: 0, padding: 0, paddingRight: 5 }}>
                  <Col xs={6} style={{ margin: 0, padding: 0 }}>
                    <a style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer" href={BbUrl}>
                      <Row style={{ margin: 0, padding: 0 }}>
                        <Col xs={4} style={{ margin: 0, padding: 0 }}>
                          <div
                            style={{
                              backgroundColor: "#e12b80",
                              color: "white",
                              fontWeight: 400,
                              borderRadius: "5px",
                              padding: "2px 4px",
                              fontSize: "10px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginRight: "8px",
                              height: "20px",
                            }}>
                            BB
                          </div>
                        </Col>
                        <Col
                          xs={4}
                          style={{ margin: 0, padding: 0, textAlign: "center" }}>
                          <small>
                            <span style={{
                              color: '#e12b80',
                              fontWeight: 'bold'
                            }} >${SBW}</span>
                          </small>
                        </Col>
                        <Col
                          xs={4}
                          style={{ margin: 0, padding: 0, textAlign: "center" }}>
                          <small>
                            <span style={{
                              color: '#e12b80',
                              fontWeight: 'bold'
                            }} >${SBP}</span>
                          </small>
                        </Col>
                      </Row>
                    </a>
                  </Col>
                  <Col xs={6} style={{ margin: 0, padding: 0 }}>
                    <a
                      style={{ textDecoration: 'none' }}
                      target="_blank"
                      rel="noreferrer"
                      href="https://b1.trickyrock.com/redirect.aspx?pid=31144281&bid=21418">
                      <Row style={{ margin: 0, padding: 0 }}>
                        <Col xs={4}>
                          <div>
                            <input
                              type="image"
                              style={{ width: '25px', height: '20px' }}
                              src={
                                "https://dnu5embx6omws.cloudfront.net/Logos/unibet/30x30@2x.png"
                              }
                              alt="Ul"
                            />
                          </div>
                        </Col>
                        <Col
                          xs={4}

                          style={{
                            margin: 0, padding: 0, textAlign: "center", color: 'black',
                            fontWeight: 'bold'
                          }}>
                          <small>
                            <span style={{
                              color: 'black',
                              fontWeight: 'bold'
                            }}>${UBW}</span>
                          </small>
                        </Col>
                        <Col
                          xs={4}

                          style={{
                            margin: 0, padding: 0, textAlign: "center", color: 'black',
                            fontWeight: 'bold'
                          }}>
                          <small>
                            <span style={{
                              color: 'black',
                              fontWeight: 'bold'
                            }}>${UBP}</span>
                          </small>
                        </Col>
                      </Row>
                    </a>
                  </Col>
                </Row>
              </Col>
            </div>
          );
        } else if (!props.showLastNR && zone[0]?.is_na === false) {
          return (
            <div
              className="last10card"
              key={i}
              style={{
                marginTop: 8,
                cursor: 'pointer',
                padding: "2px 0px",
                backgroundColor: props.dark ? "#1D1D1C" : "white",
                borderRadius: "4px",
                // padding: 2,
                minWidth: window?.innerWidth < 1200 ? "230px" : "180px",
                marginRight: window?.innerWidth < 1200 ? 8 : 0,
              }}>
              <Col
                xs="12"
                md="12"
                lg="12"
                style={{ padding: "4px" }}
                rel="nofollow">
                <Link
                  style={{ textDecoration: 'none' }}
                  to={`/horse-racing-tips/${checkRouteDate(
                    moment(zone[0]?.date)?.format("DD-MM-YYYY")
                  )}/${Rname}/R${Rnum}/${zone[0]?.uuid}`}
                  replace
                  rel="nofollow">
                  <Row style={{ margin: 0, padding: 0, paddingRight: 5 }}>
                    <Col xs={8} style={{
                      margin: 0, padding: 0, fontWeight: 700,
                      fontSize: '13px', color: '#0F72B5'
                    }}>
                      {zone[0]?.venue_full_name}
                    </Col>
                    <Col xs={4} style={{ margin: 0, padding: 0 }}>
                      <Row style={{ margin: 0, padding: 0 }}>
                        <Col
                          xs={6}
                          style={{
                            textAlign: "left",
                            fontSize: 13,
                            color: "#0f72b5",
                            fontWeight: "500",
                          }}>
                          R{zone[0]?.race_num}
                        </Col>
                        <Col xs={6} style={{ textAlign: "left" }}>
                          <small className={resultCheck(zone[0]?.ptp_result)}>
                            {resultTextCheck(zone[0]?.ptp_result)}
                          </small>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row style={{ margin: 0, padding: 0, paddingRight: 5 }}>
                    <Col xs={6} style={{ margin: 0, padding: 0 }}>
                      <Row style={{ margin: 0, padding: 0 }}>
                        <Col
                          xs={7}
                          style={{
                            margin: 0,
                            padding: 0,
                            textAlign: "left",
                            color: "green",
                            fontWeight: 600,
                          }}>
                          No.
                          {TBACheck(
                            equalselec(
                              selectionfirst,
                              selectionsecond,
                              selectionthird,
                              selectionfourth,
                              horsenb1,
                              horsenb2,
                              horsenb3,
                              horsenb4
                            ),
                            horsenb1,
                            zone[0]?.ptp_result
                          )}
                        </Col>
                        <Col xs={5}>
                          {props.showCTCL && (
                            <div
                              style={{
                                textAlign: "center",
                                background: trackColor(zone[0]?.track_condition),
                                fontWeight: 600,
                                fontSize: 12,
                                color: "white",
                                borderRadius: 5,
                                minWidth: 25,
                              }}>
                              {finalTrackCondition}
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={6} style={{ margin: 0, padding: 0 }}>
                      <Row style={{ margin: 0, padding: 0 }}>
                        <Col xs={4} style={{ textAlign: "left" }}>
                          {nrBadgeRender(zone[0]?.is_na)}
                        </Col>
                        <Col
                          xs={4}
                          style={{
                            textAlign: "left",
                            color: "#0f72b5",
                            fontSize: 12,
                          }}>
                          {Math.round(selectionfirst) + "%"}
                        </Col>
                        <Col xs={4} style={{ textAlign: "left" }}>
                          {
                            <small style={{
                              color: '#0f72b5',
                              fontWeight: 500,
                              fontSize: '11px'
                            }}>
                              <span style={{ textAlign: "left" }}>
                                {TBACheck(
                                  equalrender(selectionfirst, selectionsecond),
                                  "+" +
                                  Math.round(selectionfirst - selectionsecond) +
                                  "%",
                                  zone[0]?.ptp_result
                                )}
                              </span>
                            </small>
                          }
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr
                    style={{
                      backgroundColor: "gray",
                      opacity: 0.4,
                      marginTop: "2px",
                    }}
                  />
                </Link>

                <Row style={{ margin: 0, padding: 0, paddingRight: 5 }}>
                  <Col xs={6} style={{ margin: 0, padding: 0 }}>
                    <a style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer" href={BbUrl}>
                      <Row style={{ margin: 0, padding: 0 }}>
                        <Col xs={4} style={{ margin: 0, padding: 0 }}>
                          <div
                            style={{
                              backgroundColor: "#e12b80",
                              color: "white",
                              fontWeight: 400,
                              borderRadius: "5px",
                              padding: "2px 4px",
                              fontSize: "10px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginRight: "8px",
                              height: "20px",
                            }}>
                            BB
                          </div>
                        </Col>
                        <Col
                          xs={4}
                          style={{ margin: 0, padding: 0, textAlign: "center" }}>
                          <small>
                            <span style={{
                              color: '#e12b80',
                              fontWeight: 'bold'
                            }}>${SBW}</span>
                          </small>
                        </Col>
                        <Col
                          xs={4}
                          style={{ margin: 0, padding: 0, textAlign: "center" }}>
                          <small>
                            <span style={{
                              color: '#e12b80',
                              fontWeight: 'bold'
                            }}>${SBP}</span>
                          </small>
                        </Col>
                      </Row>
                    </a>
                  </Col>
                  <Col xs={6} style={{ margin: 0, padding: 0 }}>
                    <a
                      style={{ textDecoration: 'none' }}
                      target="_blank"
                      rel="noreferrer"
                      href="https://b1.trickyrock.com/redirect.aspx?pid=31144281&bid=21418">
                      <Row style={{ margin: 0, padding: 0 }}>
                        <Col xs={4}>
                          <div>
                            <input
                              type="image"
                              style={{ width: '25px', height: '20px' }}
                              src={
                                "https://dnu5embx6omws.cloudfront.net/Logos/unibet/30x30@2x.png"
                              }
                              alt="Ul"
                            />
                          </div>
                        </Col>
                        <Col
                          xs={4}

                          style={{
                            margin: 0, padding: 0, textAlign: "center", color: 'black',
                            fontWeight: 'bold'
                          }}>
                          <small>
                            <span style={{
                              color: 'black',
                              fontWeight: 'bold'
                            }}>${UBW}</span>
                          </small>
                        </Col>
                        <Col
                          xs={4}

                          style={{
                            margin: 0, padding: 0, textAlign: "center", color: 'black',
                            fontWeight: 'bold'
                          }}>
                          <small>
                            <span style={{
                              color: 'black',
                              fontWeight: 'bold'
                            }}>${UBP}</span>
                          </small>
                        </Col>
                      </Row>
                    </a>
                  </Col>
                </Row>
              </Col>
            </div>
          );
        }
      } else {
        return (
          <Link
            style={{ textDecoration: 'none' }}
            key={i}
            to={`/horse-racing-tips/${checkRouteDate(
              moment(zone[0]?.date)?.format("DD-MM-YYYY")
            )}/${Rname?.split(" ").join("-")}/R${Rnum}/${zone[0]?.uuid}`}
            replace
            rel="nofollow">
            <div
              key={i}
              className="last10card"
              style={{
                marginTop: 8,
                padding: "5px 5px",
                backgroundColor: props.dark ? "#1D1D1C" : "white",
                borderRadius: "4px",
                height: "75px",
                minWidth: window?.innerWidth < 1200 ? "230px" : "180px",
                marginRight: window?.innerWidth < 1200 ? 8 : 0,
              }}>

              <Col
                xs="12"
                md="12"
                lg="12"
                style={{ padding: "4px" }}
                rel="nofollow">

                <Row style={{ margin: 0, padding: 0, paddingRight: 5 }}>
                  <Col xs={8} style={{
                    margin: 0, padding: 0, fontWeight: 700,
                    fontSize: '13px', color: '#0F72B5'
                  }}>
                    {zone[0]?.venue_full_name}
                  </Col>
                  <Col xs={4} style={{ margin: 0, padding: 0 }}>
                    <Row style={{ margin: 0, padding: 0 }}>
                      <Col
                        xs={6}
                        style={{
                          textAlign: "left",
                          fontSize: 13,
                          color: "#0f72b5",
                          fontWeight: "500",
                        }}>
                        R{zone[0]?.race_num}
                      </Col>
                      <Col xs={6} style={{ textAlign: "left" }}>
                        <small className={resultCheck(zone[0]?.ptp_result)}>
                          {resultTextCheck(zone[0]?.ptp_result)}
                        </small>
                      </Col>
                    </Row>
                  </Col>
                </Row>



              </Col>
            </div>
          </Link>
        );
      }
    });

    return last;
  };

  return (
    <Fragment>
      {!props.loadingLast10 ? (
        last()
      ) : window?.innerWidth < 1200 ? (
        <div
          style={{
            width: "100%",
            minHeight: 80,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <CircularProgress style={{ color: "green" }} size={34} />
        </div>
      ) : (
        <SkeletonText dark={props.dark} />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  last10Tips: state.selectionReducer.dataLast,
  loadingLast10: state.selectionReducer.loadingLast,
  showLastNR: state.selectionReducer.showLastNR,
  showCTCL: state.selectionReducer.showCTCL,
  currentUser: state.auth.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  getLastTen: (data) => dispatch(actions.getLastTen(data)),
  getLastTenNoReload: (data) => dispatch(actions.getLastTenNoReload(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Last10));
