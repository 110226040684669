import React from 'react';
import { GoogleLogin  } from '@react-oauth/google';
import jwt_code from "jwt-decode";
import { connect } from "react-redux";
import { toast } from 'react-toastify';

const authActions = require("../../redux/actions/auth");

const GoogleSignIn = (props) => {
    // const [firstName, setFirstName] = useState("");
    // const [lastName, setLastName] = useState("");
    // const [email, setEmail] = useState("");
    // const [loading, setLoading] = useState(false);

    const login = async (credentialResponse) => {
        if (credentialResponse) {
            var decode = jwt_code(credentialResponse.credential);
            let data = {
                first_name : decode?.given_name,
                last_name: decode?.family_name,
                email: decode?.email,
                email_verified : decode?.email_verified,
                provider : "google",
                provider_id: ""
                }
            await props.loginWithGoogle(data).then(() => {
                // console.log(props)
            })
            // console.log(decode)
        }
    };


    return (

        <GoogleLogin
            onSuccess={login}
            onError={() => {
                toast.error('Login Failed');
            }}
            useOneTap
            
        />
    )

}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    currentUser: state.auth.currentUser,

});

const mapDispatchToProps = (dispatch) => ({
    loginWithGoogle: (data) => dispatch(authActions.loginWithGoogle(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(GoogleSignIn)