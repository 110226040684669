import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import Last10 from '../last10/Last10'
import Next10 from '../next10/Next10'

export default function Thursday() {
  
  const render=()=>{
    return(<Col style={{ marginTop: "24px" , marginLeft: "15px",marginRight: "15px", fontSize:"15px"}}>
    <div ref={ref}>
    <Col xs={12}>
    <h1 style={{ textAlign: "center" }}>
    Thursday’s Free Horse Racing Tips
    </h1>
    <p style={{ textAlign: "justify" }}>
    If you want lady luck on your side next time you head to the races, partner with Past The Post Tips. We
can hook you up with the latest horse racing tips for Thursday and every other day of the week. Our
experts analyse historical data and the latest information about the race to help you find the horse with
the best chance of winning (according to our system).
    </p>
    <p style={{ textAlign: "justify" }}>
    PTP Tips combines historical performance data with information about the condition of the track, the
barrier each horse draws, jockey data, live odds, and much more.
    </p>
    <p style={{ textAlign: "justify" }}>
    The system followed by PTP Tips is highly sophisticated yet simple to understand. We pour through all
the data and rate every horse from highest to lowest. If you pick the first horse on our list for any race,
you are placing a bet on the horse that’s backed by all our data. If you want to see the data for yourself,
we also provide all that information to Past The Post members.
    </p>
    <h6 style={{ textAlign: "justify" }}>
    View Thursday’s Free Horse Racing Tips Now.
    </h6>
    <hr />
  </Col>
  <ul></ul>

  <Col xs={12}>
    <h2 style={{ textAlign: "center" }}>
    Australian Horse Racing Tips 7 Days a Week, Including Thursday!
    </h2>
    <p style={{ textAlign: "justify" }}>
    Are you planning on heading to the track this Thursday? Head to PTP tips first. Our free horse racing tips
for Thursday are up to date, insightful, and profitable. If you want to double check the odds on race day,
simply head to our  <Link to={"/horse-racing-tips/today"}>horse racing tips for today</Link> section on the day of the race.
    </p>
    <p style={{ textAlign: "justify" }}>
    If there have been any changes, including horse scratching, alterations to the track condition, or jockey
changes, they will be up to date in the today’s tips section on the day.
    </p>
    <p style={{ textAlign: "justify" }}>
    Start planning your next punt with our horse racing tips for Thursday, and head to today’s tips daily
between 9 am and 11 am to view the latest information.
    </p>
    <hr />
  </Col>

  <Col xs={12}>
    <h2 style={{ textAlign: "center" }}>
    Get the Best Free Horse Racing Tips for Thursday with Past The Post
    </h2>
    <p style={{ textAlign: "justify" }}>
    You need to get a little bit lucky when you place a bet, and Past The Post Tips can put luck on your side.
With an average winning rate of 21% over 4+ years, you’ll average a win every five starts with PTP Tips.
    </p>
    <p style={{ textAlign: "justify" }}>
    We offer you the best of both worlds; horse racing tips ahead of the big race and up to date data on the
day of the meet. To start planning and get horse racing tips for Thursday, sign up now.
    </p>
    <p style={{ textAlign: "justify" }}>
    The data and insights offered by PTP Tips are all-inclusive for every race in Australia. <Link to={"/register"}>Get started now!</Link>
    </p>
    <hr />
  </Col>
</div>
</Col>
  )
    
  }

  const ref = useRef(null)

  const [height, setHeight] = useState(0);

  useEffect(() => {
    console.log(ref)
    setHeight(ref.current.clientHeight)
  },[])

  

  if(window.innerWidth > 1200 ){
  return (
    <Row>
    <Col className="d-block col-md-2" style={{ overflowY:"auto",marginTop: "24px",  height:height, marginLeft:"10px"}}>
    <h5 style={{
    backgroundColor: "#142841",
    height: 32,
    borderRadius: 4,
    textAlign: "center",
    color: "white",
    fontWeight: "600",
    margin: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "350px",
    marginLeft: "auto",
    marginRight: "auto",
    position: "sticky",
    top: 0,
    zIndex:1,
  }}>Next PTP TIPS</h5>
    <Next10/>
    </Col>

{render()}
    

    <Col className="d-block col-2" style={{ overflowY:"auto",marginTop: "24px",  height:height, marginRight:"10px"}}>
    <h5 style={{
    backgroundColor: "#142841",
    height: 32,
    borderRadius: 4,
    textAlign: "center",
    color: "white",
    fontWeight: "600",
    margin: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "350px",
    marginLeft: "auto",
    marginRight: "auto",
    position: "sticky",
    top: 0,
    zIndex:1,
  }}>PREV PTP TIPS</h5>
    <Last10/>
    </Col>
  
    </Row>
  )


}else if(window.innerWidth < 1200 ){
  return(
    <Row>
    
    {render()}

    <Col style={{ marginTop: "24px" }}>
            <h5
              style={{
                backgroundColor: "#142841",
                height: 32,
                borderRadius: 4,
                textAlign: "center",
                color: "white",
                fontWeight: "600",
                margin: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "350px",
                marginLeft: "auto",
                marginRight: "auto",
              }}>
              NEXT PTP TIPS
            </h5>
            <div
              style={{
                display: "flex",
                overflowX: "scroll",
                overflowY: "hidden",
                marginBottom: "8px",
              }}>
              <Next10 />
            </div>
          </Col>

    <Col style={{ marginTop: "24px" }}>
            <h5
              style={{
                backgroundColor: "#142841",
                height: 32,
                borderRadius: 4,
                textAlign: "center",
                color: "white",
                fontWeight: "600",
                margin: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "350px",
                marginLeft: "auto",
                marginRight: "auto",
              }}>
              PREV PTP TIPS
            </h5>
            <div
              style={{
                display: "flex",
                overflowX: "scroll",
                overflowY: "hidden",
                marginBottom:"10px",
              }}>
              <Last10 />
            </div>
          </Col>
  
    </Row>
  )
}
}
