{/* eslint-disable*/ }
import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { search } from "../../config/config";
import {
  faWindowClose,
  faBars,
  faHorseHead,
  faPoll,
  faStar,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import './header.scoped.scss'

// import Flag from "react-world-flags";
// import countryCodes from "country-codes-list";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Row,
  Col,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  UncontrolledDropdown,
} from "reactstrap";

import Sidebar from "react-sidebar";
import Close from "react-ionicons/lib/MdClose";
import Auth from "./components/auth";
import User from "./components/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Search } from "semantic-ui-react";
import _ from "lodash";
import BottomNavigation from "reactjs-bottom-navigation";
import "reactjs-bottom-navigation/dist/index.css";
import HomeIcon from "react-ionicons/lib/IosHome";
import SearchIcon from "react-ionicons/lib/IosSearch";
import CloseIcon from "react-ionicons/lib/IosClose";


const categoryLayoutRenderer = ({ categoryContent, resultsContent }) =>
  resultsContent && (
    <div style={{ display: "flex", padding: 0 }}>
      <div className="name" style={{ padding: 10, width: "20%" }}>
        {categoryContent}
      </div>
      <div className="results" style={{ padding: "0 !important", margin: 0 }}>
        {resultsContent?.map((zone) => {

          return (
            <Link key={zone?.key} to={zone?.key}>
              {zone}
            </Link>
          );
        })}
      </div>
    </div>
  );

const authActions = require("../../redux/actions/auth");

const Header = (props) => {
  const [selectedFlag, setSelectedFlag] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [userDrop, setUserDrop] = useState(false);
  const [innerWidth, setInnerWidth] = useState(window?.innerWidth);
  const [value, setValue] = useState("Search Horses, Jockeys, Venues or Races");
  const [searchResult, setSearchResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [country, setCountry] = useState(
    localStorage.getItem("country") ? localStorage.getItem("country") : localStorage.setItem("country", "aus")
  );
  const [valueNav, setValueNav] = useState(valueNav)
  // const [notificationOpen, setNotificationOpen] = useState(false)
  const [searchOpen, setSearchOpen] = useState(false);
  const [registerPopup, setRegisterPopup] = useState(false);
  // const [gotowebsitePopup, setgotowebsitePopup] = useState(false);
  // const idleTimer = null;

  // const options = [
  //   { key: 1, text: "Unibet", value: 1 },
  //   { key: 2, text: "Boombet", value: 2 },
  // ];

  const closeModal = () => {
    setRegisterPopup(false);
  };

  // const closeModal1 = () => {
  //   setgotowebsitePopup(false);
  // };

  const onSetSidebarOpen = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleResize = () => {
    setInnerWidth(window?.innerWidth);
  };

  const handleNavTabChyange = (val) => {

    props.changeNavTab(val);
    if (val === 0) {
      props?.history.push("/");
    } else if (val === 1) {
      props?.history.push("/horse-racing-tips/today");
    } else if (val === 2) {
      props?.history.push("/horse-racing-tips/results/today");
    } else if (val === 3) {
      onSetSidebarOpen();
    }

    // if ()
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!props?.isLoggedIn) {
        if (props?.history.location.pathname !== "/register") {
          setRegisterPopup(true);
        }
      }
    }, 30 * 2 * 1000);
    window.addEventListener("resize", handleResize);
    handleLoadedTab();
    return () => {
      clearTimeout(timer);
      setSidebarOpen(false);
      window.removeEventListener("resize", handleResize);
    };

  }, [props?.isLoggedIn]);

  // const toggleUser = () => {
  //   setUserDrop(!userDrop);
  //   userDrop
  // };

  const toggleSearch = () => {
    setSearchOpen(!searchOpen);
  };

  const logout = () => {
    props.logoutUser(props.currentUser?.email);
    props?.history.push("/horse-racing-tips/today");
  };

  const handleSearchChange = async (e, { value }) => {
    setValue(value);
    let length = value?.length;
    // setState({ isLoading: true, value })
    if (length > 1) {
      setIsLoading(true);
      await search(value).then((res) => {
        setSearchResult(res?.data);
        setIsLoading(false);
      });
    }
  };

  const goRegister = () => {
    setRegisterPopup(false);
    props?.history.push("/register");
  };

  const handleCountryChange = (value) => {
    localStorage.setItem("country", value);
    setCountry(value);

    if (props.location.pathname === "/horse-racing-tips/today") {
      window.location.reload();
    } else {
      props?.history.push("/horse-racing-tips/today");
    }
  };

  const renderNav = () => {
    return (
      <Navbar
        color="red"
        dark
        expand="md"
        style={{
          zIndex: 999,
          height: 50,
          backgroundColor: "#142841",
          Color: "white",
          paddingLeft: "11%",
          paddingRight: "11%",
        }}
      >
        <NavbarBrand href="/">
          <input

            type={"image"}
            alt="Logo"
            src="../../favicon.png"
            width="30px"
            height="30px"
            className="logo-default max-h-30px"
          />
        </NavbarBrand>
        <NavbarToggler />
        <Collapse isOpen={false} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem
              style={{ marginLeft: 12, fontWeight: "600", marginTop: 8 }}
            >
              <Link style={{ textDecoration: 'none' }} to={`/horse-racing-tips/today`} className="linknav">
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: 16,
                    color: "white",
                  }}
                >
                  Selections{" "}
                  <FontAwesomeIcon
                    style={{ fontSize: "14px", marginBottom: "1px" }}
                    icon={faHorseHead}
                    size="1x"
                  />
                </span>
              </Link>
            </NavItem>

            <NavItem
              style={{ marginLeft: 22, fontWeight: "600", marginTop: 8 }}
            >
              <Link style={{ textDecoration: 'none' }} to={`/horse-racing-tips/results/today`} className="linknav">
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: 16,
                    color: "white",
                  }}
                >
                  Results{" "}
                  <FontAwesomeIcon
                    style={{ fontSize: "16px" }}
                    icon={faPoll}
                    size="1x"
                  />
                </span>
              </Link>
            </NavItem>

            <NavItem
              style={{ marginLeft: 22, fontWeight: "600", marginTop: 8 }}
            >
              <Link style={{ textDecoration: 'none' }} to={`/australian-bookmakers`} className="linknav">
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: 16,
                    color: "white",
                  }}
                >
                  Australian Bookmakers{" "}
                  <FontAwesomeIcon
                    style={{ fontSize: "16px" }}
                    icon={faStar}
                    size="1x"
                  />
                </span>
              </Link>
            </NavItem>
          </Nav>

          <Search
            className="trial"
            category
            categoryLayoutRenderer={categoryLayoutRenderer}
            loading={isLoading}
            onResultSelect={(e, data) => {
              props?.history.push(data?.result?.route);
              setValue("");
            }}
            onSearchChange={handleSearchChange}
            results={searchResult}
            placeholder={value}
            value={value}
            onFocus={() => {
              setValue("");
            }}
            onBlur={() => {
              setValue("Search Horses, Jockeys, Venues or Races");
            }}
          />

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {props?.currentUser ? <User /> : <Auth />}
          </div>
        </Collapse>
      </Navbar>
    );
  };

  const renderTablet = () => {
    return (
      <>
        <Navbar
          color="red"
          dark
          expand="md"
          style={{
            zIndex: 999,
            height: 60,
            backgroundColor: "#142841",
            Color: "white",
            paddingLeft: "4%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Link to={"/"} >
            <input
              type="image"
              alt="Logo"
              src="../../favicon.png"
              width="30px"
              height="30px"
              className="logo-default max-h-30px"
              style={{ position: "fixed", top: "10px", left: "6px" }}

            />
          </Link>
          {props.currentUser ? (
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  height: 40,
                  marginRight: 8,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {!searchOpen ? (
                  <SearchIcon
                    onClick={toggleSearch}
                    style={{ marginRight: 8 }}
                    color="white"
                    fontSize="24"
                  />
                ) : (
                  <div
                    style={{
                      backgroundColor: "#142841",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      width: "100%",
                    }}
                  >
                    <Search
                      category
                      categoryLayoutRenderer={categoryLayoutRenderer}
                      loading={isLoading}
                      onResultSelect={(e, data) => {
                        e.preventDefault();
                        setValue(data.result.title);
                        props.history.push(data.result.route);
                      }}
                      onSearchChange={handleSearchChange}
                      results={searchResult}
                      placeholder={value}
                      value={value}
                      onFocus={() => {
                        setValue("");
                      }}
                      onBlur={() => {
                        setValue("Search Horses, Jockeys, Venues or Races");
                      }}
                    />
                    <CloseIcon
                      onClick={toggleSearch}
                      color="white"
                      fontSize="28"
                    />
                  </div>
                )}
              </div>
              <UncontrolledDropdown>
                <Dropdown
                  isopen={userDrop ? userDrop : undefined}
                  style={{ backgroundColor: "transparent" }}
                >
                  <DropdownToggle>
                    <div
                      style={{
                        backgroundColor: "#334157",
                        padding: 6,
                        paddingRight: 12,
                        paddingLeft: 12,
                        borderRadius: 4,
                      }}
                    >
                      <strong
                        style={{ fontSize: 16, color: "white", fontWeight: 300 }}
                      >
                        {props.currentUser?.firstName?.charAt(0)}
                      </strong>
                    </div>
                  </DropdownToggle>
                  <DropdownMenu>
                    <div style={{ padding: 16 }}>
                      <h3>
                        {props.currentUser?.firstName +
                          " " +
                          props.currentUser?.lastName}
                      </h3>
                    </div>
                    <DropdownItem>
                      <Link style={{ textDecoration: 'none', color: 'black' }} to={"/account"}>My Profile</Link>
                    </DropdownItem>
                    <DropdownItem>
                      <Link style={{ textDecoration: 'none', color: 'black' }} to={"/blackbook"}>My Blackbook</Link>
                    </DropdownItem>
                    <DropdownItem onClick={logout}>Log Out</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </UncontrolledDropdown>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                padding: 0,
                margin: 0,
              }}
            >
              {!searchOpen ? (
                <SearchIcon
                  onClick={toggleSearch}
                  style={{ marginRight: 8 }}
                  color="white"
                  fontSize="24"
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                    padding: 0,
                    margin: 0,
                  }}
                >
                  <Search
                    category
                    categoryLayoutRenderer={categoryLayoutRenderer}
                    loading={isLoading}
                    onResultSelect={(e, data) => {
                      e.preventDefault();
                      props.history.push(data?.result?.route);
                      setValue(data?.result?.title);
                    }}
                    onSearchChange={handleSearchChange}
                    results={searchResult}
                    placeholder={value}
                    value={value}
                    onFocus={() => {
                      setValue("");
                    }}
                    onBlur={() => {
                      setValue("Search Horses, Jockeys, Venues or Races");
                    }}
                  />
                  <CloseIcon
                    onClick={toggleSearch}
                    color="white"
                    fontSize="28"
                  />
                </div>
              )}
              <Button
                color="success"
                style={{
                  height: 30,
                  width: "auto",
                  justifyContent: "center",
                  paddingTop: 3,
                  paddinBottom: 3,
                  paddingLeft: 8,
                  paddingRight: 8,
                }}
                onClick={() => props.history.push("/register")}
              >
                Register
              </Button>
              <span
                style={{
                  height: 25,
                  color: "white",
                  marginLeft: 8,
                  marginRight: 8,
                  opacity: "70%",
                }}
              >
                {" "}
                |{" "}
              </span>
              <Button
                color="light"
                style={{
                  height: 30,
                  justifyContent: "center",
                  paddingTop: 3,
                  paddinBottom: 3,
                  paddingLeft: 8,
                  paddingRight: 8,
                  color: "#142841",
                }}
                onClick={() => props.history.push("/login")}
              >
                Log In
              </Button>
            </div>
          )}
        </Navbar>
      </>
    );
  };

  const renderHeader = () => {
    if (innerWidth > 1300) {
      return renderNav();
    } else {
      return renderTablet();
    }
  };

  const renderSideBar = () => {
    return (
      <div style={{ width: "auto", padding: 10 }}>
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Col>
            <NavbarBrand
              href="/"
              style={{
                marginLeft: 8,
              }}
            >
              <input

                type="image"
                alt="Logo"
                src="../../favicon.png"
                width="30px"
                height="30px"
                className="logo-default max-h-20px"
              />
            </NavbarBrand>
          </Col>
          <Col
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              marginRight: 16,
            }}
          >
            <Close
              fontSize={"30"}
              color="white"
              style={{ cursor: "pointer", opacity: "50%" }}
              onClick={() => onSetSidebarOpen(false)}
            />
          </Col>
        </Row>
        <div style={{ padding: 16, color: "white" }}>
          <Link to={"/"} onClick={() => onSetSidebarOpen(false)}>
            <h1
              style={{
                fontSize: 16,
                marginTop: 20,
                color: "white",
                fontWeight: 400,
              }}
            >
              Home
            </h1>
          </Link>
          <Link
            to={"/horse-racing-tips/today"}
            onClick={() => onSetSidebarOpen(false)}
          >
            <h1
              style={{
                fontSize: 16,
                marginTop: 20,
                color: "white",
                fontWeight: 400,
              }}
            >
              Selection
            </h1>
          </Link>
          <Link
            to={"/horse-racing-tips/results/today"}
            onClick={() => onSetSidebarOpen(false)}
          >
            <h1
              style={{
                fontSize: 16,
                marginTop: 20,
                color: "white",
                fontWeight: 400,
              }}
            >
              Results
            </h1>
          </Link>
          {props?.currentUser && (
            <Link to={"/account"} onClick={() => onSetSidebarOpen(false)}>
              <h1
                style={{
                  fontSize: 16,
                  marginTop: 20,
                  color: "white",
                  fontWeight: 400,
                }}
              >
                Profile
              </h1>
            </Link>
          )}
          {props?.currentUser && (
            <Link to={"/blackbook"} onClick={() => onSetSidebarOpen(false)}>
              <h1
                style={{
                  fontSize: 16,
                  marginTop: 20,
                  color: "white",
                  fontWeight: 400,
                }}>
                My Blackbook
              </h1>
            </Link>
          )}

          <Link
            to={"/frequently-asked-questions-faq"}
            onClick={() => onSetSidebarOpen(false)}
          >
            <h1
              style={{
                fontSize: 16,
                marginTop: 20,
                color: "white",
                fontWeight: 400,
              }}
            >
              FAQ
            </h1>
          </Link>
          <Link to={"/contactus"} onClick={() => onSetSidebarOpen(false)}>
            <h1
              style={{
                fontSize: 16,
                marginTop: 20,
                color: "white",
                fontWeight: 400,
              }}
            >
              Contact
            </h1>
          </Link>

          <Link
            to={"/australian-bookmakers"}
            onClick={() => onSetSidebarOpen(false)}
          >
            <h1
              style={{
                fontSize: 16,
                marginTop: 20,
                color: "white",
                fontWeight: 400,
              }}
            >
              Australian Bookmakers
            </h1>
          </Link>
        </div>
      </div>
    );
  };

  const handleLoadedTab = () => {
    if (
      props?.history?.location?.pathname?.split("/")?.length > 0 &&
      props?.history?.location?.pathname?.split("/")[1] === ""
    ) {
      // set default value to 0
      props.changeNavTab(0);
    } else if (
      props?.history?.location?.pathname?.split("/")?.length > 0 &&
      props?.history?.location?.pathname?.split("/")[2] === "results"
    ) {
      // set value to 2
      props.changeNavTab(2);
    } else if (
      props?.history?.location?.pathname?.split("/")?.length > 0 &&
      props?.history?.location?.pathname?.split("/")[1] === "tips"
    ) {
      // set value to 1
      props.changeNavTab(1);
    } else {
      // set value to 3
      props.changeNavTab(3);
    }
  };

  const bottomNavItems = [
    {
      title: "Home",
      icon: <HomeIcon fontSize="15" color="grey" />,
      activeIcon: <HomeIcon color="white" fontSize="15" />,
    },

    {
      title: "Tips",
      icon: (
        <FontAwesomeIcon
          icon={faHorseHead}
          size="2x"
          style={{ width: 14 }}
          color="grey"
        />
      ),
      activeIcon: (
        <FontAwesomeIcon
          icon={faHorseHead}
          size="2x"
          style={{ width: 14 }}
          color="white"
        />
      ),
    },

    {
      title: "Results",
      icon: (
        <FontAwesomeIcon
          icon={faPoll}
          size="2x"
          style={{ width: 14 }}
          color="grey"
        />
      ),
      activeIcon: (
        <FontAwesomeIcon
          icon={faPoll}
          size="2x"
          style={{ width: 14 }}
          color="white"
        />
      ),
    },

    {
      title: "Menu",
      icon: (
        <FontAwesomeIcon
          icon={faBars}
          size="2x"
          style={{ width: 14 }}
          color="grey"
        />
      ),
      activeIcon: (
        <FontAwesomeIcon
          icon={faBars}
          size="2x"
          style={{ width: 14 }}
          color="white"
        />
      ),
    },
  ];

  return (
    <div style={{ height: sidebarOpen ? "100vh" : "auto" }}>
      <Sidebar
        sidebar={renderSideBar()}
        open={sidebarOpen}
        onSetOpen={onSetSidebarOpen}
        styles={{
          sidebar: {
            borderRadius: "20px",
            background: "#142841",
            height: "50%",
            marginTop: "auto",
            marginBottom: "auto",
            overflowY: "hidden",
            overflowX: "hidden",
          },
        }}
        pullRight
      >
        <></>
      </Sidebar>

      {renderHeader()}
      {window.innerWidth < 1300 && (
        <BottomNavigation
          items={bottomNavItems}

          onItemClick={(item) => handleNavTabChyange(item?.id)}
        />
      )}

    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
  isLoggedIn: state.auth.isLoggedIn,
  clientSession: state.auth.clientSession,
  errorStatus: state.auth.errorStatus,
  navTab: state.auth.navTab,
  headerLogo: state.auth.headerLogo,
  showLastNR: state.selectionReducer.showLastNR,
  showNextNR: state.selectionReducer.showNextNR,
  rOpen: state.auth.rOpen,
});
const mapDispatchToProps = (dispatch) => ({
  changeNavTab: (data) => dispatch(authActions.changeNavTab(data)),
  logoutUser: (email) => dispatch(authActions.logoutUser(email)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

