import {
  getBlackBookDetails,
  CreateblackBook,
  DeleteBlackBookInfo,
  UpBlkBook,
} from "../../config/config";
import { toast } from "react-toastify";
import { LOGOUT_USER } from './auth'
//import moment from 'moment-timezone'

export const GET_BLACKBOOK_REQUEST = "GET_BLACKBOOK_REQUEST";
export const GET_BLACKBOOK_SUCCESS = "GET_BLACKBOOK_SUCCESS";
export const GET_BLACKBOOK_ERROR = "GET_BLACKBOOK_ERROR";

export const ADD_BLACKBOOK_STARTED = "ADD_BLACKBOOK_STARTED";
export const ADD_BLACKBOOK_END = "ADD_BLACKBOOK_END";

export const DELETE_BLACKBOOK_STARTED = "DELETE_BLACKBOOK_STARTED";
export const DELETE_BLACKBOOK_END = "DELETE_BLACKBOOK_END";

export const UPDATE_BLACKBOOK_STARTED = "UPDATE_BLACKBOOK_STARTED";
export const UPDATE_BLACKBOOK_END = "UPDATE_BLACKBOOK_END";



const actions = {
  getBlackBookDetails: (uuid) => async (dispatch) => {
    dispatch({
      type: GET_BLACKBOOK_REQUEST,
    })
    await getBlackBookDetails(uuid).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: GET_BLACKBOOK_SUCCESS,
          payload: {
            blackbookDetails: res?.data?.data,
          },
        })
      } else {
        toast.error(res?.data?.message || 'Get All BlackBook Details error')
        if (res.status === 401) {
          dispatch({ type: LOGOUT_USER })
        }
        dispatch({
          type: GET_BLACKBOOK_ERROR,
          payload: {
            message: res?.data?.error || res?.data?.message || 'GET BLACKBOOK error',
          },
        })
      }
    })
  },

  CreateblackBook: (data, country) => async (dispatch) => {
    dispatch({
      type: ADD_BLACKBOOK_STARTED,
    })
    await CreateblackBook(data, country).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: ADD_BLACKBOOK_END,
        })
        toast.success(res?.message || 'Blackbook Succesfully created')
      } else {
        toast.error(res?.data?.message || 'Add BlackBook Error')
        if (res.status === 401) {
          dispatch({ type: LOGOUT_USER })
        }
        dispatch({
          type: ADD_BLACKBOOK_END,
        })
      }
    })
  },

  DeleteBlackBookInfo: (uuid) => async (dispatch) => {
    dispatch({
      type: DELETE_BLACKBOOK_STARTED,
    })
    await DeleteBlackBookInfo(uuid).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: DELETE_BLACKBOOK_END,
        })
        toast.success('Delete from blackbook')
      } else {
        toast.error(res?.data?.message || 'DELETE BlackBook Error')
        if (res.status === 401) {
          dispatch({ type: LOGOUT_USER })
        }
        dispatch({
          type: DELETE_BLACKBOOK_END,
        })
      }
    })
  },

  UpBlkBook: (data, uuid) => async (dispatch) => {
    dispatch({
      type: UPDATE_BLACKBOOK_STARTED,
    })
    await UpBlkBook(data, uuid).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: UPDATE_BLACKBOOK_END,
        })
      } else {
        toast.error(res?.data?.message || 'Update BlackBook Error')
        if (res.status === 401) {
          dispatch({ type: LOGOUT_USER })
        }
        dispatch({
          type: UPDATE_BLACKBOOK_END,
        })
      }
    })
  },

}
export default actions;
