import moment from "moment-timezone";
import React from "react";
import { Link } from "react-router-dom";

export function checkRouteDate(date) {
  var today = moment().format("DD-MM-YYYY");
  var tomorrow = moment().add(1, "day").format("DD-MM-YYYY");
  var yesterday = moment().subtract(1, "day").format("DD-MM-YYYY");

  if (date === today) {
    return "today";
  } else if (date === tomorrow) {
    return "tomorrow";
  } else if (date === yesterday) {
    return "yesterday";
  } else {
    return moment(date, "DD-MM-YYYY").format("DD-MM-YYYY");
  }
}

export function transferRouteRaces(matchDate) {
  if (matchDate === "today") {
    return "today";
  } else if (matchDate === "tomorrow") {
    return "tomorrow";
  } else if (matchDate === "yesterday") {
    return "yesterday";
  }
  return moment(matchDate, "DD-MM-YYYY").format("DD-MM-YYYY");
}

export function transferRouteRacesDispatch(matchDate) {
  if (matchDate === "today") {
    return moment().format("YYYY-MM-DD");
  } else if (matchDate === "tomorrow") {
    return moment().add(1, "day").format("YYYY-MM-DD");
  } else if (matchDate === "yesterday") {
    return moment().subtract(1, "day").format("YYYY-MM-DD");
  }
  return moment(matchDate, "DD-MM-YYYY").format("YYYY-MM-DD");
}

export function transferRouteRacesDD(matchDate) {
  if (matchDate === "today") {
    return moment().format("DD-MM-YYYY");
  } else if (matchDate === "tomorrow") {
    return moment().add(1, "day").format("DD-MM-YYYY");
  } else if (matchDate === "yesterday") {
    return moment().subtract(1, "day").format("DD-MM-YYYY");
  }
  return moment(matchDate, "DD-MM-YYYY").format("DD-MM-YYYY");
}

export function linkToRacePageWhileReturningDataToTable(
  data,
  date,
  venue,
  rnum,
  point_id
) {

  var baseDate = moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
  var today = moment().format("DD-MM-YYYY");
  var tomorrow = moment().add(1, "day").format("DD-MM-YYYY");
  var yesterday = moment().subtract(1, "day").format("DD-MM-YYYY");
  var meetdate;
  if (baseDate === today) {
    meetdate = "today";
  } else if (baseDate === tomorrow) {
    meetdate = "tomorrow";
  } else if (baseDate === yesterday) {
    meetdate = "yesterday";
  } else {
    meetdate = moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
  }
  return (
    <Link
      style={{ color: "inherit" }}
      to={`/horse-racing-tips/${meetdate}/${venue
        ?.split(" ")
        ?.join("-")}/R${rnum}/${point_id}`}>
      {data}
    </Link>
  );
}

export function bookie(bookie, disableB) {
  if (!disableB) {
    if (bookie === "Unibet") {
      return "https://b1.trickyrock.com/redirect.aspx?pid=31144281&bid=21418";
    } else if (bookie === "sportsBetting") {
      return "https://www.boombet.com.au/join/?Referrer=PTPTips";
    }
  }
}

export function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}




