import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment-timezone";
import Countdown from "react-countdown";
// import schedule from 'node-schedule';
import UpTimer from "./uptimer";

const pTimer = (props) => {
  const [state, setState] = useState({
    count: 0,
    min: 0,
    up: false,
    current: Number(
      moment.duration(moment().format("HH:mm:ss")).asMilliseconds()
    ),
  });
  let interval;
  useEffect(() => {
    interval = setInterval(() => {
      const raceTime = props?.raceTimer;
      setState({
        current: Number(
          moment.duration(moment().format("HH:mm:ss")).asMilliseconds()
        ),
      });
      const Time = raceTime - state.current;
      if (Time <= 0) {
        setState({ up: true });
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  const raceTime = props?.raceTimer;
  const Time = raceTime - state.current;
  const Timer = Time;
  const renderCounter = () => {
    if (Time <= 5 * 60 * 1000 && Time >= 1 * 60 * 1000) {
      return (
        <Countdown
          date={Date.now() + Timer}
          onComplete={() => setState({ up: true })}
          renderer={renderFive}
        />
      );
    } else if (Time < 60 * 60 * 1000 && Time > 5 * 60 * 1000) {
      return (
        <Countdown
          style={{ color: "red" }}
          date={Date.now() + Timer}
          renderer={rendererMin}
        />
      );
    } else if (Time < 1 * 60 * 1000) {
      return (
        <Countdown
          date={Date.now() + Timer}
          onComplete={() => setState({ up: true })}
          renderer={renderseconds}
        />
      );
    } else if (Time >= 60 * 60 * 1000) {
      return <Countdown date={Date.now() + Timer} renderer={renderHour} />;
    }
  };

  const renderAll = () => {
    let Time =
      raceTime - moment.duration(moment().format("HH:mm:ss")).asMilliseconds();
    if (props?.raceStatus === "Closed") {
      clearInterval(interval);
      return <div>TBA</div>;
    } else {
      if (state.up) {
        return (
          <UpTimer
            status={props.raceStatus}
            time={Time / 1000}
          />
        );
      } else {
        return renderCounter();
      }
    }
  };

  ////TIMER/////
  const rendererMin = ({ minutes }) => {
    // if (seconds < 10) {
    return <span style={{ width: "auto" }}>{minutes}m</span>;
    // } else {
    //   return <span style={{ width: "auto" }}>{minutes}m</span>;
    // }
  };

  const renderFive = ({ minutes, seconds }) => {
    if (seconds < 10) {
      return (
        <span style={{ color: "red" }}>
          {minutes}m 0{seconds}s
        </span>
      );
    } else {
      return (
        <span style={{ color: "red" }}>
          {minutes}m {seconds}s
        </span>
      );
    }
  };

  const renderHour = ({ hours }) => {
    return <span>{hours}h</span>;
  };

  const renderseconds = ({ seconds }) => {
    return <span style={{ color: "red" }}>{seconds}s</span>;
  };

  return <div style={props?.style}>{renderAll()}</div>;
};

export default pTimer;
