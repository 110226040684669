import {
  GET_BLACKBOOK_REQUEST,
  GET_BLACKBOOK_SUCCESS,
  GET_BLACKBOOK_ERROR,
  ADD_BLACKBOOK_STARTED,
  ADD_BLACKBOOK_END,
  DELETE_BLACKBOOK_STARTED,
  DELETE_BLACKBOOK_END,
  UPDATE_BLACKBOOK_STARTED,
  UPDATE_BLACKBOOK_END,

} from "../actions/blackbook";

const defaultState = {
  loadingbk: false,
  blackbookList: [],
  addBlackBook: false,
  delBlackBook: false,
  upBlackBook: false,
};

function blackbookReducer(state = defaultState, action) {
  switch (action.type) {
    case GET_BLACKBOOK_REQUEST:
      return Object.assign({}, state, {
        loadingbk: true,
      });
    case GET_BLACKBOOK_SUCCESS:
      return Object.assign({}, state, {
        loadingbk: false,
        blackbookList: action?.payload?.blackbookDetails,
      });
    case GET_BLACKBOOK_ERROR:
      return Object.assign({}, state, {
        loadingbk: false,
        blackbookList: [],
      });

    case ADD_BLACKBOOK_STARTED:
      return Object.assign({}, state, {
        addBlackBook: true,
      });
    case ADD_BLACKBOOK_END:
      return Object.assign({}, state, {
        addBlackBook: false,
      });

    case DELETE_BLACKBOOK_STARTED:
      return Object.assign({}, state, {
        delBlackBook: true,
      });
    case DELETE_BLACKBOOK_END:
      return Object.assign({}, state, {
        delBlackBook: false,
      });

    case UPDATE_BLACKBOOK_STARTED:
      return Object.assign({}, state, {
        upBlackBook: true,
      });
    case UPDATE_BLACKBOOK_END:
      return Object.assign({}, state, {
        upBlackBook: false,
      });


    default:
      return state;
  }
}

export default blackbookReducer;
