import React, { useEffect, useState } from "react";

const uptimer = (props) => {
  let time;
  if (props?.time < 0) {
    time = Number(props?.time) * -1;
  } else {
    time = Number(props?.time);
  }
  const [state, setState] = useState({
    count: Number(time) - Number(Math.trunc(time / 60)) * 60,
    min: Number(Math.trunc(time / 60)),
  });

  useEffect(() => {
    let interval = setInterval(() => {
      const count = state.count;
      setState((prevState) => ({
        count: Number(prevState.count) + 1,
      }));

      if (count >= 59) {
        setState((prevState) => ({
          min: Number(prevState.min) + 1,
          count: 0,
        }));
      }
      if (count < 0) {
        setState({
          count: 0,
        });
      }
      if (props?.status === "Closed") {
        clearInterval(interval);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const renderAll = () => {
    if (props?.time) {
      return (
        <div style={{ color: "red" }}>
          -{state.min}:{state.count < 10 ? `0${state.count}` : state.count}
        </div>
      );
    } else {
      return <p>...</p>;
    }
  };
  return renderAll();
};
export default uptimer;
