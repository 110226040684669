import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
/* REDUX */
import actions from "../../redux/actions/last10winners";
import { checkRouteDate } from "../../config/utils";
import "./Last10Winners.scss";
import { Grid, Tab, Tabs } from "@material-ui/core";

const last10winners = (props) => {
  useEffect(() => {
    props.fetchWinners();
  }, []);

  const last = () => {
    var date;
    var Tname;
    var UrlName;
    var racenum;
    var hnum;
    // var odd;
    var last = props.info?.map((index, i) => {
      date = moment(index?.race?.date).format("YYYY-MM-DD");
      Tname = index?.race?.venue?.full_name;
      UrlName = Tname?.split(" ")?.join("-");
      racenum = index?.race?.race_num;
      hnum = index?.horse_no;
      var PID = index?.race?.uuid;

      let odd;
      if (index?.ub_win >= index?.bb_win) {
        odd = Number(index?.ub_win?.toFixed(2));
      } else {
        odd = Number(index?.bb_win?.toFixed(2));
      }
      return (
        <Tab value={1} style={{ color: 'none' }} key={i} label=<Link
          to={`/horse-racing-tips/${checkRouteDate(
            moment(index?.race?.date)?.format("DD-MM-YYYY")
          )}/${UrlName}/R${racenum}/${PID}`}
          key={i}
          style={{ color: "none", backgroundColor: 'none', textDecoration: 'none' }}
          rel="nofollow">
          <div
            className="last10"
            style={{
              minWidth: "200px",
              marginRight: "16px",
              borderRadius: "10px",
              backgroundColor: "white",
              textAlign: "center",
              padding: 10,
            }}>
            <div
              style={{
                fontSize: 13,
                height: 20,
                backgroundColor: "#44BD32",
                borderRadius: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <strong style={{ color: "white" }}>{date}</strong>
            </div>
            <div
              style={{
                marginTop: 8,
                marginBottom: 8,
                fontSize: 13,
                fontWeight: "bold",
                color: "black",
              }}>
              {Tname}
            </div>

            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div
                style={{ fontSize: 14, fontWeight: "bold", color: "#44bd32" }}>
                R{racenum}
              </div>
              <div style={{ fontSize: 13, fontWeight: "bold", color: "black" }}>
                No.{hnum}
              </div>
              <div
                style={{ fontSize: 13, fontWeight: "bold", color: "#fcb318" }}>
                ${odd}
              </div>
            </div>
          </div>
        </Link>
        />
      );
    });
    return last;
  };

  return (
    <Grid spacing={1} container>
      <Grid item xs={12}>
        <h2
          style={{
            textAlign: "center",
            fontWeight: "bolder",
            marginBottom: "-5px",
          }}>
          Previous Winners At Odds
        </h2>
      </Grid>
      <Grid item xs={12}>
        <hr style={{ border: 0, height: "1px", backgroundImage: "linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 202, 34, 0.75), rgba(0, 0, 0, 0))" }} />
      </Grid>
      <Grid item xs={12}>
        <Tabs
          value={1}
          TabIndicatorProps={{ style: { backgroundColor: 'transparent' } }}
          style={{ color: 'none', display: 'flex' }}
          orientation="horizontal"
          scrollButtons='auto'
          variant='scrollable'
          textColor="primary"
          indicatorColor="primary"
        >
          {last()}
        </Tabs>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  info: state.lastWinnersReducer.info,
});

const mapDispatchToProps = (dispatch) => ({
  fetchWinners: () => dispatch(actions.fetchWinners()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(last10winners)
);
