import { NextTojump } from "../../config/config";
import { toast } from 'react-toastify';

export const NextTojump_FETCH_STARTED = "NextTojump_FETCH_STARTED";
export const NextTojump_FETCH_SUCCESS = "NextTojump_FETCH_SUCCESS";
export const NextTojump_FETCH_ERROR = "NextTojump_FETCH_ERROR";

const actions = {
  NextTojump: () => async (dispatch) => {
    try {
      dispatch({
        type: NextTojump_FETCH_STARTED,
      });

      const response = await NextTojump();
      if (response?.status === 200 ) {
      dispatch({
        type: NextTojump_FETCH_SUCCESS,
        payload: {
          info: response?.info,
        },
      })} else {
        dispatch({
          type: NextTojump_FETCH_ERROR,
          
        
        })
        toast.error("Error getting next to jump Data")
      };
    } catch (error) {
      console.log(error);
      dispatch({
        type: NextTojump_FETCH_ERROR,
      });
    }
  },
};

export default actions;
