import React, { useState } from "react";
// import { FaBook } from "react-icons/fa";
// import { BiLogOut } from "react-icons/bi";
// import { MdManageAccounts } from "react-icons/md";
// import LogoutIcon from '@material-ui/icons/Logout';
import MenuBook from '@material-ui/icons/MenuBook';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// import LogoutIcon from '@material-ui/icons/';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import {
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import "../styles.scss";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
const authActions = require("../../../redux/actions/auth");

const User = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const { currentUser } = props;
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const logout = () => {
    props.logoutUser(currentUser.email);
    setIsOpen(false);
  };

  const firstName = currentUser?.firstName;
  const lastName = currentUser?.lastName;
  const fullName = firstName + " " + lastName;
  return (
    <div className="topbar">
      {/*begin::User*/}
      <Dropdown isOpen={isOpen} toggle={toggle}>
        {/*begin::Toggle*/}

        <DropdownToggle
          className="topbar-item"
          style={{ backgroundColor: "transparent", borderWidth: 0 }}>
          <div className="btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto">
            <span className="text-white opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1">
              Hi,
            </span>
            <span className="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline">
              {firstName}
            </span>
            {/* <span style={{ marginLeft: 10 }} className="symbol symbol-35">
              <span className="symbol-label text-dark font-size-h5 font-weight-bold bg-white-o-30" >
                {firstName?.charAt(0)}
              </span>
            </span> */}
          </div>
        </DropdownToggle>
        {/*end::Toggle*/}

        {/*begin::Dropdown*/}
        <DropdownMenu right className="dropdown-menu-lg p-0">
          {/*begin::Header*/}
          <div className="d-flex align-items-center p-8 rounded-top">
            {/*begin::Symbol*/}

            {/*end::Symbol*/}
            {/*begin::Text*/}
            <div className="text-dark m-0 flex-grow-1 mr-3 text-center">
              <h2><b>{fullName}</b></h2>
              <hr />
            </div>
            {/*end::Text*/}
          </div>
          <div
            className="separator separator-solid"
          />
          {/*end::Header*/}
          {/*begin::Nav*/}
          <div className="navi navi-spacer-x-0 pt-5">
            {/*begin::Item*/}
            <DropdownItem className="navi-item px-8">
              <div className="navi-link">
                <div className="navi-icon mr-2">
                  <i className="flaticon2-calendar-3 text-success" />
                </div>
                <Link style={{ textDecoration: 'none' }} to="/account">
                  <div className="navi-text">
                    <div style={{ color: 'black' }} className="font-weight-bold">
                      {" "}
                      <AccountCircleIcon xs={{ fontSize: 20 }} />
                      My Profile
                    </div>
                    <div className="text-muted">
                      Account settings and more...
                    </div>
                  </div>
                </Link>
              </div>
            </DropdownItem>
            {/*end::Item*/}
            {/*begin::Item*/}

            <DropdownItem className="navi-item px-8">
              <div className="navi-link">
                <div className="navi-icon mr-2">
                  <i className="flaticon2-calendar-3 text-success" />
                </div>
                <Link style={{ textDecoration: 'none' }} to="/blackbook">
                  <div className="navi-text">
                    <div style={{ color: 'black' }} className="font-weight-bold">
                      <MenuBook xs={{ fontSize: 18 }} /> My Blackbook
                    </div>
                  </div>
                </Link>
              </div>
            </DropdownItem>

            <DropdownItem onClick={() => logout()} className="navi-item px-8">
              <div onClick={() => logout()} className="navi-link">
                <div className="navi-icon mr-2">
                  <i className="flaticon2-hourglass text-primary" />
                </div>
                <div className="navi-text" style={{ marginRight: 20 }}>
                  <div style={{ color: 'black' }} className="font-weight-bold">
                    <ExitToAppIcon style={{ fontSize: 16 }} />Log Out
                  </div>
                </div>
              </div>
            </DropdownItem>
            {/*end::Item*/}
          </div>
          {/*end::Nav*/}
        </DropdownMenu>
        {/*end::Dropdown*/}
      </Dropdown>
      {/*end::User*/}
      {/*end::Topbar*/}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
});
const mapDispatchToProps = (dispatch) => ({
  logoutUser: (email) => dispatch(authActions.logoutUser(email)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(User));
