import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import Last10 from '../last10/Last10'
import Next10 from '../next10/Next10'

export default function Saturday() {
  
  const render=()=>{
    return(<Col style={{ marginTop: "24px" , marginLeft: "15px",marginRight: "15px", fontSize:"15px"}}>
    <div ref={ref}>
    <Col xs={12}>
    <h1 style={{ textAlign: "center" }}>
    Saturday’s Free Horse Racing Tips
    </h1>
    <p style={{ textAlign: "justify" }}>
    Are you planning on placing a bet at any Australian thoroughbred meeting this weekend? Before you
bet, check out the horse racing tips for Saturday available exclusively through Past The Post Tips. Our
team of experts analyse every race and every horse, taking into account all the variables that go into
victory. We then present you with a rating for each horse. The horse on the top of our list has the best
chance of victory according to our system, so all you need to do is select number one to swing the odds
in your favour.
    </p>
    <h6 style={{ textAlign: "justify" }}>
    View Saturday’s Free Horse Racing Tips Now
    </h6>
    <hr />
  </Col>
  <ul></ul>

  <Col xs={12}>
    <h2 style={{ textAlign: "center" }}>
    Australian Horse Racing Tips 7 Days a Week, Including Saturday!
    </h2>
    <p style={{ textAlign: "justify" }}>
    The tips and predictions provided by PTP Tips are based on the latest and most accurate data available
at the time. You can view Saturday’s horse racing tips any day of the week, and our advice may change
as the days go on.
    </p>
    <p style={{ textAlign: "justify" }}>
    For the most accurate information and the best chance of winning according to our system, we
recommend checking the <Link to={"/horse-racing-tips/today"}>tips for today</Link> section on Saturday morning. Uploaded daily before 11am, the
rankings in our today’s tips section are based on all the latest information, such as changes in the track,
new jockeys, or horse scratching details.
    </p>
    <hr />
  </Col>

  <Col xs={12}>
    <h2 style={{ textAlign: "center" }}>
    Get the Best Free Horse Racing Tips for Saturday with Past The Post
    </h2>
    <p style={{ textAlign: "justify" }}>
    Betting on the races is an exhilarating experience, and you’re always at the mercy of lady luck when you
play. At Past The Post Tips, we believe you can make your own luck because we’ve been doing it for
years. The formulas and real-time information that we provide are the same advice that we’ve been
following for years. We’re excited to share this with you to give you the best horse racing tips for
Saturday and every other race day of the week!
    </p>
    <p style={{ textAlign: "justify" }}>
    Whenever and wherever there is a thoroughbred horserace across the country, you can be sure that PTP
Tips has data-driven rankings to give you the leg up on lady luck. You could crunch all this data yourself,
but who has time for that? (Well, we do!)
    </p>
    <p style={{ textAlign: "justify" }}>
    The best thing about our horse racing tips for Saturday is that you can access them for free. 
    </p>
    <p style={{ textAlign: "justify" }}>
    Our daily horse racing tips not only provide you with the latest information on every Australian
thoroughbred meeting every day of the week. We also crunch all the data for you and provide you with
ratings for every horse for every race. The horse with the highest percentage has the best chance of
winning according to our data.
    </p>
    <p style={{ textAlign: "justify" }}>
    Sounds good? Sign up and get started with <Link to={"/register"}>free horse racing tips for Saturday</Link>
    </p>
    <hr />
  </Col>
</div>
</Col>
  )
    
  }

  const ref = useRef(null)

  const [height, setHeight] = useState(0);

  useEffect(() => {
    console.log(ref)
    setHeight(ref.current.clientHeight)
  },[])

  

  if(window.innerWidth > 1200 ){
  return (
    <Row>
    <Col className="d-block col-md-2" style={{ overflowY:"auto",marginTop: "24px",  height:height, marginLeft:"10px"}}>
    <h5 style={{
    backgroundColor: "#142841",
    height: 32,
    borderRadius: 4,
    textAlign: "center",
    color: "white",
    fontWeight: "600",
    margin: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "350px",
    marginLeft: "auto",
    marginRight: "auto",
    position: "sticky",
    top: 0,
    zIndex:1,
  }}>Next PTP TIPS</h5>
    <Next10/>
    </Col>

{render()}
    

    <Col className="d-block col-2" style={{ overflowY:"auto",marginTop: "24px",  height:height, marginRight:"10px"}}>
    <h5 style={{
    backgroundColor: "#142841",
    height: 32,
    borderRadius: 4,
    textAlign: "center",
    color: "white",
    fontWeight: "600",
    margin: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "350px",
    marginLeft: "auto",
    marginRight: "auto",
    position: "sticky",
    top: 0,
    zIndex:1,
  }}>PREV PTP TIPS</h5>
    <Last10/>
    </Col>
  
    </Row>
  )


}else if(window.innerWidth < 1200 ){
  return(
    <Row>
    
    {render()}

    <Col style={{ marginTop: "24px" }}>
            <h5
              style={{
                backgroundColor: "#142841",
                height: 32,
                borderRadius: 4,
                textAlign: "center",
                color: "white",
                fontWeight: "600",
                margin: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "350px",
                marginLeft: "auto",
                marginRight: "auto",
              }}>
              NEXT PTP TIPS
            </h5>
            <div
              style={{
                display: "flex",
                overflowX: "scroll",
                overflowY: "hidden",
                marginBottom: "8px",
              }}>
              <Next10 />
            </div>
          </Col>

    <Col style={{ marginTop: "24px" }}>
            <h5
              style={{
                backgroundColor: "#142841",
                height: 32,
                borderRadius: 4,
                textAlign: "center",
                color: "white",
                fontWeight: "600",
                margin: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "350px",
                marginLeft: "auto",
                marginRight: "auto",
              }}>
              PREV PTP TIPS
            </h5>
            <div
              style={{
                display: "flex",
                overflowX: "scroll",
                overflowY: "hidden",
                marginBottom:"10px",
              }}>
              <Last10 />
            </div>
          </Col>
  
    </Row>
  )
}
}
