{
  /*eslint-disable */
}
import React, { useState, useEffect } from "react";
import {
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
  Modal,
  ModalBody,
  // ModalHeader,
  ModalFooter,
  Alert,
  InputGroupText,
  InputGroup,
  InputGroupAddon,
  Input,
  Col,
  Row,
  Form,
  Label,
  FormGroup,
} from "reactstrap";
import {
  CountryDropdown, RegionDropdown,
} from "react-country-region-selector";
import { CircularProgress } from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import moment from "moment-timezone";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useHistory, withRouter } from "react-router-dom";
//import moment from 'moment-timezone'
import { connect } from "react-redux";
// import { CircularProgress } from "@material-ui/core";
import { getIpByApi, registerStep1 } from "../../../config/config";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import "../styles.scss";
import GoogleSignIn from "../../GoogleLogin/GoogleLogin";
const authActions = require("../../../redux/actions/auth");
const User = (props) => {
  const history = useHistory();
  const [notice, setNotice] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [phoneMessage, setPhoneMessage] = useState("");
  const [over18, setOver18] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [country, setCountry] = useState("Australia");
  const [region, setRegion] = useState("Australian Capital Territory");
  const [emailError, setEmailError] = useState("");
  const [errorCode, setErrorCode] = useState(null);
  // const [forgotClicked, setForgotClicked] = useState(props.loadingForgetPassword);
  const [isOpen, setIsOpen] = useState(false);
  const [rOpen, setROpen] = useState(false);
  const [fOpen, setFOpen] = useState(false);
  // const [errorPopup, setErrorPopup] = useState(false)
  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [recaptcha, setRecaptcha] = useState("");



  const onRecaptcha = (value) => {
    if (value) {
      setRecaptcha(value);
    }
  };

  const toggle = () => {
    setIsOpen(!isOpen);
    setFOpen(false);
    props.cleanMessages();
  };

  const rtoggle = () => {
    setROpen(!rOpen);
  };

  const login = async (event) => {
    setLoading(true);
    event.preventDefault();
    await props.loginClient({ email, password }).then(() => {
      if (props?.user?.status === 200) {
        setIsOpen(false);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const forgotPass = (e) => {
    e.preventDefault();
    props.forgotPassword(email);
  };

  const renderMessage = () => {
    if (props?.loginError) {
      if (props?.errorStatus === 400) {
        return (
          <div>
            <Alert color="danger">
              {props?.loginError}
              <br />
              <strong>
                <a onClick={forgotClick} >
                  Forgot Password?
                </a>
              </strong>
            </Alert>
          </div>
        );
      } else if (props?.errorStatus === 400) {
        return (
          <div>
            {/* <Alert color="danger">
              {props?.loginError}
              <br />
              <Link to="/register" onClick={toggle}>
                <strong>
                  <span style={{ color: "white", cursor: "pointer" }}>
                    Create an Account
                  </span>
                </strong>
              </Link>
            </Alert> */}
          </div>
        );
      } else if (props?.errorStatus === 300) {
        return (
          <div>
            <Alert color="danger">
              {props?.loginError}
              <br />
            </Alert>
          </div>
        );
      }
    }
  };

  const forgotClick = () => {
    setFOpen(true);
  };

  const handlePhone = (phone) => {
    setPhone(phone);
  };

  const handleCountry = (country) => {
    setCountry(country);
  };

  const handleRegion = (region) => {
    setRegion(region);
  };

  const handleChangeFirstName = (evt) => {
    setFirstName(evt.target.value);
  };

  const handleChangePassword = (evt) => {
    setPassword(evt.target.value);
  };

  const handleChangeLastName = (evt) => {
    setLastName(evt.target.value);
  };

  const handleChangeEmail = (evt) => {
    setEmail(evt.target.value);
  };
  const handleBox = (e) => {
    setOver18(e.target.checked);
  };

  const closeNotice = () => {
    setNotice(false);
    let today = moment().format("DD-MM-YYYY");
    history.push(`/horse-racing-tips/${today}`);
  };

  const closeWithoutRedirect = () => {
    setNotice(false);
    window.scrollTo(0, 0);
  };

  const closeError = () => {
    window.scrollTo(0, 0);
    setError(false);
    setErrorMessage("");
  };

  const handleKeySpace = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const handleSubmit = async () => {
    //if(this.state.phone !== ''){
    // const { dispatch } = this.props
    setLoading(true);
    setPhoneMessage("");
    let userDetails = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
      phone: phone,
      country: country,
      state: region,
    };
    await registerStep1(userDetails).then((response) => {
      if (response?.status === 200) {
        setLoading(false);
        setNotice(true);
        setError(false);
      } else if (response?.data?.error?.error_msg?.status === 500) {
        setErrorMessage(
          "Please choose a stonger password. Try a mix of letters, numbers and symbols."
        );
        setLoading(false);
        setEmailError("red");
        setErrorCode(400);
        window.scrollTo(0, 0);
      } else if (response?.status === 400) {
        setErrorMessage(response?.data?.error + ", Login to your account");
        setLoading(false);
        setEmailError("red");
        setErrorCode(400);
        window.scrollTo(0, 0);
      } else if (response?.status === 401) {
        setErrorMessage(response?.data?.message);
        setLoading(false);
        setEmailError("red");
        setErrorCode(400);
        window.scrollTo(0, 0);
      } else {
        setErrorMessage(
          "User registration not completed. please try again later"
        );
        setLoading(false);
        setErrorCode(null);
      }
    });
  };

  const renderMessageRegister = () => {
    if (errorCode === 200) {
      return (
        <Alert color="primary">
          <div>
            {notice}
            <br />
          </div>
        </Alert>
      );
    } else if (errorCode === 400) {
      return (
        <Alert color="danger">
          <div>
            {errorMessage}
            <br />
            {/* <Link style={{color:'white'}} to="/login"><strong>Login to your account</strong></Link> */}
          </div>
        </Alert>
      );
    }
  };

  const { isLoggedIn } = props;
  if (isLoggedIn) {
    history.push("/");
  }
  return (
    <>
      <Container id="container">
        <Dropdown style={{ marginRight: 8 }} isOpen={isOpen} toggle={toggle}>
          <DropdownToggle
            // className="topbar-item "
            style={{
              marginRight: 5,
              backgroundColor: "transparent",
              borderWidth: 0,
            }}
          >
            <span
              style={{
                color: "white",
                fontFamily: "Poppins",
                fontSize: 16,
              }}
              size="sm"
            >
              Log In
            </span>
          </DropdownToggle>

          <DropdownMenu right className="dropdown-menu-lg p-0" hidden={fOpen} style={{ width: "300px", marginTop: 10 }}>
            <div
              className="d-flex align-items-center p-8 rounded-top"
              style={{ textAlign: "center" }}
            >
              <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                Log In to Your Account .
              </div>
            </div>
            <div
              style={{
                marginBottom: -8,
                paddingRight: 24,
                paddingLeft: 24,
              }}
            >
              {renderMessage()}
            </div>
            <div />

            <form onSubmit={login} style={{ padding: 24 }}>
              <InputGroup>
                <Input
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  type="email"
                  name="email"
                  id="cemail"
                  autoComplete="current-email"
                  placeholder="Email Address"
                  required
                />
              </InputGroup>

              <InputGroup style={{ marginTop: 8 }}>
                <Input
                  value={password}
                  required
                  autoComplete="current-password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  type={showPass ? "text" : "password"}
                  name="password"
                  id="cpassword"
                  placeholder="Password"
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText onClick={() => setShowPass(!showPass)}>
                    {showPass ? (
                      <FontAwesomeIcon
                        style={{ cursor: "pointer" }}
                        color="grey"
                        icon={faEye}
                        size="1x"
                      />
                    ) : (
                      <FontAwesomeIcon
                        style={{ cursor: "pointer" }}
                        color="grey"
                        icon={faEyeSlash}
                        size="1x"
                      />
                    )}
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>

              <div
                style={{ marginTop: 24 }}
                className="form-group d-flex flex-wrap justify-content-between align-items-center"
              >
                <p
                  className="text-muted text-hover-primary"
                  onClick={forgotClick}
                  style={{ cursor: "pointer" }}
                >
                  Forget Password ?
                </p>
              </div>
              <div>
                <Button
                  type="submit"
                  color="primary"
                  style={{ color: "white" }}
                  size="md"
                >
                  {loading ? (
                    <CircularProgress style={{ color: "white" }} size={24} />
                  ) : (
                    <strong
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins",
                      }}
                    >
                      Log In
                    </strong>
                  )}
                </Button>
                <div
                  // className="text-dark m-0 flex-grow-1 mr-3 font-size-h5"
                  style={{ fontSize: 12 }}
                >
                  Don't have an account?
                  <Link
                    color="primary"
                    type="submit"
                    to="/register"
                    style={{ color: "#44bd32" }}
                  >
                    <strong>Join Now</strong>
                  </Link>
                </div>
              </div>
            </form>
          </DropdownMenu>

          <DropdownMenu className="dropdown-menu-lg" hidden={!fOpen} style={{ width: "250px" }}>
            <div className="d-flex align-items-center p-8 rounded-top">
              <div>
                <p style={{ fontSize: 16 }}>Forgot Password?</p>
                <p style={{ fontSize: 12, textAlign: "justify" }}>
                  Looks like you’re having some trouble logging in. Enter your
                  email below and an Reset Password email will be sent to your
                  inbox.
                </p>
                <p
                  style={{
                    color: "red",
                    fontSize: 16,
                    marginBottom: -16,
                  }}
                >
                  {props?.forgotError}
                </p>
                <p
                  style={{
                    color: "blue",
                    fontSize: 16,
                    marginBottom: -16,
                  }}
                >
                  {props?.forgotMessage}
                </p>
              </div>
            </div>
            <div className="separator separator-solid" />

            <form onSubmit={forgotPass} style={{ padding: 15 }}>
              <p>Enter your email address</p>
              <input
                required
                type="email"
                name="email"
                autoComplete="current-email"

                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                className="form-control rounded-right border-0 bg-light text-dark opacity-80 email-field"
                style={{ textAlign: "left" }}
                placeholder="Email Address"
              />

              <div
                style={{ marginTop: 24 }}
                className="form-group d-flex flex-wrap justify-content-between align-items-center"
              >
                <Button
                  type="submit"
                  color="primary"
                  style={{ color: "white", width: "150px" }}
                  disabled={props.loadingForgetPassword}
                  size="md"
                >
                  <strong
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins",
                    }}
                  >
                    {props.loadingForgetPassword ? (
                      <CircularProgress color="white" size={24} />
                    ) : (
                      "Forgot Pass"
                    )}
                  </strong>
                </Button>
              </div>
            </form>
          </DropdownMenu>
        </Dropdown>
        <div style={{ marginRight: 0 }}>
          <div>
            <div>
              <Dropdown isOpen={rOpen} toggle={rtoggle}>
                <DropdownToggle
                  color="success"
                  style={{
                    color: "white",
                    marginLeft: 0,
                    fontWeight: 400,
                    fontSize: 16,
                  }}
                  size="sm"
                >
                  {/* <strong
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins",
                  }}> */}
                  Get Started
                  {/* </strong> */}
                </DropdownToggle>
                <DropdownMenu
                  // left
                  className="dropdown-menu-lg p-0"
                  hidden={fOpen}
                  style={{ marginTop: "3px", width: "auto" }}
                >
                  <Col>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 8,
                      }}
                    >
                      <GoogleSignIn />
                    </Row>
                    <Form
                      autoComplete="false"
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                      style={{
                        backgroundColor: "white",
                        padding: 12,
                        borderRadius: 12,
                        // marginTop: 3,
                        marginBottom: 65,
                        marginRight: 0,
                      }}
                    >
                      {renderMessageRegister()}
                      <Row>
                        <Col xs="12" md="6">
                          <FormGroup>
                            <Label for="firstName">
                              First Name
                              <span style={{ color: "red" }}>*</span>
                            </Label>
                            <Input
                              type="text"
                              required
                              name="firstName"
                              id="firstName"
                              placeholder={
                                firstName ? firstName : "Your First Name"
                              }
                              value={firstName}
                              onChange={handleChangeFirstName}
                            />
                          </FormGroup>
                        </Col>

                        <Col xs="12" md="6">
                          <FormGroup>
                            <Label for="lastName">
                              Last Name<span style={{ color: "red" }}>*</span>
                            </Label>
                            <Input
                              type="text"
                              required
                              name="lastName"
                              id="lastName"
                              value={lastName}
                              placeholder={
                                lastName ? lastName : "Your Last Name"
                              }
                              onChange={handleChangeLastName}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={12} md={6}>
                          <FormGroup>
                            <Label for="email">
                              Email Address
                              <span style={{ color: "red" }}> *</span>
                            </Label>
                            <Input
                              type="email"
                              required
                              autoComplete="new-email"
                              name="email"
                              id="nemail"
                              onKeyPress={handleKeySpace}
                              value={email}
                              onChange={handleChangeEmail}
                              style={{ borderColor: emailError }}
                              placeholder={email ? email : "Your Email Address"}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                          <FormGroup>
                            <Label for="password">
                              Password<span style={{ color: "red" }}>*</span>
                            </Label>
                            <InputGroup>
                              <Input
                                onChange={handleChangePassword}
                                style={{ marginTop: 0 }}
                                onKeyPress={handleKeySpace}
                                type={showPass ? "text" : "password"}
                                required
                                name="password"
                                autoComplete="new-password"
                                id="npassword"
                                placeholder="Create a unique password"
                              />
                              <InputGroupAddon
                                addonType="append"
                                style={{ cursor: "pointer" }}
                              >
                                <InputGroupText
                                  onClick={() => setShowPass(!showPass)}
                                >
                                  {showPass ? (
                                    <FontAwesomeIcon
                                      color="grey"
                                      icon={faEye}
                                      size="1x"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      color="grey"
                                      icon={faEyeSlash}
                                      size="1x"
                                    />
                                  )}
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs="10" md="6">
                          <FormGroup>
                            <Label for="contry">Country</Label>
                            <CountryDropdown
                              className="form-control h-auto form-control-solid py-4 px-8"
                              value={country}
                              labelType="full"
                              onChange={(country) => handleCountry(country)}
                              placeholder="Country"
                              id="country"
                            />
                          </FormGroup>
                        </Col>

                        <Col xs="10" md="6">
                          <FormGroup>
                            <Label for="region">State</Label>
                            <RegionDropdown
                              labelType="full"
                              className="form-control h-auto form-control-solid py-4 px-8"
                              country={country}
                              value={region}
                              placeholder="Text"
                              onChange={(region) => handleRegion(region)}
                              id="region"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup style={{ marginBottom: "28px" }}>
                            <Label for="phone">
                              <span style={{ color: "red" }}>
                                {phoneMessage}
                              </span>
                            </Label>
                            <PhoneInput
                              country={"au"}
                              onChange={(phone) => handlePhone(phone)}
                              inputStyle={{
                                height: "45px",
                                width: "100%",
                                borderColor: "#d2d8dd",
                                textAlign: "left",
                              }}
                              id="phone"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup tag="fieldset">
                        <FormGroup check>
                          <Label check>
                            <Input
                              name="over18"
                              defaultChecked={over18}
                              onChange={handleBox}
                              type="checkbox"
                            />{" "}
                            Yes, I am over 18 years of age and accept the
                            website{" "}
                            <a href="/policy" style={{ color: "#44bd32" }}>
                              Terms and Conditions
                            </a>
                          </Label>
                        </FormGroup>
                      </FormGroup>
                      <>
                        <ReCAPTCHA
                          // ref={recaptchaRef}
                          sitekey="6LfPDokaAAAAAGyPw1MFrVEklyKwytD8P6IXE3OQ"
                          onChange={onRecaptcha}
                          type="image"
                        />
                      </>
                      <Button
                        type="submit"
                        color="primary"
                        style={{ width: "150px", marginTop: 10 }}
                        disabled={!over18 || !recaptcha}
                      >
                        {loading ? (
                          <CircularProgress color="inherit" size={24} />
                        ) : (
                          <strong>Create Account</strong>
                        )}
                      </Button>
                      <br />
                    </Form>

                    <Modal isOpen={notice} style={{ marginTop: 80 }}>
                      <ModalBody
                        style={{
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <img
                          alt="Logo"
                          src="../../faviconDark.png"
                          width="40px"
                          className="logo-default max-h-40px"
                        />
                        <p
                          style={{
                            fontFamily: 12,
                            marginTop: 4,
                            fontSize: 16,
                          }}
                        >
                          An activation link has been sent to {email}
                          <br />
                          <span
                            onClick={closeWithoutRedirect}
                            style={{ cursor: "pointer", color: "blue" }}
                          >
                            click here to edit email.
                          </span>{" "}
                          <br />
                          Kindly click on the link in the email to activate your
                          account. If you cannot find it, please check your junk
                          or spam. Please contact us for assistance.
                        </p>
                      </ModalBody>
                      <ModalFooter
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Link to="/horse-racing-tips/today">
                          <Button onClick={closeNotice} color="primary">
                            <strong>Okay</strong>
                          </Button>
                        </Link>
                        {""}
                      </ModalFooter>
                    </Modal>

                    <Modal isOpen={error} style={{ marginTop: 80 }}>
                      <ModalBody
                        style={{
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <img
                          alt="Logo"
                          src="../../favicon.png"
                          width="30px"
                          height="30px"
                          className="logo-default max-h-30px"
                        />
                        <p
                          style={{
                            fontFamily: 12,
                            marginTop: 4,
                            fontSize: 16,
                            color: "red",
                          }}
                        >
                          {errorMessage}
                        </p>
                      </ModalBody>
                      <ModalFooter
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Button onClick={closeError} color="danger">
                          <strong>Okay</strong>
                        </Button>
                        {""}
                      </ModalFooter>
                    </Modal>
                  </Col>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  currentUser: state.auth.currentUser,
  isFetching: state.auth.isFetching,
  loginError: state.auth.loginError,
  loadingInit: state.auth.loadingInit,
  forgotError: state.auth.forgotError,
  forgotMessage: state.auth.forgotMessage,
  errorStatus: state.auth.errorStatus,
  loadingForgetPassword: state.auth.loadingForgetPassword
});
const mapDispatchToProps = (dispatch) => ({
  cleanMessages: () => dispatch(authActions.cleanMessages()),
  loginClient: (creds) => dispatch(authActions.loginClient(creds)),
  forgotPassword: (email) => dispatch(authActions.forgotPassword(email)),
  // loginWithGoogle : (creds) =>dispatch(authActions.loginWithGoogle(creds))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(User));
