import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import Last10 from '../last10/Last10'
import Next10 from '../next10/Next10'

export default function Sunday() {
  
  const render=()=>{
    return(<Col style={{ marginTop: "24px" , marginLeft: "15px",marginRight: "15px", fontSize:"15px"}}>
    <div ref={ref}>
    <Col xs={12}>
    <h1 style={{ textAlign: "center" }}>
    Sunday’s Free Horse Racing Tips
    </h1>
    <p style={{ textAlign: "justify" }}>
    Looking to have some fun at Pakenham, Randwick, Rosehill, or Doomben this Sunday? Before you head
down to the track, visit PTP Tips first. The Past The Post Tips team has assembled the very best horse
racing tips for Sunday using our tried and tested system.
    </p>
    <p style={{ textAlign: "justify" }}>
    Here’s how it works.
    </p>
    <p style={{ textAlign: "justify" }}>
    We look at everything there is to know about each race in the Australian thoroughbred horse racing
industry. This includes information about the horses’ past performance against each other and how they
fare on each particular track and at every distance. We also look at the barrier they draw, the jockey,
and much more.
    </p>
    <p style={{ textAlign: "justify" }}>
    So much information goes into our formula, but all you need to look at is the final ratings. We rank each
horse based on their percentage chance of winning the race according to all our stats. The horse on the
top of the list is the one we give the best chance of winning. All this information is displayed alongside
live odds so you can compare the pair and make an informed decision. It’s as easy as that.

    </p>
    <h6 style={{ textAlign: "justify" }}>
    View Sunday’s Free Horse Racing Tips Now
    </h6>
    <hr />
  </Col>
  <ul></ul>

  <Col xs={12}>
    <h2 style={{ textAlign: "center" }}>
    Australian Horse Racing Tips 7 Days a Week, Including Sunday!
    </h2>
    <p style={{ textAlign: "justify" }}>
    Our free horse racing tips for Sunday can be viewed right now. We love looking ahead, so if you’re
planning a Sunday at the races, check out our tips, rankings, and ratings now.
    </p>
    <p style={{ textAlign: "justify" }}>
    Of course, a lot can change in sports in just a few days. We keep you up to date with changes and
alterations up until the very last minute. If a horse is scratched, a different jockey is riding your horse,
the track condition has changed, or anything else has happened, PTP Tips will update the rankings
accordingly.
    </p>
    <p style={{ textAlign: "justify" }}>
    Be sure to check your PTP Tips account again on race day. You can find all the latest data and rankings in
our <Link to={"/horse-racing-tips/today"}>tips for today</Link> section on the day of the race.
    </p>
    <hr />
  </Col>

  <Col xs={12}>
    <h2 style={{ textAlign: "center" }}>
    Get the Best Free Horse Racing Tips for Sunday with Past The Post
    </h2>
    <p style={{ textAlign: "justify" }}>
    If you’re ready to get started with horse racing tips for Sunday, all you need to do is sign up with Past
The Post Tips. Get started with a free, 6-month trial with no credit card required! You can also rest
assured that once you are paying, there are no extra hidden fees. Simply sign up, pay for your
membership, and get access to all our advice with no further costs.
    </p>
    <p style={{ textAlign: "justify" }}>
    Let’s throw lady luck a curveball! To get free horse racing tips for Sunday, <Link to={"/register"}>Sign up now!</Link>
    </p>
    <hr />
  </Col>
</div>
</Col>
  )
    
  }

  const ref = useRef(null)

  const [height, setHeight] = useState(0);

  useEffect(() => {
    console.log(ref)
    setHeight(ref.current.clientHeight)
  },[])

  

  if(window.innerWidth > 1200 ){
  return (
    <Row>
    <Col className="d-block col-md-2" style={{ overflowY:"auto",marginTop: "24px",  height:height, marginLeft:"10px"}}>
    <h5 style={{
    backgroundColor: "#142841",
    height: 32,
    borderRadius: 4,
    textAlign: "center",
    color: "white",
    fontWeight: "600",
    margin: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "350px",
    marginLeft: "auto",
    marginRight: "auto",
    position: "sticky",
    top: 0,
    zIndex:1,
  }}>Next PTP TIPS</h5>
    <Next10/>
    </Col>

{render()}
    

    <Col className="d-block col-2" style={{ overflowY:"auto",marginTop: "24px",  height:height, marginRight:"10px"}}>
    <h5 style={{
    backgroundColor: "#142841",
    height: 32,
    borderRadius: 4,
    textAlign: "center",
    color: "white",
    fontWeight: "600",
    margin: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "350px",
    marginLeft: "auto",
    marginRight: "auto",
    position: "sticky",
    top: 0,
    zIndex:1,
  }}>PREV PTP TIPS</h5>
    <Last10/>
    </Col>
  
    </Row>
  )


}else if(window.innerWidth < 1200 ){
  return(
    <Row>
    
    {render()}

    <Col style={{ marginTop: "24px" }}>
            <h5
              style={{
                backgroundColor: "#142841",
                height: 32,
                borderRadius: 4,
                textAlign: "center",
                color: "white",
                fontWeight: "600",
                margin: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "350px",
                marginLeft: "auto",
                marginRight: "auto",
              }}>
              NEXT PTP TIPS
            </h5>
            <div
              style={{
                display: "flex",
                overflowX: "scroll",
                overflowY: "hidden",
                marginBottom: "8px",
              }}>
              <Next10 />
            </div>
          </Col>

    <Col style={{ marginTop: "24px" }}>
            <h5
              style={{
                backgroundColor: "#142841",
                height: 32,
                borderRadius: 4,
                textAlign: "center",
                color: "white",
                fontWeight: "600",
                margin: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "350px",
                marginLeft: "auto",
                marginRight: "auto",
              }}>
              PREV PTP TIPS
            </h5>
            <div
              style={{
                display: "flex",
                overflowX: "scroll",
                overflowY: "hidden",
                marginBottom:"10px",
              }}>
              <Last10 />
            </div>
          </Col>
  
    </Row>
  )
}
}
