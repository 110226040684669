import React, { Suspense, lazy, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "./App.scss";

/* COMPONENTS */
import Loading from "./loading/LoadingNew";
import Layout from "./layouts/Layout";
import Header from "./headers/HeadersNew";
import Footer from "./footers/Footers";


/* Styling*/
import "react-toastify/dist/ReactToastify.css";
import "semantic-ui-css/semantic.min.css";
// import "../../node_modules/line-awesome/dist/line-awesome/css/line-awesome.css";
import Thursday from "./seoPages/Thursday";
import Friday from "./seoPages/Friday";
import Saturday from "./seoPages/Saturday";
import Sunday from "./seoPages/Sunday";

/* PAGES */
const ErrorPage = lazy(() => import("../pages/error/ErrorPage"));
const Register = lazy(() => import("../pages/register/Register"));
const Home = lazy(() => import("../pages/homes/Home"));
const Faq = lazy(() => import("../pages/faq/Faq"));
const Contact = lazy(() => import("../pages/contactUs/ContactsUs"));
const Selections = lazy(() => import("../pages/selection/Selections"));
const Account = lazy(() => import("../pages/account/Account"));
const Blackbook = lazy(() => import("../pages/blackbook/Blackbooks"));
const Verify = lazy(() => import("../pages/verify/Verify"));
const ForgotPass = lazy(() => import("../pages/forgotPass/ForgotPassword"));
const Policy = lazy(() => import("../pages/legal/Privacies"));
// const Unsubscribe = lazy(() => import("../pages/unsubscribe/Unsubscribe"));
// const PromotionEmail = lazy(() => import("../pages/emailPromo/EmailsPromo"));
const Login = lazy(() => import("../pages/loginMobile/loginMobile"));
const FreeHorseTips = lazy(() =>
  import("../pages/freeHorseTips/FreeHorsesTips")
);
const Sitemap = lazy(() => import("../pages/sitemaps/Sitemaps"));
const Thanks = lazy(() => import("../pages/thank-you/Thanks"));
const HorseProfile = lazy(() => import("../pages/profile/HorseProfile"));
const JockeyProfile = lazy(() => import("../pages/profile/JockeyProfile"));
const TrainerProfile = lazy(() => import("../pages/profile/TrainerProfile"));
const VenueProfile = lazy(() => import("../pages/profile/VenueProfile"));
// const TopPerforming = lazy(() => import("../components/TopPerforming/TopPerforming"))
// const TopPerformingHorseDetails = lazy(() => import("../pages/bestPerformingDetails/bestPerformingHorseDetails"))
// const TopPerformingJockeyDetails = lazy(() => import("../pages/bestPerformingDetails/bestPerformingJockeyDetails"))
// const TopPerformingVenueDetails = lazy(() => import("../pages/bestPerformingDetails/bestPerformingVenueDetails"))

/*Bookies Review */
const Unibet = lazy(() => import("../pages/bookies-reviews/Unibet"));
const Boombet = lazy(() => import("../pages/bookies-reviews/Boombet"));
const Betr = lazy(() => import("../pages/bookies-reviews/Betr"));
const Betfair = lazy(() => import("../pages/bookies-reviews/Betfair"));
const BookMakerReview = lazy(() => import("../pages/bookies-reviews/BookMakerReview"));
const Tab = lazy(() => import("../pages/bookies-reviews/Tab"));
const SportsBet = lazy (()=> import("../pages/bookies-reviews/SportsBet"));
const Bet365 = lazy (()=> import("../pages/bookies-reviews/Bet365"));
const SouthernCross = lazy (()=> import("../pages/bookies-reviews/SouthernCross"));
const ReviewLayout = lazy (()=> import("../pages/bookies-reviews/ReviewLayout"));
const GetSetBet = lazy (()=> import("../pages/bookies-reviews/GetSetBet"));
const BetDeluxe = lazy (()=> import("../pages/bookies-reviews/BetDeluxe"));


/*Melbourne Cup Review */
const MelbourneCup = lazy(() => import("../pages/melbourne-cup/MelbourneCup"))

/* CSS */

const CloseButton = ({ closeToast }) => (
  <i
    onClick={closeToast}
    style={{ color: "white" }}
    className="la la-close notifications-close"
  />
);

const App = (props) => {


  const [ width , setWidth ] = useState(window.innerWidth);

  const updateDimensions = () => {
  setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.scrollTo(0 , 0)
    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("online", handleConnectionChange);
    window.removeEventListener("offline", handleConnectionChange);
    window.removeEventListener("resize", updateDimensions);
    };
  },[]);

  const handleConnectionChange = () => {
    const condition = navigator.onLine ? "online" : "offline";
    if (condition === "online") {
      const webPing = setInterval(() => {
        fetch("//google.com", {
          mode: "no-cors",
        })
          .then(() => {
              return clearInterval(webPing);
          })
          .catch(() => {
            toast.error(<p style={{ color: "white" }}>Connection lost !</p>);
          })
          .then(() => {
              return clearInterval(webPing);
          })
          .catch(() => {
            toast.error(
              <strong style={{ color: "white", fontFamily: "" }}>
                It seems there's a problem with your <br /> internet connection!
              </strong>
            );
          });
      }, 10000);
      return;
    }
    return (
      toast.error(
        <strong style={{ color: "white", fontFamily: "" }}>
          It seems there's a problem with your <br /> internet connection!
        </strong>
      )
    );
  };

  const handleWidth = () => {
    if (width > 1550) {
      return "1550px";
    } else return "100%";
  };

  const renderFooter = () => {
    const { pathname } = props.location;
    if (pathname === "/login") {
      return null;
    } else if (pathname === "/register") {
      return null;
    } else {
      return <Footer />;
    }
  };

  return (
    <div
      style={{
        backgroundColor: props.dark ? "black" : "#eef0f4",
      }}>
      {!props.fullScreen ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            width: " 100%",
            zIndex: 10,
          }}>
          <Header />
        </div>
      ) : (
        <div style={{ backgroundColor: "black", height: 32 }}></div>
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "50px",
        }}>
        <div style={{ minHeight: 800, width: handleWidth() }}>
          <Suspense fallback={<Loading />}>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              pauseOnHover
              hideProgressBar
              closeButton={<CloseButton />}
            />
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>

              <Redirect strict from={"/policy/"} to={"/policy"} />
              <Route exact path="/policy">
                <Policy />
              </Route>

              <Redirect
                strict
                from={"/free-horse-racing-tips/"}
                to={"/free-horse-racing-tips"}
              />
              <Route exact path="/free-horse-racing-tips">
                <FreeHorseTips />
              </Route>

              <Route exact path="/australian-bookmakers">
                <BookMakerReview titles="Australian Bookmakers Reviewssssssssssssssssssssssssss" />
              </Route>

              <Route exact path="/australian-bookmakers/unibet">
                <Unibet />
              </Route>

              <Route exact path="/australian-bookmakers/boombet">
                <Boombet />
              </Route>
     
              <Route exact path="/australian-bookmakers/betr">
                <Betr />
              </Route>

              <Route exact path="/australian-bookmakers/betfair">
                <Betfair />
              </Route>

              <Route exact path="/australian-bookmakers/Tab">
                <Tab />
              </Route>

              <Route exact path="/australian-bookmakers/SportsBet">
                <SportsBet />
              </Route>

              <Route exact path="/australian-bookmakers/Bet365">
                <Bet365 title="bet3tsrfg" />
              </Route>

              <Route exact path="/australian-bookmakers/SouthernCross">
                <SouthernCross />
              </Route>

              <Route exact path="/australian-bookmakers/BetDeluxe">
                <BetDeluxe />
              </Route>

              <Route exact path = "/australian-bookmakers/ReviewLayout">
                <ReviewLayout />
              </Route>

              <Route exact path = "/melbourne-cup">
                <MelbourneCup />
              </Route>

              {/*SEO ROUTES ADDED*/}
              {/* <Redirect strict from={"/horse-racing-tips/today/"} to={"/horse-racing-tips/today"} /> */}
              <Route exact path="/horse-racing-tips/today/">
                <Selections />
              </Route>

              <Route exact path="/horse-racing-tips/yesterday/">
                <Selections />
              </Route>

              <Route exact path = "/australian-bookmakers/getsetbet">
                <GetSetBet />
              </Route>

              <Redirect
                strict
                from={"/horse-racing-tips/next-to-jump/"}
                to={"/horse-racing-tips/next-to-jump"}
              />
              <Route exact path="/horse-racing-tips/next-to-jump/">
                <Selections />
              </Route>

              <Route exact path="/thank-you">
                <Thanks />
              </Route>

              <Route exact path="/horse-racing-tips/tomorrow/">
                <Selections />
              </Route>


              <Route exact path="/horse-racing-tips/thursday">
                 <Thursday />
              </Route>      
   
              <Route exact path="/horse-racing-tips/friday">
                 <Friday />
              </Route>      

              <Route exact path="/horse-racing-tips/saturday">
                 <Saturday />
              </Route>      

              <Route exact path="/horse-racing-tips/sunday">
                 <Sunday />
              </Route>      

              <Route exact path="/profile/horse/:horseId/:horseName">
                <HorseProfile />
              </Route>

              <Route exact path="/profile/jockey/:jockeyId/:jockeyName">
                <JockeyProfile />
              </Route>

              <Route exact path="/profile/trainer/:trainerId">
                <TrainerProfile />
              </Route>

              <Route exact path="/profile/venue/:uuid/:trackCode">
                <VenueProfile />
              </Route>

              <Route exact path="/horse-racing-tips/:date">
                <Selections />
              </Route>

              <Route path="/horse-racing-tips">
                <Layout />
              </Route>

              <Redirect
                strict
                from={"/frequently-asked-questions-faq/"}
                to={"/frequently-asked-questions-faq"}
              />
              <Route exact path="/frequently-asked-questions-faq">
                <Faq />
              </Route>

              <Redirect strict from={"/sitemap/"} to={"/sitemap"} />
              <Route exact path="/sitemap">
                <Sitemap />
              </Route>

              <Redirect strict from={"/contactus/"} to={"/contactus"} />
              <Route exact path="/contactus">
                <Contact />
              </Route>

              <Route exact path="/register/:promoid">
                <Register />
              </Route>

              <Route exact path="/register/:promoid/*">
                <Register />
              </Route>

              <Redirect strict from={"/register/"} to={"/register"} />
              <Route exact path="/register">
                <Register />
              </Route>

              <Redirect strict from={"/login/"} to={"/login"} />
              <Route exact path="/login">
                <Login />
              </Route>

              {/* <Route exact path="/unsubscribe/:id">
                <Unsubscribe />
              </Route> */}

              {/* <Route exact path="/promo/:id">
                <PromotionEmail />
              </Route> */}
              <Redirect strict from={"/account/"} to={"/account"} />
              <Route exact path="/account">
                <Account />
              </Route>

              <Redirect strict from={"/blackbook/"} to={"/blackbook"} />
              <Route exact path="/blackbook">
                <Blackbook />
              </Route>

              <Route exact path="/verify/:id">
                <Verify />
              </Route>

              <Route exact path="/forgotPassword/:id">
                <ForgotPass />
              </Route>

              <Redirect strict from={"/error/"} to={"/error"} />
              <Route exact path="*">
                <ErrorPage />
              </Route>


              {/*redirection old*/}
              <Redirect strict from={"/xyz/"} to={"/horse-racing-tips/today"} />
              <Route exact path="/xyz">
                <Redirect to={"/horse-racing-tips/today"} />
              </Route>

              <Redirect strict from={"/sign_in/"} to={"/login"} />
              <Route exact path="/sign_in">
                <Redirect to={"/login"} />
              </Route>

              <Redirect strict from={"/sign_up/"} to={"/register"} />
              <Route exact path="/sign_up">
                <Redirect to={"/register"} />
              </Route>

              <Redirect strict from={"/contactus/"} to={"/contactus"} />
              <Route exact path="/contactus">
                <Redirect to={"/contactus"} />
              </Route>

              <Route exact path="/about">
                <Redirect to={"/"} />
              </Route>

              <Route>
                <Redirect to={"/horse-racing-tips/today"} />
              </Route>


            </Switch>
          </Suspense>
        </div>
      </div>

      {!props.fullScreen && renderFooter()}

      {/* <MessengerCustomerChat
        pageId="113887910039269"
        appId="348721749704038"
        htmlRef="fb"
      /> */}
    </div>
  );
};
// Redux
const mapStateToProps = (state) => ({
  fullScreen: state.auth.fullScreen,
  dark: state.auth.dark,
});


export default connect(mapStateToProps)(withRouter(App));
