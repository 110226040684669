import React, {useRef, useState, useEffect} from 'react';
import { Col ,Row} from 'reactstrap';
import Next10 from "../../components/next10/Next10";
import Last10 from "../../components/last10/Last10";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const MelbourneCup = () => {
  const createColorsData = (color, count) => {
    return { color, count}
  }

  const createSaddleclothData = (number, wins) => {
    return {number, wins}
  }

  const createWinnersData = (year, winner, jockey, trainer) => {
    return {year, winner, jockey, trainer}
  }

  const createCalendarData = (event, location, date, type) => {
    return {event, location, date, type}
  }

  const Calendar = [
    createCalendarData('',	'September 2022',	'',	''),
    createCalendarData('Sir Rupert Clarke Stakes',	'Caulfield',	'17/09/2022',	'Nominations'),
    createCalendarData('George Main Stakes',	'Randwick',	'17/09/2022',	'Nominations'),
    createCalendarData('Moir Stakes	Moonee', 'Valle',	'23/09/2022',	'Futures'),
    createCalendarData('Golden Rose	Rosehill', 'Garden',	'24/09/2022',	'Futures'),
    createCalendarData('Underwood Stakes',	'Sandown Hillside',	'25/09/2022',	'Futures'),
    createCalendarData('',	'October 2022',	'',	''),
    createCalendarData('Turnbull Stakes', 	'Flemington',	'01/10/2022',	'Futures'),
    createCalendarData('Flight Stakes', 	'Randwick',	'01/10/2022',	'Nominations'),
    createCalendarData('Epsom Handicap', 	'Randwick',	'01/10/2022',	'Futures'),
    createCalendarData('The Metropolitan', 'Randwick',	'01/10/2022',	'Futures'),
    createCalendarData('Caulfield Guineas',	'Caulfield', '08/10/2022',	'1st Acceptances'),
    createCalendarData('Toorak Handicap',	'Caulfield',	'08/10/2022',	'Futures'),
    createCalendarData('Caulfield Stakes (Might and Power)', 'Caulfield',	'08/10/2022',	'Futures'),
    createCalendarData('The Silver Eagle', 'Randwick',	'08/10/2022',	'Futures'),
    createCalendarData('Thousand Guineas', 'Caulfield', '12/10/2022',	'1st Acceptances'),
    createCalendarData('Caulfield Cup', 'Caulfield', 	'15/10/2022',	'Weights'),
    createCalendarData('The Everest', 'Randwick',	'15/10/2022',	'Futures'),
    createCalendarData('The Kosciuszko', 	'Randwick',	'15/10/2022',	'Futures'),
    createCalendarData('Geelong Cup', 'Geelong',	'19/10/2022',	'Futures'),
    createCalendarData('Manikato Stakes',	'Moonee Valley',	'21/10/2022',	'Futures'),
    createCalendarData('The Invitation', 	'Randwick',	'22/10/2022',	'Futures'),
    createCalendarData('Spring Champion Stakes', 'Randwick',	'22/10/2022',	'Futures'),
    createCalendarData('Cox Plate', 'Moonee Valley', 	'22/10/2022',	'1st Acceptances'),
    createCalendarData('Bendigo Cup', 'Bendigo',	'26/10/2022',	'Futures'),
    createCalendarData('Coolmore Stud Stakes', 	'Flemington',	'29/10/2022',	'Futures'),
    createCalendarData('Empire Rose Stakes', 	'Flemington',	'29/10/2022',	'Futures'),
    createCalendarData('The Golden Eagle', 'Rosehill Gardens', 	'29/10/2022',	'Nominations'),
    createCalendarData('Rosehill Gold Cup',	'Rosehill Gardens',	'29/10/2022',	'Futures'),
    createCalendarData('Winners Stakes',	'Rosehill Gardens',	'29/10/2022',	'Futures'),
    createCalendarData('Victoria Derby', 'Flemington',	'29/10/2022',	'Futures'),
    createCalendarData('',	'November 2022',	'',	''),
    createCalendarData('Melbourne Cup', 'Flemington', '01/11/2022',	'Nominations'),
    createCalendarData('The Big Dance', 'Randwick',	'01/11/2022',	'Futures'),
    createCalendarData('VRC Oaks', 	'Flemington',	'03/11/2022',	'Futures'),
    createCalendarData('VRC Champions Stakes', 	'Flemington',	'05/11/2022',	'Futures'),
    createCalendarData('Champions Mile', 	'Flemington',	'05/11/2022',	'Futures'),
    createCalendarData('Golden Gift',	'Rosehill Gardens',	'05/11/2022',	'Futures'),
    createCalendarData('The Five Diamonds', 'Rosehill Gardens',	'05/11/2022',	'Futures'),
    createCalendarData('Champions Sprint', 	'Flemington',	'05/11/2022',	'Futures'),
    createCalendarData('The Hunter', 	'Newcastle',	'12/11/2022',	'Futures'),
    createCalendarData('Railway Stakes', 	'Ascot',	'19/11/2022',	'Futures'),
    createCalendarData('The Gong', 'Kembla Grange',	'19/11/2022',	'Futures'),
    createCalendarData('Winterbottom Stakes',	'Ascot',	'26/11/2022',	'Futures'),
    createCalendarData('',	'December 2022',	'',	''),
    createCalendarData('Northerly Stakes', 	'Ascot',	'03/12/2022',	'Futures'),
    createCalendarData('The Gold Rush', 'Ascot',	'10/12/2022',	'Futures'),
  ]

  const Winners = [
    createWinnersData('2022', 'GOLD TRIP', 'MARK ZAHRA', 'C Maher & D Eustace'),

    createWinnersData('2021', 'VERRY ELLEEGANT', 'JAMES MCDONALD', 'CHRIS WALLER'),
    createWinnersData('2020', 'TWILIGHT PAYMENT', 'JYE MCNEIL', "JOSEPH O'BRIEN"),
    createWinnersData('2019', 'VOW AND DECLARE', 'CRAIG WILLIAMS', "DANNY O'BRIEN"),
    createWinnersData('2018', 'CROSS COUNTER', 'KERRIN MCEVOY', 'CHARLIE APPLEBY'),
    createWinnersData('2017',	'REKINDLING',	'COREY BROWN',	"JOSEPH O’BRIEN"),
    createWinnersData('2016',	'ALMANDIN',	'KERRIN MCEVOY',	'ROBERT HICKMOTT'),
    createWinnersData('2015',	'PRINCE OF PENZANCE',	'MICHELLE PAYNE',	'DARREN WEIR'),
    createWinnersData('2014',	'PROTECTIONIST','RYAN MOORE', 'ANDREAS WOHLER'),
    createWinnersData('2013',	'FIORENTE',	'DAMIEN OLIVER',	'GAI WATERHOUSE'),
    createWinnersData('2012',	'GREEN MOON',	'BRETT PEBBLE',	'ROBERT HICKMOTT'),
    createWinnersData('2011',	'DUNADEN',	'CHRISTOPHE LEMAIRE',	'MIKEL DELZANGLES'),
    createWinnersData('2010',	'AMERICAIN', 'GERALD MOSSE',	'ALAIN DE ROYER-DUPRE'),
    createWinnersData('2009',	'SHOCKING',	'COREY BROWN',	'MARK KAVANAGH'),
    createWinnersData('2008',	'VIEWED', 'B SHINN',	'BART(J.B.) CUMMINGS'),
    createWinnersData('2007',	'EFFICIENT',	'MICHAEL RODD',	'GRAEME ROGERSON'),
    createWinnersData('2006',	'DELTA BLUES',	'YASUNARI IWATA',	'KATSUHIKO SMUII (JAPAN)'),
    createWinnersData('2005',	'MAKYBE DIVA',	'GLEN BOSS',	'LEE FREEDMAN'),
    createWinnersData('2004',	'MAKYBE DIVA',	'GLEN BOSS',	'LEE FREEDMAN'),
    createWinnersData('2003',	'MAKYBE DIVA',	'GLEN BOSS',	'D. J. HALL'),
    createWinnersData('2002',	'MEDIA PUZZLE',	'D. OLIVER',	'D. K. WELD'),
    createWinnersData('2001',	'ETHERAL',	'S. SEAMER',	'MS. S. LAXON'),
    createWinnersData('2000',	'BREW',	'K. MCEVOY',	'M. MORONEY'),
    createWinnersData('1999',	'ROGAN JOSH',	'J. MARSHALL',	'J.B.CUMMINGS'),
    createWinnersData('1998',	'JEZABEEL',	'C. MUNCE',	'B. JENKINS'),
    createWinnersData('1997',	'MIGHT AND POWER',	'J.CASSIDY',	'J.DENHAM'),
    createWinnersData('1996',	'SAINTLY',	'D.BEADMAN',	'J.B.CUMMINGS'),
    createWinnersData('1995',	'DORIEMUS',	'D.OLIVER',	'D.L.FREEDMAN'),
    createWinnersData('1994',	'JEUNE',	'W.HARRIS',	'D.A.HAYES'),
    createWinnersData('1993',	'VINTAGE CROP',	'M.KINANE',	'D.WELD'),
    createWinnersData('1992',	'SUBZERO',	'G.HALL',	'D.L.FREEDMAN'),
    createWinnersData('1991',	'LET’S ELOPE',	'S.R.KING',	'J.B.CUMMINGS'),
    createWinnersData('1990',	'KINGSTON RULE',	'D.BEADMAN',	'J.B.CUMMINGS'),
    createWinnersData('1989',	'TAWRIFFIC',	'R.S.DYE',	'D.L.FREEDMAN'),
    createWinnersData('1988',	'EMPIRE ROSE',	'T.K.ALLAN',	'L.K.LAXON'),
    createWinnersData('1987',	'KENSEI',	'L.OLSEN',	'L.J.BRIDGE'),
    createWinnersData('1986',	'AT TALAQ',	'M.CLARKE',	'C.S.HAYES'),
    createWinnersData('1985',	'WHAT A NUISANCE',	'P.T.HYLAND', 'J.F.MEAGHER'),
    createWinnersData('1984',	'BLACK KNIGHT',	'P.COOK',	'G.M.HANLON'),
    createWinnersData('1983',	'KIWI',	'J.A.CASSIDY',	'E.S.LUPTON'),
    createWinnersData('1982',	'GURNER’S LANE',	'L.DITTMAN',	'G.T.MURPHY'),
    createWinnersData('1981',	'JUST A DASH',	'P.COOK',	'T.J.SMITH'),
    createWinnersData('1980',	'BELDALE BALL',	'J.LETTS',	'C.S.HAYES'),
    createWinnersData('1979',	'HYPERNO',	'H.WHITE',	'J.B.CUMMINGS'),
    createWinnersData('1978',	'ARWON',	'H.WHITE',	'G.M.HANLON'),
    createWinnersData('1977',	'GOLD AND BLACK',	'J.DUGGAN',	'J.B.CUMMINGS'),
    createWinnersData('1976',	'VAN DER HUM',	'R.J.SKELTON',	'L.H.ROBINSON'),
    createWinnersData('1975',	'THINK BIG',	'H.WHITE',	'J.B.CUMMINGS'),
    createWinnersData('1974',	'THINK BIG',	'H.WHITE',	'J.B.CUMMINGS'),
    createWinnersData('1973',	'GALA SUPREME',	'F.REYS',	'R.J.HUTCHINS'),
    createWinnersData('1972',	'PIPING LANE',	'J.LETTS',	'G.M.HANLON'),
    createWinnersData('1971',	'SILVER KNIGHT',	'R.B.MARSH',	'E.TEMPERTON'),
    createWinnersData('1970',	'BAGHDAD NOTE',	'E.J.DIDHAM',	'R.HEASLEY'),
    createWinnersData('1969',	'RAIN LOVER',	'J.JOHNSON',	'M.L.ROBINS'),
    createWinnersData('1968',	'RAIN LOVER',	'J.JOHNSON',	'M.L.ROBINS'),
    createWinnersData('1967',	'RED HANDED',	'R.HIGGINS',	'J.B.CUMMINGS'),
    createWinnersData('1966',	'GALILEE',	'J.MILLER',	'J.B.CUMMINGS'),
    createWinnersData('1965',	'LIGHT FINGERS',	'R.HIGGINS',	'J.B.CUMMINGS'),
    createWinnersData('1964',	'POLO PRINCE',	'R.TAYLOR',	'J.P.CARTER'),
    createWinnersData('1963',	'GATUM GATUM',	'J.JOHNSON',	'H.G.HEAGNEY'),
    createWinnersData('1962',	'EVEN STEVENS',	'L.COLES',	'A.MCGREGOR'),
    createWinnersData('1961',	'LORD FURY',	'R.SELKRIG',	'F.B.LEWIS'),
    createWinnersData('1960',	'HI JINX',	'W.A.SMITH',	'T.H.KNOWLES'),
    createWinnersData('1959',	'MACDOUGAL',	'P.GLENNON',	'R.W.RODEN'),
    createWinnersData('1958',	'BAYSTONE', 'M.SCHUMACHER',	'J.GREEN'),
    createWinnersData('1957',	'STRAIGHT DRAW',	'N.MCGROWDIE',	'J.M.MITCHELL'),
    createWinnersData('1956',	'EVENING PEAL',	'G.PODMORE',	'E.D.LAWSON'),
    createWinnersData('1955',	'TOPAROA',	'N.SELLWOOD',	'T.J.SMITH'),
    createWinnersData('1954',	'RISING FAST',	'J.PURTELL',	'I.J.TUCKER'),
    createWinnersData('1953',	'WODALLA',	'J.PURTELL',	'R.SINCLAIR'),
    createWinnersData('1952',	'DALRAY',	'W.WILLIAMSON',	'C.C.MCCARTHY'),
    createWinnersData('1951',	'DELTA',	'N.SELLWOOD',	'M.MCCARTEN'),
    createWinnersData('1950',	'COMIC COURT',	'P.GLENNON',	'J.CUMMINGS'),
    createWinnersData('1949',	'FOXZAMI',	'W.FELLOWS',	'D.LEWIS'),
    createWinnersData('1948',	'RIMFIRE',	'R.NEVILLE',	'S.BOYDEN'),
    createWinnersData('1947',	'HIRAJI',	'J.PURTELL',	'J.W.MCCURLEY'),
    createWinnersData('1946',	'RUSSIA',	'D.MUNRO',	'E.HUSH'),
    createWinnersData('1945',	'RAINBIRD',	'W.COOK',	'S.EVANS'),
    createWinnersData('1944',	'SIRIUS',	'D.MUNRO',	'E.FISHER'),
    createWinnersData('1943',	'DARK FELT',	'V.HARTNEY',	'R.WEBSTER'),
    createWinnersData('1942',	'COLONUS',	'H.MCCLOUD',	'F.MANNING'),
    createWinnersData('1941',	'SKIPTON',	'W.COOK',	'J.FRYER'),
    createWinnersData('1940',	'OLD ROWLEY',	'A.KNOX',	'J.A.SCULLY'),
    createWinnersData('1939',	'RIVETTE',	'E.PRESTON',	'H.BAMBER'),
    createWinnersData('1938',	'CATALOGUE',	'F.SHEAN',	'A.MCDONALD'),
    createWinnersData('1937',	'THE TRUMP',	'A.REED',	'S.W.READ'),
    createWinnersData('1936',	'WOTAN',	'O.PHILLIPS',	'J.FRYER'),
    createWinnersData('1935',	'MARABOU',	'K.VOITRE',	'L.ROBERTSON'),
    createWinnersData('1934',	'PETER PAN',	'D.MUNRO',	'F.MCGRATH'),
    createWinnersData('1933',	'HALL MARK',	'J.O’SULLIVAN',	'J.HOLT'),
    createWinnersData('1932',	'PETER PAN',	'W.DUNCAN',	'F.MCGRATH'),
    createWinnersData('1931',	'WHITE NOSE',	'N.PERCIVAL',	'E.J.HATWELL'),
    createWinnersData('1930',	'PHAR LAP',	'J.E.PIKE',	'H.R.TELFORD'),
    createWinnersData('1929',	'NIGHTMARCH',	'R.REED',	'A.MCAULAY'),
    createWinnersData('1928',	'STATESMAN',	'J.MUNRO',	'W.KELSO'),
    createWinnersData('1927',	'TRIVALVE',	'R.LEWIS',	'J.SCOBIE'),
    createWinnersData('1926',	'SPEARFELT',	'H.CAIRNS',	'V.O’NEILL'),
    createWinnersData('1925',	'WINDBAG',	'J.MUNRO',	'G.PRICE'),
    createWinnersData('1924',	'BACKWOOD',	'P.BROWN',	'R.BRADFIELD'),
    createWinnersData('1923',	'BITALLI',	'A.WILSON',	'J.SCOBIE'),
    createWinnersData('1922',	'KING INGODA',	'A.WILSON',	'J.SCOBIE'),
    createWinnersData('1921',	'SISTER OLIVE',	'E.O’SULLIVAN',	'J.WILLIAMS'),
    createWinnersData('1920',	'POITREL',	'K.BRACKEN',	'H.J.ROBINSON'),
    createWinnersData('1919',	'ARTILLERYMAN',	'R.LEWIS',	'P.T.HEYWOOD'),
    createWinnersData('1918',	'NIGHT WATCH',	'W.DUNCAN',	'R.BRADFIELD'),
    createWinnersData('1917',	'WESTCOURT',	'W.MCLACHLAN',	'J.BURTON'),
    createWinnersData('1916',	'SASANOF',	'F.FOLEY',	'M.HOBBS'),
    createWinnersData('1915',	'PATROBAS',	'R.LEWIS',	'C.WHEELER'),
    createWinnersData('1914',	'KINGSBURGH',	'K.G.MEDDICK',	'I.FOULSHAM'),
    createWinnersData('1913',	'POSINATUS',	'A.SHANAHAN',	'J.CHAMBERS'),
    createWinnersData('1912',	'PIASTRE',	'A.SHANAHAN',	'R.O’CONNER'),
    createWinnersData('1911',	'THE PARISIAN',	'R.CAMERON',	'C.WHEELER'),
    createWinnersData('1910',	'COMEDY KING',	'W.MCLACHLAN',	'J.LYNCH'),
    createWinnersData('1909',	'PRINCE FOOTE',	'W.MCLACHLAN',	'F.MCGRATH'),
    createWinnersData('1908',	'LORD NOLAN',	'J.FLYNN',	'E.A.MAO'),
    createWinnersData('1907',	'APOLOGUE',	'W.EVANS',	'I.EARNSHAW'),
    createWinnersData('1906',	'POSEIDON',	'T.CLAYTON',	'I.EARNSHAW'),
    createWinnersData('1905',	'BLUE SPEC',	'F.BULLOCK',	'W.HICKENBOTHAM'),
    createWinnersData('1904',	'ACRASIA',	'T.CLAYTON',	'A.E.WILLS'),
    createWinnersData('1903',	'LORD CARDIGAN',	'N.GODBY',	'A.E.CORNWELL'),
    createWinnersData('1902',	'THE VICTORY',	'R.LEWIS',	'R.BRADFIELD'),
    createWinnersData('1901',	'REVENUE',	'F.DUNN',	'F.MUNRO'),
    createWinnersData('1900',	'CLEAN SWEEP',	'A.RICHARDSON',	'J.SCOBIE'),
    createWinnersData('1899',	'MERRIWEE',	'V.TURNER',	'J.WILSON (JNR)'),
    createWinnersData('1898',	'THE GRAFTER',	'J.GOUGH',	'W.FORRESTER'),
    createWinnersData('1897',	'GAULUS',	'S.CALLINAN',	'W.FORRESTER'),
    createWinnersData('1896',	'NEWHAVEN',	'H.J.GARDINER',	'W.HICKENBOTHAM'),
    createWinnersData('1895',	'AURARIA',	'J.STEVENSON',	'J.H.HILL'),
    createWinnersData('1894',	'PATRON',	'H.G.DAWES',	'R.BRADFIELD'),
    createWinnersData('1893',	'TARCOOLA',	'H.CRIPPS',	'J.CRIPPS'),
    createWinnersData('1892',	'GLENLOTH', 'G.ROBSON',	'M.CARMODY'),
    createWinnersData('1891',	'MALVOLIO',	'G.REDFEARN',	'J.REDFEARN'),
    createWinnersData('1890',	'CARBINE',	'R.RAMAGE',	'W.HICKENBOTHAM'),
    createWinnersData('1889',	'BRAVO',	'J.ANWIN',	'T.WILSON'),
    createWinnersData('1888',	'MENTOR',	'M.O’BRIEN',	'W.HICKENBOTHAM'),
    createWinnersData('1887',	'DUNLOP',	'T.SANDERS',	'J.NICHOLSON'),
    createWinnersData('1886',	'ARSENAL',	'W.ENGLISH',	'H.RAYNER'),
    createWinnersData('1885',	'SHEET ANCHOR',	'M.O’BRIEN',	'T.WILSON'),
    createWinnersData('1884',	'MALUA',	'A.ROBERTSON',	'I.FOULSHAM'),
    createWinnersData('1883',	'MARTINI-HENRI',	'J.WILLIAMSON',	'M.FENNELLY'),
    createWinnersData('1882',	'THE ASSYRIAN',	'C.HUTCHENS',	'J.E.SAVILL'),
    createWinnersData('1881',	'ZULU',	'J.GOUGH',	'T.LAMOND'),
    createWinnersData('1880',	'GRAND FLANEUR',	'T.HALES',	'T.BROWN'),
    createWinnersData('1879',	'DARRIWELL',	'S.CRACKNELL',	'W.E.DAKIN'),
    createWinnersData('1878',	'CALAMIA',	'T.BROWN',	'E.DE MESTRE'),
    createWinnersData('1877',	'CHESTER',	'P.PIGOTT',	'E.DE MESTRE'),
    createWinnersData('1876',	'BRISEIS',	'P.ST ALBANS',	'J.WILSON'),
    createWinnersData('1875',	'WOLLOMAI',	'R.BATTY',	'S.MOON'),
    createWinnersData('1874',	'HARICOT',	'P.PIGOTT',	'S.HARDING'),
    createWinnersData('1873',	'DON JUAN',	'W.WILSON',	'J.WILSON'),
    createWinnersData('1872',	'THE QUACK',	'W.ENDERSON',	'J.TAIT'),
    createWinnersData('1871',	'THE PEARL',	'J.CAVANAGH',	'J.TAIT'),
    createWinnersData('1870',	'NIMBLEFOOT',	'J.DAY',	'W.LANG'),
    createWinnersData('1869',	'WARRIOR',	'J.MORRISON',	'R.SEVOIR'),
    createWinnersData('1868',	'GLENCOE',	'C.STANLEY',	'J.TAIT'),
    createWinnersData('1867',	'TIM WHIFFLER',	'J.DRISCOLL',	'E.DE MESTRE'),
    createWinnersData('1866',	'THE BARB',	'W.DAVIS',	'J.TAIT'),
    createWinnersData('1865',	'TORYBOY',	'E.CAVANAGH',	'P.MILEY'),
    createWinnersData('1864',	'LANTERN',	'S.DAVIS',	'S.MAHON'),
    createWinnersData('1863',	'BANKER',	'H.CHIFNEY',	'S.WALDOCK'),
    createWinnersData('1862',	'ARCHER',	'J.CUTTS',	'E.DE MESTRE'),
    createWinnersData('1861',	'ARCHER',	'J.CUTTS',	'E.DE MESTRE')
  ]

  const SaddleCloth = [
    createSaddleclothData('No 4', 12),
    createSaddleclothData('No 12', 11),
    createSaddleclothData('No 1', 10),
    createSaddleclothData('No 6', 10),
    createSaddleclothData('No 5 and No 8', 8),
    createSaddleclothData('No 2 and No 11', 7)
  ]

  const Colors = [
    createColorsData('Blue', 39),
    createColorsData('Black', 25),
    createColorsData('Red and Maroon', 19),
    createColorsData('Pink and Purple', 13),
    createColorsData('Yellow and Gold', 24),
    createColorsData('Grey and Silver', 5),
    createColorsData('Green', 7),
    createColorsData('Orange', 5),
    createColorsData('White', 14),
    createColorsData('Cream', 1),
    createColorsData('Brown', 2)
  ]
      
      const render=()=>{
        return(
          <Col  id='content' style={{marginTop: "24px" , marginLeft: "15px",marginRight: "15px", fontSize:"15px"}}>
          <div ref={ref}>
      
          <h1 style={{ textAlign: "center", fontSize: 50 }}>Melbourne Cup</h1>
      
          <p><strong>The Melbourne Cup</strong> is the most well-known yearly Thoroughbred horse race in Australia. 
            <strong> This famous race</strong>, is held as part of the weeklong Melbourne Cup Carnival of festivities and fashion, 
            is organised by the Victoria Racing Club at the Flemington Racecourse in Melbourne, Victoria. 
            It is one of the most prestigious turf races as well as the richest "two-mile" handicap in the world. 
            Locals refer to the event as <strong>"The Race that Stops the Nation"</strong> and it begins on the first Tuesday in November. 
            <br />
            <br />Every year, <strong>"Cup Day"</strong> as it is fondly called by Aussies, draws countless numbers of spectators to Flemington <strong>Race Course</strong>, Melbourne, for the biggest horse racing event in Australia. 650 million people worldwide watch the live broadcast of the 3,200-meter turf event on television.
            <br />
            {/* <iframe  width="100%" height="600" src="https://www.youtube.com/embed/XX2D1r-BuOY"></iframe> */}
            {/* <br /> */}
            <br />Considering that horse racing was frequently the only legal form of gambling during the 19th and 20th centuries and was a popular national sport. Everyone, including followers of other sports besides racing, enjoys The Melbourne Cup.
            <br />
            <br />Over the years, some great horses have won the Melbourne Cup. Horses like Phar Lap and Makybe Diva are just a couple of the iconic runners symbolic of this historical race.
            <br />
            <br />The caliber of the Melbourne Cup jockeys is as big as the caliber of the horses that make up the Melbourne Cup field, given the size of the prize fund at risk and the national attention on the big event. Glen Boss and Bobbie Lewis are two Melbourne Cup jockeys who have achieved fame equal to that of the horses they rode to victory on "Cup Day."
            <br />
            <br />Victoria Derby Day, Oaks Day, and Stakes Day are three additional significant race days that fall under the famed Melbourne Cup Day umbrella. There is no other week that celebrates horse racing as thoroughly as Melbourne does, which falls on the first week of every November, and resulted in giving this Carnival a special place in the world of racing.
            <br />
            <br />Let’s not forget what makes this event so exciting. A few traditions are associated with the Melbourne Cup, some of which include fancy dressing at selected race carnivals, the serving of champagne, wine, and fine finger foods, and not to forget to mention that the most popular thing to do on this day as well, is to place a bet; even those who don't ordinarily gamble do so on this special occasion because after all, the Melbourne Cup only comes along once a year!
          </p>
      
          <h2 style={{ fontSize: 40}}><strong><u>Contents</u></strong></h2>
          <ul style={{ marginLeft : '20px', fontSize: '20px'}}>
            <li>The Cup's Beginnings</li>
              <ul style={{ marginLeft : '20px', fontSize: '15px'}}><li>Early History</li></ul>
            <li>The Melbourne Cup Carnival</li>
              <ul style={{ marginLeft : '20px', fontSize: '15px'}}>
                <li>Penfolds Victoria Derby Day</li>
                <li>Lexus MElbourne Cup Day</li>
                <li>Kennedy Oaks Day</li>
                <li>VRC Champions Stakes Day</li>
              </ul>
            <li>Melbourne Cup Trophy</li>
            <li>Melbourne Cup Sponsors</li>
            <li>Hall of Fame</li>
              <ul style={{ marginLeft : '20px', fontSize: '15px'}}>
                <li>Famous Horses</li>
                <li>Famous Jockeys</li>
                <li>Famous Trainers</li>
              </ul>
            <li>Traditions</li>
            <li>National Holiday</li>
            <li>Fun Facts</li>
            <li>Statistics</li>
            <li>Melbourne Cup Lead Up Races</li>
            <li>Racing Calendar 2022</li>
          </ul>  
          <br/><br/>
          <h3 style={{ textAlign: "center", fontSize: 30 }}><strong><u>The Cup's Beginnings</u></strong></h3>
          <br />
          <p>Since the early years of European colonisation of the continent, 
            horseracing has played a significant and widespread role in Australian sporting culture.
            <br />
            <br />The Gold Rush city of Melbourne decided to hold horse races in 1840 where spectators could watch from a hill. 
            Before there was a true grandstand, which wasn't until 1848, the organisers had built some scaffolding for the spectators.
            <br />
            <br />The region was given the name "Flemington" by the owner after a piece of property his Scottish-born wife owned, and by the 1850s, everyone was referring to the track as the "Flemington Racecourse." 
            It is Australia's oldest and most famous racetrack today!
            <br />
            <br />An important component of Australia's national identity in the 19th century was the integration of horses and horsemanship. 
            The Melbourne Cup was born out of Victoria's "horse culture" and the state's explosive economic and population boom.
            <br />
            <br />Numerous racing clubs had been established by the middle of the 19th century, and regulations had been established to organise the activity.
            <br />
            <br />The Victorian Turf Club founded in 1852, and the Victorian Jockey Club founded in 1857, were the two governing bodies of horse racing in Victoria when the Melbourne Cup was first held in 1861. 
            The Turf Club created the Melbourne Cup to outdo the popularity of Jockey Club events like the Two Thousand Guineas.
            <br />
            <br />In 1864, the two rival organisations combined to become the Victoria Racing Club, 
            which has since governed racing in Victoria, including the Melbourne Cup.
            <br />
            <br />Nowadays, one of the few significant handicap horse races in the world is the Melbourne Cup. 
            A race that is handicapped assigns each horse a certain weight based on its age and past performance. 
            This makes it difficult for gamblers to predict which horse will beat its handicap.
          </p>
          <br /><br />
          <h3 style={{ textAlign: "center", fontSize: 25 }}><strong><u>Early History</u></strong></h3>
          <br />
          <p>Frederick Standish, the chairman of the Victorian Racing Club, is credited with coming up with the concept for a horse race in Victoria and giving it the name "Melbourne Cup." 
            Originally from England and a son of a Lord, Standish arrived on Australian shores in 1952. 
            Appointed as an Assistant Commissioner in 1954, he resigned as Chief Commissioner in 1880. 
            Standish, who served as the Victoria Racing Club's chairman from 1881 to 1883, was renowned for his passion of horses and horse racing and today is the man behind “The Race that Stops the Nation”.
            <br />
            <br />On Thursday, November 7, 1861, his dream came true when 17 horses competed in the first Melbourne Cup in front of 4,000 spectators. 
            The passing of explorers Burke and Wills a few days before the race meant that attendance was lower than anticipated.
            <br />
            <br />Victorian horse ‘Mormon’ was the favourite to win the race, but instead, a thoroughbred native of New South Wales by the name of ‘Archer’, finished first, and did so in 3 minutes and 52 seconds, which remains the slowest time on record. 
            It was said that in some place on the track, the grass was 3 meters long, which was definitely challenging for the horses on the day. 
            On the day, Archer was rode by John Cutts, trained by Etienne de Mestre, and won the race, but that year there was no cup presented to the winners and the first prize consisted of 710 gold sovereigns and a gold watch.  
            It was the race that ignited the long-running competitiveness between Victoria and New South Wales.
            <br />
            <br />The prize climbed to 810 gold sovereigns the next year, and attendance doubled to 7,000 people. 
            With a time of 3 minutes and 47 seconds, Archer successfully retained his title, and Mormon once more took second place. 
            A late message prevented Archer from competing in the Cup the following year, despite his intention to do so. 
            This pro1mpted trainers from across Melbourne to boycott the event, and as a result, their horses were scratched from the 1863 competition. 
            The Melbourne Cup had seven starters that year, the fewest ever in racing history.
            <br />
            <br />The first trophy was given out in 1865. It is said that Mr. Marshall, the winning horse's owner, called the English-made trophy a monstrosity. 
            Marshall sold it to the Flemington Hunt Club right away, and it was renamed as the prize for the club's annual cup. 
            Consequently, this trophy is officially identified as the Flemington Hunt Club Cup given to the champion, Babbler, in 1873. 
            Wine retailer Wolf Blass now owns the trophy that Christies sold in the 1970s.
            <br />
            <br />The earliest Melbourne Cup that is still in its pristine condition is from 1866 and was created by Daniel and Charles Houle in London in 1865, and Walsh Brothers Jewelers imported it to Australia on the Victorian Racing Club's behalf. 
            The cup is 75.5 centimeters tall and is made of silver-gilt chased with gold leaf. 
            It is an example of the Renaissance revival design popular in England in the middle of the 19th century.
            <br />
            <br />It can be argued that the 1867 Queen's Plate, crafted by Melbourne silversmith William Edwards, 
            was intended to be exhibited as a Melbourne Cup because the protruding horse's heads closely resemble those depicted on that piece. 
            <br />
            <br />The Melbourne Cup was won in 1868 by "Honest" John Tait, an immigrant to Australia in 1837, 
            went into racing as a business and was one of the first people that went into horse racing as a serious enterprise and become substantially successful, 
            as he was as a horse owner, trainer and punter. 
            He had extensively bet on Glencoe, his backup horse, at favorable odds before scratching his more fancied champion, The Barb, who was the undisputed pre-race favorite. 
            One of the first people to pursue horse racing as a serious economic enterprise was Tait.
             He was reportedly quite successful as a punter as well as an owner, trainer, and pundit. 
             Tait is regarded as one of Australia's greatest racers and has even been dubbed the "Father of the Australian Turf" by some. 
             Tait's excellent winning record includes four Melbourne Cups, four Victorian Derbys, two Queensland Derbys, four Australian Jockey Club Derbies, two Epsom Handicaps, two Sydney Cups, and three Victorian Racing Club Champion Stakes. 
             Tait was inducted into the Australian Racing Hall of Fame in 2006.
             <br />
             <br />The Barb, a wonderfully proportioned black yearling known as the "Black Demon" for his fiery disposition after he threw from his rider before the start of his maiden race, won the Melbourne Cup in 1866. 
             George Lee, the son of one of the area's first free settlers, William Lee, raised the Barb at Bathurst. 
             According to some tales, the horse was taken while still a foal at foot by bushrangers, but when he developed lameness, he was freed and later given back to his owners. 
             Even the horse's fiery disposition has been attributed to possible mistreatment by his captors at the time. 
             As a yearling, George Lee sold John Tait, The Barb.
             <br />
             <br />With 16 victories from 23 races, including the Metropolitan Stakes, two Sydney Cups, the Australian Jockey Club Derby, and the 1866 Melbourne Cup, The Barb had a remarkable record.
              The Barb was the country's first legendary champion racehorse, and he dominated the period when Australian racing switched from being predominately amateur to predominately professional. 
              Of all the horses honored by the Australian Racing Hall of Fame, The Barb was admitted first.
              </p>
            <br />
            <br /><br />
            <h3 style={{ textAlign: "center", fontSize: 30 }}><strong><u>The Melbourne Cup Carnival</u></strong></h3>
            <br />
            <p>The Melbourne Cup Carnival is a famous event of the highest caliber that brings together the best in racing, entertainment, fashion, cuisine, and wine in one location.
            <br />
            <br />It spans four days, almost a full week of festivities with a variety of events, not just one day of racing. 
            It’s the biggest social event of the year due to its unmatched prestige and heritage.
            <br />
            <br />The Melbourne Cup Carnival, which includes the famed Melbourne Cup Day in addition to the important race days of the Victoria Derby Day, Oaks Day, and Stakes Day, is a standout event in global horseracing. 
            It officially kicks off with Victoria Derby day, sometimes known as "Derby Day," and this year will start off on October 29, 2022.
            <br />
            <br />Old favourites, fresh ideas, and exciting racing are all expected to make Flemington Racecourse the place to be this spring, making this year's racing season remarkable and what fans hope for.
            <br />
            <br />It’s good to know that when visiting Flemington, visitors can purchase flowers from flower vendors near the entryway for each significant race day of the Spring Racing Carnival.
            <br />
            <br />Day-by-day schedule for the Melbourne Cup Carnival 2022:
            <br />
            <br />It can be argued that the 1867 Queen's Plate, crafted by Melbourne silversmith William Edwards, 
            was intended to be exhibited as a Melbourne Cup because the protruding horse's heads closely resemble those depicted on that piece. 
            <br />
            </p>
            <TableContainer component={Paper} style={{marginTop: 5}}>
              <Table style={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{fontSize: 18}}><strong>Day 1:<br />Saturday 29<br />October 2022</strong></TableCell>
                    <TableCell align="center" style={{fontSize: 18}}><strong>Day 2:<br />Tuesday 1<br />November 2022</strong></TableCell>
                    <TableCell align="center" style={{fontSize: 18}}><strong>Day 3:<br />thursday 3<br />November 2022</strong></TableCell>
                    <TableCell align="center" style={{fontSize: 18}}><strong>Day 4:<br />Saturday 5<br />November 2022</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow
                      key={''}
                      style={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="center" style={{fontSize: 15}}>Penfolds Victoria Derby Day</TableCell>
                      <TableCell align="center" style={{fontSize: 15}}>Lexus Melbourne Cup Day</TableCell>
                      <TableCell align="center" style={{fontSize: 15}}>Kennedy Oaks Day</TableCell>
                      <TableCell align="center" style={{fontSize: 15}}>VRC Champions Stakes Day</TableCell>
                    </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          <br /><br />
          <h3 style={{ textAlign: "center", fontSize: 25 }}><strong><u>Penfolds Victoria Derby Day</u></strong></h3>
          <br />
          <p>Every year, on the Saturday before the Melbourne Cup, Victoria Derby Day, also known as the Penfolds Victoria Derby, kicks off the weeklong Melbourne Cup carnival at Flemington Racecourse, which is hosted by the VRC (Victorian Racing Club). 
            This event is a three-year-old Group 1 Thoroughbred horse race with a $1.5 million prize fund that is run under Set Weights rules over a distance of 2,500 meters.
            <br />
            <br />With 129,069 attendees, the 2006 Victoria Derby attracted the largest audience of the competition. 
            The previous record was held by Makybe Diva's first Cup victory at the 2003 Melbourne Cup.
            <br />
            <br />The Victoria Derby first took place in 1855, and one horse named Fireworks, is historic for winning the Derby twice in a row. 
            In November 1867 and again in 1868, Fireworks succeeded in accomplishing the record by winning those consecutive races.
            <br />
            <br />Although racing is the primary focus of Victoria Derby Day because it is the only race day in Australia where every race has a Group Status, 
            Derby Day is frequently regarded as the most important race day for Fashions on the Field, especially for men. 
            <br />
            <br />A growing number of stylish men compete each year for awards and a place in the social sections. Traditionally, the ladies follow a black and white colour theme, 
            while gentlemen dress to impress in their own interpretations of sophisticated blacks, greys, and complimentary touches.
            <br />
            <br />A blue cornflower is the flower of the day! Mrs. Sheelah Wood, the wife of a well-known VRC committeeman at the time, 
            proposed the tradition of donning a cornflower on Derby Day in 1962.
            <br />
            <br />In addition to the $1 million TAB Empire Rose Stakes (1600m) for fillies and mares, Flemington Racecourse's nine-race program also features the $2 million Group 1 Coolmore Stud Stakes (1200m), 
            the pinnacle for three-year-old sprinters. 
            <br />
            </p>
            <br />
            <br /><br />
            <h3 style={{ textAlign: "center", fontSize: 25 }}><strong><u>Lexus Melbourne Cup Day</u></strong></h3>
            <br />
          <p>The most eagerly awaited racing event of the year is Melbourne Cup Day. The highlight of the Melbourne Cup Carnival, ‘Cup Day’ as it’s popularly referred to, is the highest point on the Australian racing calendar! The Melbourne Cup is a yearly event that takes place on the first Tuesday in November, 
            which is a full day public holiday in Melbourne and a half day holiday in Victoria.
            <br />
            <br />The Melbourne Cup gives the most lucrative prize in Australian sport in addition to an 18ct solid gold Hardy Brothers trophy 
            valued at over $175,000 and a stake in the $8 million prize pool and the chance to engrave their names in history.
            <br />
            <br />The Cup is a handicapped 3200-meter race that is categorised as a Group 1 race. While the Group 3 The Hong Kong Jockey Club Stakes (1400m) for mares and the Group 3 Darley Maribyrnong Plate (1000m) for two-year-olds are among the highlights of the undercard. 
            The popular Listed Resimax Group Subzero Handicap (1400m), a contest only open to grey horses, is also included.
            <br />
            <br />So, at 3 p.m., Australians pause for one of the world's most renowned horse races and even those who don't typically gambling will occasionally place a wager or buy a sweepstakes ticket.
            <br />
            <br />Also when visiting Flemington, yellow roses—the official flower for Melbourne Cup Day—can be bought from flower vendors at the entrances
            <br />
            </p>
            <br />
            <br /><br />
            <h3 style={{ textAlign: "center", fontSize: 25 }}><strong><u>Kennedy Oaks Day</u></strong></h3>
            <br />
            <p>The extravagant appearances, attire, 
            and racing experiences that have made Kennedy Oaks Day celebrations so well-known are beyond expectations.
            <br />
            <br />Every year, on the Thursday after Melbourne Cup day, the Victoria Racing Club hosts the $1 million Oaks Day, 
            a Group 1 Thoroughbred horse race for three-year-old fillies over 2,500 meters at Flemington Racecourse.
            <br />
            <br />Kennedy Oaks Day, often known as Ladies Day or just Oaks Day, has been a remarkable event in racing. 
            Despite taking place on a workday, Oaks Day holds the fastest-growing attendance statistics of all the carnival days. 
            The record attendance for the race day was reached in 2004 with 110,677 in attendance.
            <br />
            <br />The track's top athletes are the main attraction on race day, but the annual "Fashion on the Field" parties are another well-attended activity on Crown Oaks Day.
            <br />
            <br />At Flemington Racecourse, women of all ages gather to present their finest selves for everyone to see. 
            They dress in the most imaginative outfits, colours, accessories, and headpieces.
            <br />
            <br />Ladies Day and the Fashions on the Field concept were first implemented by the Victoria Racing Club in 1962 in an effort to attract more women to the track. 
            Since then, they have become a staple at racetracks and many fashionistas now consider it to be the industry standard for Fashions on the Field Day.
            <br />
            <br />Not to forget about the pink rose, which is the Oaks Day's official flower!
            <br />
            </p>
            <br />
            <br /><br />
            <h3 style={{ textAlign: "center", fontSize: 25 }}><strong><u>VRC Champions Stakes Day</u></strong></h3>
            <br />
          <p>The Melbourne Spring Racing Carnival's last day is referred to as Mackinnon Stakes Day or simply Stakes Day. 
            On the Saturday after Melbourne Cup Day, Stakes Day is held. 
            The Coburg Stakes, now known as the "Stakes," were held for the first time in 1881. 
            However, the event's name was changed from Stakes day to Champions Stakes day in 2022.
            <br />
            <br />The legendary $3 million Group 1 VRC Champions Stakes (2000m), 
            the spring championship race for Australia's top middle-distance horses, will take place on the Carnival's final day.
            <br />
            <br />Additionally, the $3 million Group 1 Darley Champions Sprint takes place on Cup Week's final day (1200m).
            <br />
            <br />Also the $3 million Group 1 Kennedy Champions Mile (1600 meters), which is for the best of the best over the Flemington mile, 
            will be added to the schedule on VRC Champions Stakes Day. 
            <br />
            <br />The Melbourne Spring Racing Carnival's Stakes Day is often referred to as Family Day since there are additional activities planned all day long for kids and families. Due to its laid-back and informal attitude, 
            Stakes Day has become one of the fastest growing occasions on the Australian racing calendar.
            <br />
            <br />There are countless events for kids, parents, and any other race enthusiast, whether they are young or old, in addition to the outstanding nine-race schedule that is offered. 
            VRC Champions Stakes Day is well-liked, featuring a never-ending variety of music, cuisine, dancing, and activities throughout the day.
            <br />
            <br />And for the final day the red rose is the flower of the day.
            <br />
            </p>
            <br />
            <br /><br />
            <h3 style={{ textAlign: "center", fontSize: 30 }}><strong><u>The Melbourne Cup Trophy</u></strong></h3>
            <br />
          <p>Early on and in the first four years, there was no trophy prize for the Melbourne Cup. 
            The Melbourne Cup, which was first run in 1861, was just a name for a competition with cash and gold watches as prizes. 
            The first official trophy cup was given out in 1865 and underwent several design and material changes through the following years.
            <br />
            <br />The owner of the 1865 prize, a complex silver bowl on a hand-made stand, reportedly thought it was ugly and decided to sell it. 
            The oldest Melbourne Cup trophy still in its original state is the one from 1866. 
            That 1866 cup was purchased by the Australian National Museum in 2012.
            <br />
            <br />The trophies were originally created in England and shipped to Australia specifically for the event early on in the race’s history.
            <br />
            <br />It wasn't until 1919 that the three-handled Melbourne Cup trophy as we know it debuted. 
            James Sleeth was hired by the VRC to create it. No one predicted that it was going to be a long-lasting design at the time. 
            But it appears that it was well received and just stuck. 
            It is still readily recognisable more than a century later. 
            <br />
            </p>
            <br />
            <br /><br />
            <h3 style={{ textAlign: "center", fontSize: 30 }}><strong><u>Melbourne Cup Sponsors</u></strong></h3>
            <br />
          <p>Many businesses would be eager to sponsor such a significant event as The Melbourne Cup and have their names and products prominently displayed.
            <br />
            <br />The sponsors of the Melbourne Cup 2022 include:
            </p>
            <ul style={{ marginLeft: '2%'}}>
            <li>The main partner<br /><strong>Lexus</strong>, a manufacturer of luxury automobiles.</li>
            <li>Other partners:
              <ul style={{marginLeft: '2%'}}>
                <li><strong>Furphy</strong>, the Australian beer manufacturer</li>
                <li><strong>Kennedy</strong>, an Australia jewellery and luxury watch shops for both men and women</li>
                <li><strong>MYER</strong>, high to mid-range department store chain in Australia</li>
                <li><strong>Penfolds</strong>, Australian wine company</li>
              </ul>
            </li>
            </ul>
            <br />
            <br />
            <br /><br />
            <h3 style={{ textAlign: "center", fontSize: 30 }}><strong><u>Melbourne Cup Hall of Fame</u></strong></h3>
            <br />
            The Melbourne Cup is both one of the most difficult, greatest and most awaited horse racing event on the Australian calendar, 
            partly because of the top competitors that attend.
            <br />
            <br />Not only does the runner achieve fame by winning The Cup, 
            but the jockeys and trainers have also gained recognition and got in limelight over the years.
            <br />
            <br />
            <ul style={{marginLeft: '2%'}}>
              <li ><strong style={{fontSize: 18}}>Famous Horses</strong><br />
              <br />Over the years, some great horses have won the Melbourne Cup, and that is due to the number of elite runners that compete which makes the event one of the toughest races in Australia. 
              This event consistently draws a strong field from across the globe who fight for prize money of millions of dollars!
              <br />
              <br />The Melbourne Cup, has begun to be significantly influenced by international horse with the likes of Trainer Dermot K. Weld, who had previously won the Cup with the Irish Horse Vintage Crop in 1993, returned in 2002 to do so with the European horse Media Puzzle. 
              The event was also won by a Japanese horse, Delta Blues, in 2006. 
              And the two most recent champions, Americain in 2010 and Dunaden in 2011, were both trained in France.
              <br />
              <br />The following are a few of the most well-known Melbourne Cup winners:
              
              <br />
              <br /><ul style={{marginLeft: '2%'}}>
                <li><strong>Phar Lap:</strong>(1936-1932)<br />
                <br />Phar Lap must be at the top of any list of outstanding historic Melbourne Cup horses. 
                Phar Lap, a champion thoroughbred racehorse that was born and raised in New Zealand, 
                is largely recognised as the best racehorse to ever run in the Melbourne Cup.
                <br />
                <br />During the challenging 1930s Great Depression, the public's fascination was sparked by his spectacular rise from difficult starts.
                <br />
                <br />In 51 races, Phar Lap finished first 37 times, including the 1930 Melbourne Cup. 
                A wave of rage and sorrow followed his death in the United States in 1932.
                <br />
                <br />Three times—in 1929, 1930, and 1931—Phar Lap entered the Melbourne Cup, carrying a handicap that reflected his popularity as the favourite each time. 
                The horse was so exceptional that upon his third attempt to win the Melbourne Cup in 1931, his handicap was set at 10 stone 10 pounds, the highest weight ever for the competition (just over 68 kilograms).
                <br />
                <br />After Phar Lap had won every significant race in Australia, Davis and Telford, who were now a part-owner of the horse, took him to the United States to compete in the 1932 Agua Caliente handicap, which was the richest race in the history of the globe.
                <br />
                <br />Billy Elliott's horse Phar Lap won the event by two lengths. 
                After a sudden and unexplained sickness that lasted for sixteen days, Phar Lap passed away in Atherton, California, in 1932. 
                Phar Lap was the third-highest stakes winner in the world at the time. 
                His mounted hide, skeleton, and heart are on display at the National Museum of Australia, the Museum of New Zealand, and the Melbourne Museum, respectively.
                </li>
                <br />
                <li><strong>Makybe Diva:</strong>(1999-retired)<br />
                <br />The only mare to have won the Cup more than once is the champion Australian Thoroughbred Makybe Diva. 
                She won the Melbourne Cup three times, in 2003, 2004 and 2005. In 2005, she was also the Cox Plate winner. 
                She won more than AUD $14 million, making her the greatest stakes winner in Australian history.
                <br />
                <br />Throughout the history of the Melbourne Cup, Makybe Diva is recognised as one of the best horses to compete. 
                Makybe Diva, although being a mare, conquered the Melbourne Cup field during her reign, shattering the record for most Cup victories and establishing a very high bar for successive victories. 
                She also set a new record for a mare's weight by winning the Cup in 2005 while toting a whopping 58kg.
                <br />
                <br />Today 'The Diva' is taking advantage of a well-earned retirement at Makybe Stud, a specialised thoroughbred farm Tony Santic built in Victoria. 
                Although none of her offspring have yet attained remarkable heights, she produced five race winners by the age of 22, and has recently retired from breeding.
                </li>
                <br />
                <li><strong>Archer:</strong>(1856-1872)<br />
                <br />The first and second Melbourne Cups were won by the Australian thoroughbred racehorse Archer in 1861 and 1862, respectively. 
                He comfortably won both Cups, making him one of only five horses to have won the Melbourne Cup twice or more and one of only four to have won back to back Cups.
                <br />
                <br />At the first Melbourne Cup, which was run in 1861, Archer prevailed over a field of 17 horses. 
                In 1862, he travelled back to Victoria for a repeat Melbourne Cup race and sprinted through the finish line to win by eight lengths in a field of 20 competitors. 
                Only three horses, including Archer, carried more than 60 kilograms to victory in the Melbourne Cup.
                <br />
                <br />In order to train for the September Metropolitan Cup at Randwick Racecourse, Archer was brought to Sydney, 
                however, on August 10, 1864, while receiving veterinary care, Archer suffered a critical injury that required immediate attention. 
                In spite of his recovery and quick return to training, he was ultimately disqualified from the race. 
                Archer was retired and brought back to his keepers Hassell and Roberts on Exeter Farm in Jembaicumbene, close to where he had been born, following the Randwick meeting.
                <br />
                <br />On December 22, 1872, at the age of 16, Archer met a tragic end due to a severe lung inflammation while in a field of green barley. 
                Sadly, his jockey, John Cutts, had passed away three months previously. 
                Now the Australian Racing Museum in Melbourne features a horseshoe ornament made from Archer's tail hair by Victorian craftswoman Therese Haynes.
                </li>
                <br />
                <li><strong>Rising Fast:</strong>(1949-1978)<br />
                <br />The only horse to achieve the Spring Grand Slam, winning the Melbourne Cup, Caulfield Cup, and Cox Plate in 1954, was Rising Fast, a great New Zealand-bred Thoroughbred racehorse. 
                At the time, these races were among the hardest! Rising Fast was essentially the winner of all Australian horse racing, not just the Melbourne Cup.
                <br />
                <br />With that being said, Rising Fast has the reputation of being the best handicap stayer to compete in Australia.
                <br />
                <br />In 1956, it was announced that Rising Fast would retire, and the Moonee Valley Racing Club organized a special farewell for the champion, 
                who made his way up the straight whilst a band played "The Maori Farewell."
                <br />
                <br />In both Australia and New Zealand, Rising Fast is well respected and was admitted to the Australian and New Zealand Racing Halls of Fame.
                </li>
                <br />
              </ul>
              </li>
              <li><strong style={{fontSize: 18}}>Famous Jockeys</strong><br />
              <br />At the 'Race That Stops A Nation,' several Melbourne Cup jockeys have achieved fame equal to that of the horses they rode to victory. 
              Despite the huge prize package that comes with winning the Melbourne Cup, many riders view the victory itself as the reward because more than 100,000 people are watching history being made.
              <br />
              <br />However, some historical occurrences stand out from the others. 
              For instance, Maree Lyndon rode Argonaut Style in 1987 to become the first female to ride a Melbourne Cup contender. 
              2015 saw Michelle Payne become the first female jockey to ride Prince of Penzance to the Melbourne Cup victory, making history.
              <br />
              <br />The riders listed below are some of the famous Melbourne Cup riders:
              <ul>
              <br />
                <li><strong>John 'Cutts' Dillon:</strong>(1829-1872)<br />
                <br />The rider that rode Archer over the winning post in the first two Melbourne Cup races in 1861 and 1862 was John Cutts, who was 32 years old at the time. 
                John "Cutts" Dillon was a well-known, well-liked, and well-respected jockey in New South Wales for much of his long career.
                </li>
                <br />
                <li><strong>Bobby Lewis:</strong>(1878-1947)<br />
                <br />Bobbie's mother, who was allegedly a skilled horsewoman, is said to be the cause of his passion for horses.
                <br />
                <br />Bobby Lewis won the Melbourne Cup four times and only Harry White matched this record later on. 
                Lewis won The Victory in 1902, Patrobas in 1915, Artilleryman in 1919, and Trivalve in 1927.
                <br />
                <br />He also took home many additional high-classification victories, including an astounding eight wins in the Victoria Derby and VRC St. Legers.
                </li>
                <br />
                <li><strong>Harry White:</strong>(1944-retired)<br />
                <br />Harry White is a former jockey from Australia. Particularly in the 1970s, he was considered to be among the best jockeys in the nation. 
                White was well known for using his discretion in long-distance situations.
                <br />
                <br />Harry White, who triumphed riding, Think Big in 1974 and 1975, Arwon in 1978, and Hyperno in 1979, and is the only other rider to have won the Cup four times. 
                A fantastic connection between two Australian racing icons resulted from his Melbourne Cup-winning rides for Bart Cummings, the best Melbourne Cup trainer of all time. 
                Three Newmarket Handicaps, three Oakleigh Plates, and three Futurity Stakes victories are also among his sprinting accomplishments.
                </li>
                <br />
                <li><strong>Glen Boss:</strong>(1969-retired)<br />
                <br />Australian jockey Glen Boss is arguably the most well-known Melbourne Cup rider in recent times since he is most known for winning the Melbourne Cup on Makybe Diva in 2003, 2004, and 2005. 
                This achievement, which is unlikely to be repeated anytime soon, set new records for the number of victories by a horse as well as the number of Melbourne Cup victories consecutively by jockey.
                <br />
                <br />Additionally, Boss has won four Cox Plate races: Makybe Diva in 2005, So You Think in 2009, Ocean Park in 2012, and Sir Dragonet in 2020.
                </li>
                <br />
              </ul>
              </li>
              <li><strong style={{fontSize: 18}}>Famous Trainers</strong><br />
              <br />Horse trainers are tasked with the extremely difficult job of making sure each owner's horse is capable of giving it’s all in every race it competes in, 
              but many of them go above and beyond for the horses they prepare for a Melbourne Cup run.
              <br />
              <br />A champion horse needs the ideal combination of training, nature and nurturing. 
              A horse has the talent to win the Melbourne Cup if they are born with the right genetics, which gives them the biological advantage.
              <br />
              <br />At elite competitions like the Melbourne Cup, there isn't such a thing as a natural champion. 
              Human coaching and direction are required for horses to reach their maximum potential. 
              Even the strongest, fastest, and smartest horses need human assistance to perfect their skills on a race track.
              <br />
              <br />Here is a list of the all-time top and most famous Australian horse racing trainers:
              <ul>
              <br />
              <li><strong>Bart Cummings:</strong><br />
              <br />James Bartholomew Cummings, often known by his initials J.B. Cummings, was born on November 14, 1927 and was one of the most accomplished racehorse trainers in Australia.
              <br />
              <br />When Cummings, then 23 years old, saddled the family-bred horse "Comic Court" for his father Jim Cummings in 1950, he felt the thrill of a Melbourne Cup victory for the first time. 
              He has acquired the label "Cups King" for his training accomplishment of producing 12 Melbourne Cup winners and five quinellas to date.
              <br />
              <br />Despite having a hay allergy, Cummings, an Adelaide native, worked as a strapper at the Adelaide stables for his father. He got his own training license in 1953, using his father’s stables as his training ground. With "Stormy Passage," he won his first Group 1 race at the SAJC Derby in 1958. 
              He ended up winning 268 Group 1 events over the following 62 years.
              <br />
              <br />Light Fingers (1965), Galilee (1966), Red Handed (1967), Think Big (1974 and 1975), Gold and Black (1977), Hyperno (1979), Kingston Rule (1990), Let's Elope (1991), Saintly (1996), Rogan Josh (1999), and Viewed (2008) are among his twelve Melbourne Cup wins.
              <br />
              <br />Stables at Flemington, now called Saintly Place, were created by Cummings in 1968; they served as his headquarters in Melbourne until his death in 2015. 
              In 1975, he also established stables at Randwick, now called Leilani Lodge, from which he oversaw his training and spelling activities.
              <br />
              <br />Cummings initially gained national attention when he not only trained the winners of the following two Melbourne Cups—"Galilee" in 1966 and "Red Handed" in 1967—but also won his first Cup with "Light Fingers" in 1965. 
              He also prepared the runners-up in 1965 and 1966.
              <br />
              <br />For his contributions to the horse racing industry, Bart Cummings AM was raised to the status of Legend of Australian Sport in 2008 and admitted into the Sport Australia Hall of Fame as a General Member in 1991.
              <br />
              <br />Cummings passed away on August 30, 2015, at the age of 87, at Prince's Farm in Castlereagh, New South Wales.
              </li>
              <br />
              <li><strong>Lee Freedman:</strong><br />
              <br />Australian trainer of thoroughbred racehorses and member of the Hall of Fame David Lee Freedman was born on August 12, 1956. 
              He has won numerous big races in Australia over the past 20 years in collaboration with his brothers Anthony, Michael, and Richard, including two of the three Melbourne Cups won by Makybe Diva. 
              He has also won four Golden Slippers, four Caulfield Cups, two Cox Plates, and five Melbourne Cups.
              <br />
              <br />When Lee was a young boy, his father changed his profession from real estate developer to horse breeder, which ignited Lee's interest in racing.
              <br />
              <br />Lee Freedman, who runs the family stud farm at Yass in southern New South Wales, became involved in the horse breeding profession as a result of growing up surrounded by the show and pageantry that Australian thoroughbred racing offered.
              <br />
              <br />The Melbourne Cup victories in 2004 and 2005 by "The Diva," who went on to win the Cup three times overall—a feat unheard of in the more than 150-year history of the race—are Freedman's greatest training accomplishments.
              <br />
              <br />Lee Freedman quit from training in August 2011 due to a fall in success for Freedman Brothers. 
              Anthony and Michael Lee, Lee's brothers, took over Markdel's training responsibilities. 
              Over the next three years, Freedman worked in the racing industry in a variety of capacities, 
              including director of racing for Lloyd Williams' Macedon Lodge, a training partnership with Graeme Rogerson in Sydney, and an adviser post with Sir Peter Vela's Pencarrow Stud in New Zealand.
              <br />
              <br />Freedman announced in November 2014 that he would resume training with stables in Flemington and Pinecliff on the Mornington Peninsula, working alongside Anthony. 
              The sprinters Shoals and Santa Ana Lane were the pair's most successful horses at this time.
              <br />
              <br />Midway through 2017, Freedman relocated to Singapore to train horses there, taking over Laurie Laxon's stables, who was born in New Zealand. 
              The 2018 Group 1 Singapore Guineas champion, Mr. Clint, was trained by Lee Freedman.
              <br />
              <br />Freedman declared his intention to return to Australia in late 2020, in part as a result of COVID-19 and its implications on the racing in Singapore.
              <br />
              <br />In 2003, Lee Freedman was officially inducted into the Australian Hall of Fame. As for his future, it is reasonable to anticipate more success to add to his already excellent accomplishments given his 25 years of experience and young age (only in his mid-50s).
              </li>
              <br />
              <li><strong>Gai Waterhouse:</strong><br />
              <br />Gai Waterhouse is one woman who defied cultural norm in a world where men predominate and went on to become one of Australia's top personal trainers instead of conforming to it.
              <br />
              <br />A horse trainer and businesswoman from Australia, Gabriel Marie "Gai" Waterhouse was born on September 2, 1954. 
              Waterhouse was born and raised in Sydney and is the daughter of Tommy J. Smith, a well-known Thoroughbred racehorse trainer. 
              After graduating from the University of New South Wales, she acted for a while, performing in both Australian and English television series.
              <br />
              <br />After spending 15 years working for her father, Waterhouse received an Australian Jockey Club (AJC) license in 1992. 
              Later that year, she trained her first Group One (G1) winner.
              <br />
              <br />She took over her father's Tulloch Lodge stable in 1994 after learning of his illness. 
              Since then, she has trained 145 G1 winners and garnered seven Sydney trainers' premierships. 
              She was also Fiorente's trainer, making her the third woman, and the very first Australian woman, to train a Melbourne Cup victor in 2013. 
              Waterhouse, known as the "First lady of Australian racing," was admitted into the Australian Racing Hall of Fame in 2007.
              <br />
              <br />She became a member of the Australian Racing Hall of Fame in 2007 and joined her father, who had been inducted in the previous year and had a record 282 Group 1 victories. 
              When Waterhouse was inducted, only Smith and the late Bart Cummings, a Legend of Australian Sport, had trainer more Group 1 victories (236).
              <br />
              <br />Six Metropolitan Handicaps, seven Doncaster Handicaps, four Epsom Handicaps, the training of all three finishers in the 2001 Golden Slipper, and a fourth Golden Slipper in 2012 are among her 114 Group 1 victories. 
              <br />
              <br />The VRC and Thoroughbred Racehorse Owners' Association Archer Award was given to Waterhouse in recognition of her exceptional achievement and contributions to the racing industry. 
              She also received the Telstra NSW Business Woman of the Year award in 2000.
              </li>
              </ul>
              </li>
            </ul>
            <br />
            <br /><br />
            <h3 style={{ textAlign: "center", fontSize: 30 }}><strong><u>Melbourne Cup Traditions</u></strong></h3>
            <br />
            <p>Many of the locals' actions around this occasion have developed into traditions through time. 
              Throughout this race week, Australians keep themselves occupied and amused with everything from fashion shows to food to gambling.
              <br />
              <br />On Cup day, local races are staged all across the nation, and the Flemington Racecourse's featured race is aired on screens at racetracks all throughout Australia. 
              And around 3 o'clock on Melbourne Cup Day, a lot of people stop what they are doing to watch or listen to the main race on television, the Internet, or the radio.
              <br />
              <br />At the races, people dress up and make sure that race day dress code is like no other. 
              Many women come out to shine in their best or brightest hats and outfits, and some even take part in fashion parades on the field. 
              For the attendees, there is no set attire but they are urged to dress formally for the event.
              <br />
              <br />VIP attendees, including local, national, and international celebrities attend this festive event, and have marquees made up for them. 
              Also on this day, fine wine, champagne, and finger appetisers are typically provided.
              <br />
              <br />Closer to the start of the major race, many offices all throughout the nation in Australia take a day off to celebrate, despite the fact that it is only an official holiday in Victoria. 
              Staff lunches at restaurants, company parties with hat and dress contests, and afternoon teas with a television so that employees may watch the main race are some of the events.
              <br />
              <br />Even individuals who are not particularly engaged in horse racing, place bets on this day because it is regarded as the most anticipated event by gamblers.
            </p>
            <br />
            <br /><br />
            <h3 style={{ textAlign: "center", fontSize: 30 }}><strong><u>Melbourne Cup a National Holiday</u></strong></h3>
            <br />
            <p>
            In 1865, Melbourne Cup Day was initially made a holiday for Victorian government employees and bank workers. 
            The Victoria Government took some time to accept the justification for the declared holiday, but the next year it was proclaimed a public holiday for all other public and private sector workers, 
            and several businesses also followed midday closings and this tradition continues today. 
            </p>
            <br />
            <br /><br />
            <h3 style={{ textAlign: "center", fontSize: 30 }}><strong><u>Fun Facts about the Melbourne Cup</u></strong></h3>
            <br />
            <p>Hundreds of thousands of people flock to the Melbourne racetrack for the Spring Cup Carnival, the greatest horse racing event in Australia. It all boils down to the Melbourne Cup, 
              the major event whether people are there to watch the horses, participate in Fashions On The Field, or to simply drink champagne.
              <br />
              <br />Given the long history of this glamourous race, many interesting events have undoubtedly taken place. 
              Here are some intriguing Melbourne Cup-related facts:
            </p>
              <ol style={{marginLeft: '2%'}}>
                <li>The Melbourne Cup was merely a trophy in name when it was first run in 1861, with cash and a gold watch as the only rewards. 
                  It wasn’t until 1865 that the first prise cup was given out formally.
                </li>
                <br />
                <li>
                The Melbourne Cup race track was originally 3.219 kilometres long, but when Australia implemented the metric system in 1972, the distance was shortened to 3,200 metres (1 mile and 1,740 yards).
                </li>
                <br />
                <li>
                With a time of 3:16.3, the winner of the 1990 Melbourne Cup, Kingston Rule, holds the current record for the fastest time.
                </li>
                <br />
                <li>
                The Melbourne Cup was held on Saturdays for four years from 1942 to 1945, during World War II.
                </li>
                <br />
                <li>
                Clearly expressed safety concerns led to the cap of 24 starters. A lot more people were permitted to participate in the Melbourne Cup races in the past, with 39 runners competing in the largest field ever in 1890.
                </li>
                <br />
                <li>
                Peter St. Albans rode Briseis to victory in the 1876 Melbourne Cup, becoming the sport's youngest champion jockey in history. 
                At the time, he was 12 years, 11 months, and 23 days old.
                </li>
                <br />
                <li>
                The Melbourne Cup was won by Michelle Payne in 2015, marking her the first female jockey to do so with The Prince of Penzance as her ride.
                </li>
                <br />
                <li>
                The record for most Melbourne Cup victories belongs to trainer Bart Cummings, whose horses have won the race a record of 12 times.
                </li>
                <br />
                <li>
                The largest group of bettors are from New South Wales. New South Wales gamblers place $1,200 bets on average. 
                Amazingly, that is 22% higher than the national average in 2014.
                </li>
                <br />
                <li>
                Archer, who was the first winner of the first ever Melbourne Cup in 1861, finished at a time of 3 minutes 52 seconds, and holds the record for the slowest time to ever win the event.
                </li>
              </ol>
            <br />
            <br /><br />
            <h3 style={{ textAlign: "center", fontSize: 30 }}><strong><u>Melbourne Cup Winners Statistics</u></strong></h3>
            <br />
            <h3 style={{ textAlign: "center", fontSize: 25 }}><strong>Melbourne Cup Winning Colors and Numbers</strong></h3>
            <br />
            It's not necessary to be an avid race fan to enjoy placing a wager on the Melbourne Cup. 
            Every year, millions of wagers are placed on the Cup, many of those bets are placed on the color worn by the jockey or the number of the winning horse’s saddlecloth.
            <br />
            <br />The jockeys most popular winning colour choices for the Melbourne Cup are broken down in the following:
            <br />
            <br />
            <TableContainer component={Paper} >
            <Table style={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{fontSize: 16}}><strong>Silk Color</strong></TableCell>
                  <TableCell align="center" style={{fontSize: 16}}><strong>Number of Wins</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Colors.map((color) => (
                  <TableRow
                    key={color.color}
                    style={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center" style={{fontSize: 13}}>{color.color}</TableCell>
                    <TableCell align="center" style={{fontSize: 13}}>{color.count}</TableCell>
                  </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <br />The most successful saddlecloth numbers are:
          <br />
          <br />
          <TableContainer component={Paper} >
            <Table style={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{fontSize: 16}}><strong>Number</strong></TableCell>
                  <TableCell align="center" style={{fontSize: 16}}><strong>Wins</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {SaddleCloth.map((cloth) => (
                  <TableRow
                    key={cloth.number}
                    style={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center" style={{fontSize: 13}}>{cloth.number}</TableCell>
                    <TableCell align="center" style={{fontSize: 13}}>{cloth.wins}</TableCell>
                  </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <br /><br />
          <h3 style={{ textAlign: "center", fontSize: 25 }}><strong>Melbourne Cup Winners</strong></h3>
          <br />
          <br />There have been many Cup winners during the course of 160 years of racing. 
          The complete list of each year's winning horse, jockey, and trainer is as follows:
          <br />
          <br />
          <TableContainer component={Paper} >
            <Table style={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{fontSize: 16}}><strong>Year</strong></TableCell>
                  <TableCell align="center" style={{fontSize: 16}}><strong>Winner</strong></TableCell>
                  <TableCell align="center" style={{fontSize: 16}}><strong>Jockey</strong></TableCell>
                  <TableCell align="center" style={{fontSize: 16}}><strong>Trainer</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Winners.map((winner) => (
                  <TableRow
                    key={winner.year}
                    style={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center" style={{fontSize: 13}}>{winner.year}</TableCell>
                    <TableCell align="center" style={{fontSize: 13}}>{winner.winner}</TableCell>
                    <TableCell align="center" style={{fontSize: 13}}>{winner.jockey}</TableCell>
                    <TableCell align="center" style={{fontSize: 13}}>{winner.trainer}</TableCell>
                  </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <br /><br />
          <h3 style={{ textAlign: "center", fontSize: 30 }}><strong><u>Melbourne Cup Lead-Up Races</u></strong></h3>
          <br />
          As the competitors move toward the 2022 Melbourne Cup, there are a number of significant lead-up races to watch out for. 
          The best predictors of a successful Cup performance in previous years were the Caulfield Cup and the Cox Plate, but as racing trends change, so do the form races coming up to the Melbourne Cup.
          <br />
          <br />The major races that bettors should pay close attention to are: 
          <br />
          <ul style={{marginLeft: '3%'}}>
            <li>The Andrew Ramsden Stakes</li>
            <li>Turnbull Stakes</li>
            <li>The Bart Cummings</li>
            <li>Herbert Power Stakes</li>
            <li>The Caulfield Cup</li>
            <li>Geelong Cup</li>
            <li>The Cox Plate</li>
            <li>Moonee Valley Cup</li>
            <li>The Lexus Stakes</li>
          </ul>
          <br />
          <br />
          <ol>
            <li><strong>The Andrew Ramsden Stakes (14 May 2022)</strong><br />
            <br />Every year, the Andrew Ramsden Stakes is a listed event run at Flemington Racecourse in Victoria. 
            The Andrew Ramsden Stakes (2800m) is a handicap race for horses 3 years old, 4 years old, and 5 years old, and it has a $400,000 total prize fund.
            <br />
            <br />One of the Melbourne Cup ballot exemption races is the winner of the Andrew Ramsden Stakes. 
            (the winner receives straight admission into the Melbourne Cup.)
            </li>
            <br />
            <li><strong>Turnbull Stakes (1 October 2022)</strong><br />
            <br />A great lead-up race for the Spring Racing Carnival is the Group 1 staying race known as the Turnbull Stakes. 
            It is a valuable training race for the feature Cups and is run over 2000 meters at Flemington Racecourse every year.
            <br />
            <br />The event is at the Group 1 level, with a $500,000 total prize fund, and is held under predetermined weight plus penalty rules.
            </li>
            <br />
            <li><strong>The Bart Cummings(1 October 2022)</strong><br />
            <br />The Bart Cummings is a Group 3 event that takes place at Flemington Racecourse every year during the Melbourne spring racing carnival. 
            The Bart Cummings (2500m) has a $300,000 prize fund overall and is run under excellent handicap settings.
            <br />
            <br />The winner of the Bart Cummings automatically enters the Melbourne Cup field since it is one of five events that offer ballot-exemption for the Melbourne Cup.
            <br />
            <br />‘The Bart’ as it is renowned for, has established itself as a strong form event going into the Cup, 
            despite only being a direct entry race since 2015, giving horses the longest window of time to prepare for the Melbourne Cup after the ballot-exempt race.
            </li>
            <br />
            <li><strong>Herbert Power Stakes (8 October 2022)</strong><br />
            <br />Each year, the Group 2 Herbert Power Stakes (2400m) race is hosted at Caulfield Racecourse in Victoria. 
            It is open to horses 3 years of age and older and is run under handicapping rules. 
            The total prize money for this race, is $400,000.\
            <br />
            <br />For horses aiming for the Caulfield Cup and Melbourne Cup, the Herbert Power Stakes has established itself as a respectable preparatory event.
            </li>
            <br />
            <li><strong>Caulfield Cup (15 October 2022)</strong><br />
            <br />The Caulfield Cup never disappoints, featuring some of the best horses in the world competing over 2400 meters for $5 million in cash prizes. This Group 1 race, which takes place at Caulfield Racecourse every year, is one of the hardest and most valuable handicap races in the world. 
            The Melbourne Racing Club hosts the Caulfield Cup, which is Australia's second most watched horse race after the Melbourne Cup.
            <br />
            <br />The majority of the time, the winner of a Caulfield Cup will go on to compete in the Melbourne Cup, but given that only 11 horses have won both the Caulfield and Melbourne Cup in their respective histories, it appears to be quite difficult to win both events in a given year.
            </li>
            <br />
            <li><strong>Geelong Cup (19 October 2022)</strong><br />
            <br />Each year, the Geelong Cup, a Group 3 race run at Geelong Racecourse in Victoria, has served as a reliable preview to the Melbourne Cup, particularly for runners from abroad.
            <br />
            <br />The Geelong Cup (2400m), which has a $500,000 total prize fund and is open to fillies and mares 3 years of age and older, is held under specified weight + penalty rules.
            <br />
            <br />Due to the winner's assured start in the Melbourne Cup, the Geelong Cup generates a lot of interest. 
            The Group 2 Herbert Power Stakes and the Group 1 Caulfield Cup are two popular races that come before the Geelong Cup, 
            but many of the competitors are Melbourne Cup contenders who haven't yet locked up a position in the main event.
            </li>
            <br />
            <li><strong>Cox Plate (22 October 2022)</strong><br />
            <br />The Cox Plate is a famous Group 1 event for horses three years old and older. 
            Since 1922, the Cox Plate has been held under weight-for-age conditions at Melbourne's Moonee Valley Racecourse.
            <br />
            <br />This race's lengthy and illustrious history has seen a number of high-caliber horses win it, including Phar Lap, Sunline, Northerly, and Kingston Town.
            <br />
            <br />2040 meters are covered in the Cox Plate race, which offers a $5 million prize pool. 
            Another factor contributing to the Cox Plate's rise to prominence is its strategic placement on the racing calendar between the Caulfield Cup, Victoria Derby, and Melbourne Cup.
            <br />
            <br />The Cox Plate has long been regarded as one of the most prestigious competitions in global horse sport. 
            The Cox Plate, known as "the greatest two minutes in sport," has almost exclusively been won by athletes from Australia and New Zealand, yet it is still regarded as one of the most prestigious international competitions.
            <br />
            <br />Furthermore, the famous, ‘So You Think’ is the only horse to have won the Cox Plate and then advanced to the Melbourne Cup between 2010 and 2018.
            </li>
            <br />
            <li><strong>Moonee Valley Cup (22 October 2022)</strong><br />
            <br />Each year, Moonee Valley Racecourse hosts the Moonee Valley Cup, a Group 2 event. The highlight distance race on the Moonee Valley calendar, the 2500m competition is conducted in late October every year. 
            The competition has a $500,000 total prize purse, making it a desirable objective for both local and foreign horseback riders.
            <br />
            <br />The Moonee Valley Cup is typically the goal for staying the distance horses who are above average and may not necessarily be the elite stayers, but who aim for the Melbourne Cup. 
            Frequently a strong race for stayers who missed the Caulfield Cup field.
            </li>
            <br />
            <li><strong>Lexus Hotham Stakes (29 October 2022)</strong><br />
            <br />The Lexus Hotham Stakes, a Group 3 race that takes place at Flemington Racecourse in Victoria every year, is an important race to watch before the Melbourne Cup since the winner receives a ballot exemption.
            <br />
            <br />The Lexus Hotham Stakes (2500m) has a $300,000 prize fund overall and is held under excellent handicapping rules.
            <br />
            <br />The winner of the Lexus Hotham Stakes receiving automatic entrance into the Melbourne Cup, as this race is the last throw of the dice for stayers. 
            A typical lead-up race for this event is the Group 2 Moonee Valley Cup, which is contested at Moonee Valley one week before the Hotham Handicap.
            <br />
            <br />The Lexus Hotham Stakes has only been a mild form guide for the 3200m feature given the races closeness to the Melbourne Cup. 
            The only horses to win both the Lexus Stakes and Melbourne Cup in the last 30 years were ‘Brew’ in 2000 and ‘Shocking’ in 2009.
            </li>
          </ol>
          <br />
          <br /><br />
          <h3 style={{ textAlign: "center", fontSize: 30 }}><strong><u>Racing Calendar 2022</u></strong></h3>
          <br />
          <br />The important dates, such as those for nominations, acceptances, final fields, are shown here:
          <br />
          <br />
          <TableContainer component={Paper} >
            <Table style={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{fontSize: 16}}><strong>Event</strong></TableCell>
                  <TableCell align="center" style={{fontSize: 16}}><strong>Location</strong></TableCell>
                  <TableCell align="center" style={{fontSize: 16}}><strong>Date</strong></TableCell>
                  <TableCell align="center" style={{fontSize: 16}}><strong>Type</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Calendar.map((calendar) => (
                  <TableRow
                    key={calendar.event}
                    style={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center" style={{fontSize: 13}}>{calendar.event}</TableCell>
                    <TableCell align="center" style={{fontSize: 13}}>{calendar.location}</TableCell>
                    <TableCell align="center" style={{fontSize: 13}}>{calendar.date}</TableCell>
                    <TableCell align="center" style={{fontSize: 13}}>{calendar.type}</TableCell>
                  </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          </div>
          </Col>
          
        )
      }
        
        const ref = useRef(null)
      
        const [height, setHeight] = useState(0);
      
        useEffect(() => {
          setHeight(ref.current.clientHeight)
        },[])
      
        
      
        if(window.innerWidth > 1200 ){
        return (
          <Row>
          <Col className="d-block col-md-2" style={{ overflowY:"auto",marginTop: "24px",  height:height, marginLeft:"10px"}}>
          <h5 style={{
          backgroundColor: "#142841",
          height: 32,
          borderRadius: 4,
          textAlign: "center",
          color: "white",
          fontWeight: "600",
          margin: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "350px",
          marginLeft: "auto",
          marginRight: "auto",
          position: "sticky",
          top: 0,
          zIndex:1,
        }}>NEXT PTP TIPS</h5>
          <Next10/>
          </Col>
      
        
         {render()}
        
      
          <Col className="d-block col-2" style={{ overflowY:"auto",marginTop: "24px",  height:height, marginRight:"10px"}}>
          <h5 style={{
          backgroundColor: "#142841",
          height: 32,
          borderRadius: 4,
          textAlign: "center",
          color: "white",
          fontWeight: "600",
          margin: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "350px",
          marginLeft: "auto",
          marginRight: "auto",
          position: "sticky",
          top: 0,
          zIndex:1,
        }}>PREV PTP TIPS</h5>
          <Last10/>
          </Col>
        
          </Row>
        )
      
      
      }else if(window.innerWidth < 1200 ){
        return(
          <Row>
          
          {render()}
      
          <Col style={{ marginTop: "24px" }}>
                  <h5
                    style={{
                      backgroundColor: "#142841",
                      height: 32,
                      borderRadius: 4,
                      textAlign: "center",
                      color: "white",
                      fontWeight: "600",
                      margin: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      maxWidth: "350px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}>
                    NEXT PTP TIPS
                  </h5>
                  <div
                    style={{
                      display: "flex",
                      overflowX: "scroll",
                      overflowY: "hidden",
                      marginBottom: "8px",
                    }}>
                    <Next10 />
                  </div>
                </Col>
          
          <Col style={{ marginTop: "24px" }}>
                  <h5
                    style={{
                      backgroundColor: "#142841",
                      height: 32,
                      borderRadius: 4,
                      textAlign: "center",
                      color: "white",
                      fontWeight: "600",
                      margin: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      maxWidth: "350px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}>
                    PREV PTP TIPS
                  </h5>
                  <div
                    style={{
                      display: "flex",
                      overflowX: "scroll",
                      overflowY: "hidden",
                      marginBottom:"10px",
                    }}>
                    <Last10 />
                  </div>
                </Col>
        
          </Row>
        )
      }
      }

export default MelbourneCup