import { combineReducers } from "redux";
import auth from "./auth";
import homeReducer from "./homeReducer";
import selectionReducer from "./selectionReducer";
import resultsReducer from "./resultsReducer";
import raceReducer from "./raceReducer";
import lastWinnersReducer from "./lastWinnersReducer";
import NextTojumpReducer from "./NextTojumpReducer";
import oddsUpdateReducer from "./oddsUpdateReducer";
import getGenTimeReducer from "./generationTimeReducer";
import onNowReducer from "./onNowReducer";
import profilesReducer from "./profilesReducer";
import venuesReducer from "./venuesReducer";
import racesAnalyticsReducer from "./racesAnalyticsReducer";
import blackbook from "./blackbookReducer";

const reducers = combineReducers({
  auth,
  homeReducer,
  selectionReducer,
  resultsReducer,
  raceReducer,
  lastWinnersReducer,
  NextTojumpReducer,
  oddsUpdateReducer,
  getGenTimeReducer,
  onNowReducer,
  profilesReducer,
  venuesReducer,
  racesAnalyticsReducer,
  blackbook,
});

export default reducers;
