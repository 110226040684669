import React from "react";
import { Link } from "react-router-dom";

// MUI
import { Instagram, Facebook, Twitter } from "@material-ui/icons";

const Footer = () => {
  const navigator = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="Page">
      <div
        className="footer kt-grid__item bgi-position-center-center bgi-size-cover bgi-no-cover"
        id="kt_footer"
        style={{ backgroundColor: "#e7e9f1" }}
      >
        <div className="container py-8">
          <div className="row">
            <div className="col-lg-12 my-lg-0 my-5">
              <h3 style={{ fontWeight: "bolder", color: "#44bd32" }}>
                About Us
              </h3>
              <p
                style={{
                  color: "#142841",
                  fontWeight: "bolder",
                  textAlign: "justify",
                  fontSize: "13px",
                }}
              >
                Making Money On Horse Races Is Something That Everyone Would
                Love To Do, But Few People Have The Knowledge Or The Time To
                Devote To Doing It Properly. At Past The Post We Provide You
                With Ratings On Every Horse With Racing Form In Australia On A
                Daily Basis.
              </p>
              <ul></ul>
            </div>

            <div className="col-lg-6 my-lg-0 my-10">
              <h3 style={{ fontWeight: "bolder", color: "#44bd32" }}>
                Quick Links
              </h3>
              <div className="d-flex align-items-center">
                <div className="d-flex flex-column mr-18">
                  <Link
                    style={{ color: "#142841", fontWeight:"bolder" }}
                    to="/frequently-asked-questions-faq"
                    // className="text-white opacity-55 text-hover-primary"
                    onClick={() => {
                      navigator();
                    }}
                  >
                    FAQ
                  </Link>
                  <ul></ul>
                  <Link
                    to="/contactus"
                    style={{ color: "#142841", fontWeight:"bolder" }}
                    onClick={() => {
                      navigator();
                    }}
                  >
                    Contact Us
                  </Link>
                  <ul></ul>
                  <Link
                  style={{ color: "#142841", fontWeight:"bolder" }}
                    to="/sitemap"
                    onClick={() => {
                      navigator();
                    }}
                  >
                    SiteMap
                  </Link>
                </div>
                <div className="d-flex flex-column">
                  <a
                    href="https://www.facebook.com/PTPTIPSAU/"
                    style={{ color: "#142841" }}
                    target="blank"
                  >
                    <Facebook />
                  </a>
                  <ul></ul>
                  <a
                    href="https://www.instagram.com/ptptips/"
                    style={{ color: "#142841" }}
                    target="blank"
                  >
                    <Instagram />
                  </a>
                  <ul></ul>
                  <a
                    href="https://twitter.com/PTPTIPS"
                    style={{ color: "#142841" }}
                    target="blank"
                  >
                    <Twitter />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 my-lg-0 my-10">
              <h3 style={{ fontWeight: "bolder", color: "#44bd32" }}>
                Horse Racing Tips
              </h3>
              <div className="d-flex flex-column mr-18">
                <Link
                  to={"/horse-racing-tips/today"}
                  style={{ color: "#142841", fontWeight:"bolder" }}
>
                  Today's Tips
                </Link>

                <Link
                  to={"/horse-racing-tips/tomorrow"}
                  style={{ color: "#142841", fontWeight:"bolder" }}                >
                  Tomorrow's Tips
                </Link>

                <Link
                  to={"/horse-racing-tips/yesterday"}
                  style={{ color: "#142841", fontWeight:"bolder" }}                >
                  Yesterday's Tips
                </Link>
              </div>
              <h3
                style={{ marginTop: 8, fontWeight: "bolder", color: "#44bd32" }}
              >
                Horse Racing Results{" "}
              </h3>
              <div className="d-flex flex-column mr-18">
                <Link
                  to={"/horse-racing-tips/results/today"}
                  style={{ color: "#142841", fontWeight:"bolder" }}                >
                  Today's Results
                </Link>

                <Link
                  to={"/horse-racing-tips/results/yesterday"}
                  style={{ color: "#142841", fontWeight:"bolder" }}                >
                  Yesterday's Results
                </Link>
              </div>
            </div>

            <div className="col-lg-6 my-lg-0 my-5">
              <h3 style={{ color: "#44bd32", fontWeight:"bolder" }}>Get In Touch </h3>
              <div className="d-flex flex-column">
                <p className="text-white opacity-55 text-hover-primary">
                  <strong style={{ color: "#142841" }}>E-mail: </strong>
                  <a
                    style={{ color: "#44bd32" }}
                    href="mailto:support@ptptips.com.au"
                  >
                    support@ptptips.com.au
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-8">
              <p
                
                style={{ textAlign: "justify", color: "#142841" }}
              >
                <strong>
                  <br />
                  18+ Know When To Stop. Don’t Go Over The Top. Gamble
                  Responsibly. Think! About Your Choices. Call Gambling Help On
                  1800 858 858 Or Visit{" "}
                  <a
                    style={{ color: "#44bd32" }}
                    href="https://www.gamblinghelponline.org.au/"
                    target="blank"
                  >
                    www.gamblinghelponline.org.au{" "}
                  </a>
                  -
                  <a
                    style={{ color: "#44bd32" }}
                    href="http://www.gamblinghelp.nsw.gov.au/"
                    target="blank"
                  >
                    {" "}
                    www.gambleaware.nsw.gov.au
                  </a>
                  {/* Or <a href="https://www.gamblinghelponline.org.au/" target="blank" >www.gamblinghelponline.org.au</a> */}
                </strong>
              </p>
            </div>
          </div>
        </div>
             <div className="d-flex align-items-center justify-content-between flex-lg-row flex-column">
            {/*begin::Copyright*/}

            <div className="d-flex align-items-center order-lg-1 order-2">
              <span className="text-muted font-weight-bold mx-2">
                {new Date().getFullYear()} ©
              </span>

              <span>
                <Link to={"/"} style={{ color: "#44bd32" }}>
                  PAST THE POST TECHNOLOGIES PTY LTD ACN 637 874 992
                </Link>
              </span>
            </div>
            <div className="d-flex align-items-center order-lg-2 order-1 mb-lg-0 mb-5">
              <a
                href="/policy"
                style={{color:"#44bd32"}}
                onClick={() => {
                  navigator();
                }}
              >
                Terms of use & Privacy Policy
              </a>
            </div>
          </div>
          <div className="gcse-search"></div>
     
      </div>
    </div>
  );
};

export default Footer;
