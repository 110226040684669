import React, { lazy, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { Row, Col, CustomInput } from "reactstrap";

import actions from "../../redux/actions/selections";

/* COMPONENTS */
import Last10 from "../last10/Last10";
import Next10 from "../next10/Next10";

import Last10Winners from "../lasts10Winners/Last10Winners";

/* CSS */
import "./Layout.scss";
import HorseRacingTips from "../seoPages/HorseRacingTips";
import { country } from "../../config/constants";
import MelbourneCup from "../../pages/melbourne-cup/MelbourneCup";

/* PAGES */
const Results = lazy(() => import("../../pages/result/Results"));
const Race = lazy(() => import("../../pages/raceNew/Race"));







const Layout = (props) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    }
  }, []);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  const renderRoutes = () => {
    return (
      <Col style={{ padding: 0 }} xs="12" lg="12" xl="8">
        <Switch>


          <Route exact path="/horse-racing-tips/race/*">
            <Redirect to={"/horse-racing-tips/today"} />
          </Route>

          <Route exact path="/horse-racing-tips/results/today">
            <Results />
          </Route>

          <Route exact path="/horse-racing-tips/results/yesterday">
            <Results />
          </Route>

          <Route exact path="/horse-racing-tips/results/tomorrow">
            <Results />
          </Route>

          <Route exact path={`/horse-racing-tips/results/:date`}>
            <Results />
          </Route>

          <Route path={`/horse-racing-tips/:date/:venue/:raceNumber/:id/:tab`}>
            <Race />
          </Route>

          <Route path={`/horse-racing-tips/:date/:venue/:raceNumber/:id`}>
            <Race />
          </Route>

          <Route exact path="/horse-racing-tips">
            <HorseRacingTips />
          </Route>


          <Route exact path="melbourne-cup">
            <MelbourneCup />
          </Route>
        </Switch>

      </Col>
    );
  };


  const mobileLayout = () => {
    try {
      if (country === 'aus') {
        return (
          <Col
            xs="12"
            lg="12"
            style={{ backgroundColor: "#eef0f8", marginTop: 32, marginBottom: 40 }}>
            <Row>
              {renderRoutes()}
              <Col xs="12" lg="3" xl="2" style={{ marginTop: "24px" }}>
                <h5
                  style={{
                    backgroundColor: "#44bd32",
                    height: 32,
                    borderRadius: 4,
                    textAlign: "center",
                    color: "white",
                    fontWeight: "600",
                    margin: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    maxWidth: "350px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}>
                  NEXT PTP TIPS
                </h5>
                <div
                  style={{
                    display: "flex",
                    overflowX: "scroll",
                    overflowY: "hidden",
                    marginBottom: "8px",
                  }}>
                  <Next10 />
                </div>
              </Col>

              <Col xs="12" lg="3" xl="2" style={{ marginTop: "24px" }}>
                <h5
                  style={{
                    backgroundColor: "#44bd32",
                    height: 32,
                    borderRadius: 4,
                    textAlign: "center",
                    color: "white",
                    fontWeight: "600",
                    margin: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    maxWidth: "350px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}>
                  PREVIOUS PTP TIPS
                </h5>
                <div
                  style={{
                    display: "flex",
                    overflowX: "scroll",
                    overflowY: "hidden",
                  }}>
                  <Last10 />
                </div>
              </Col>

              <Col xs="12" lg="3" xl="2">
                <Last10Winners />
              </Col>
            </Row>
          </Col>
        );
      } else {
        return (
          <Col
            xs="12"
            lg="12"
            style={{ backgroundColor: "#eef0f8", marginTop: 32, marginBottom: 40 }}>
            <Row>
              {renderRoutes()}
              <Col xs="12" lg="3" xl="2" style={{ marginTop: "24px" }}>
                <h5
                  style={{
                    backgroundColor: "#44bd32",
                    height: 32,
                    borderRadius: 4,
                    textAlign: "center",
                    color: "white",
                    fontWeight: "600",
                    margin: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    maxWidth: "350px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}>
                  NEXT RACES TO GO
                </h5>
                <div
                  style={{
                    display: "flex",
                    overflowX: "scroll",
                    overflowY: "hidden",
                    marginBottom: "8px",
                  }}>
                  <Next10 />
                </div>
              </Col>

              <Col xs="12" lg="3" xl="2" style={{ marginTop: "24px" }}>
                <h5
                  style={{
                    backgroundColor: "#44bd32",
                    height: 32,
                    borderRadius: 4,
                    textAlign: "center",
                    color: "white",
                    fontWeight: "600",
                    margin: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    maxWidth: "350px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}>
                  PREVIOUS RACES
                </h5>
                <div
                  style={{
                    display: "flex",
                    overflowX: "scroll",
                    overflowY: "hidden",
                  }}>
                  <Last10 />
                </div>
              </Col>

              <Col xs="12" lg="3" xl="2">
                <Last10Winners />
              </Col>
            </Row>
          </Col>
        );
      }
    } catch (err) {
      console.log('Error Mobile Loading Layout ...', err)
    }
  };

  const tabletLayout = () => {
    if (country === 'aus ') {
      return (
        <Col
          xs="12"
          lg="12"
          style={{ backgroundColor: "#eef0f8", marginTop: 32, marginBottom: 40 }}>
          <Row>
            {renderRoutes()}
            <Col>
              <div
                style={{
                  backgroundColor: "#44bd32",
                  height: 32,
                  borderRadius: 4,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <h5
                  style={{
                    textAlign: "center",
                    color: "white",
                    fontWeight: "600",
                    marginTop: 5,
                  }}>
                  NEXT PTP TIPS
                </h5>
              </div>
              <div
                style={{
                  display: "flex",
                  overflowX: "scroll",
                  overflowY: "hidden",
                  marginBottom: "8px",
                }}>
                <Next10 />
              </div>
            </Col>

            <Col>
              <div
                style={{
                  backgroundColor: "#44bd32",
                  height: 32,
                  borderRadius: 4,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <h5
                  style={{
                    textAlign: "center",
                    color: "white",
                    fontWeight: "600",
                    marginTop: 5,
                  }}>
                  PREVIOUS PTP TIPS
                </h5>
              </div>
              <div
                style={{
                  display: "flex",
                  overflowX: "scroll",
                  overflowY: "hidden",
                }}>
                <Last10 />
              </div>
            </Col>

            <Col>
              <Last10Winners />
            </Col>
          </Row>
        </Col>
      );
    } else {
      return (
        <Col
          xs="12"
          lg="12"
          style={{ backgroundColor: "#eef0f8", marginTop: 32, marginBottom: 40 }}>
          <Row>
            {renderRoutes()}
            <Col>
              <div
                style={{
                  backgroundColor: "#44bd32",
                  height: 32,
                  borderRadius: 4,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <h5
                  style={{
                    textAlign: "center",
                    color: "white",
                    fontWeight: "600",
                    marginTop: 5,
                  }}>
                  NEXT TO GO
                </h5>
              </div>
              <div
                style={{
                  display: "flex",
                  overflowX: "scroll",
                  overflowY: "hidden",
                  marginBottom: "8px",
                }}>
                <Next10 />
              </div>
            </Col>

            <Col>
              <div
                style={{
                  backgroundColor: "#44bd32",
                  height: 32,
                  borderRadius: 4,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <h5
                  style={{
                    textAlign: "center",
                    color: "white",
                    fontWeight: "600",
                    marginTop: 5,
                  }}>
                  PREVIOUS PTP TIPS
                </h5>
              </div>
              <div
                style={{
                  display: "flex",
                  overflowX: "scroll",
                  overflowY: "hidden",
                }}>
                <Last10 />
              </div>
            </Col>

            <Col>
              <Last10Winners />
            </Col>
          </Row>
        </Col>
      );
    }
  };

  const selectNRNext = (e) => {
    props.selectNRNext(e.target.checked);
  };

  const selectCTCN = (e) => {
    props.selectCTCN(e.target.checked);
  };

  const selectCTCL = (e) => {
    props.selectCTCL(e.target.checked);
  };

  const selectNRLast = (e) => {
    props.selectNRLast(e.target.checked);
  };

  const browserLayout = () => {
    if (country === 'aus') {
      return (
        <div
          style={{ backgroundColor: "#eef0f8", marginTop: 32, marginBottom: 40 }}>
          <Row>
            <Col xs="12" lg="2" xl="2" style={{ paddingRight: "10px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 5,
                  backgroundColor: "rgb(20, 40, 65)",
                  color: "white",
                  height: 32,
                  paddingTop: "3%",
                  borderRadius: 4,
                  textAlign: "center",
                  fontWeight: "600",
                  fontSize: 14,
                }}>
                NEXT PTP TIPS
                <CustomInput
                  type="checkbox"
                  id="CTCN"
                  label="CTC"
                  checked={props?.showCTCN}
                  onChange={(e) => selectCTCN(e)}
                />
                <CustomInput
                  type="checkbox"
                  id="NRNext"
                  label="N/R"
                  checked={props?.showNextNR}
                  onChange={(e) => selectNRNext(e)}
                />
              </div>
              <div style={{
                height: '900px',
                overflow: 'auto',
                scrollbarWidth: 'none',
              }}>
                <Next10 />
              </div>

            </Col>

            {renderRoutes()}

            <Col xs="12" lg="2" xl="2" style={{ paddingLeft: "10px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 5,
                  backgroundColor: "rgb(20, 40, 65)",
                  color: "white",
                  height: 32,
                  paddingTop: "3%",
                  borderRadius: 4,
                }}>
                {/* <CustomInput type="checkbox" id="MYLast" label="My" checked={props?.showLastMy} onChange={(e) => selectNRNext(e)} /> */}
                <h5
                  style={{
                    textAlign: "center",
                    color: "white",
                    fontWeight: "600",
                    fontSize: 14,
                  }}>
                  PREV PTP TIPS
                </h5>
                <CustomInput
                  type="checkbox"
                  id="CTCL"
                  label="CTC"
                  checked={props?.showCTCL}
                  onChange={(e) => selectCTCL(e)}
                />
                <CustomInput
                  type="checkbox"
                  id="NRLast"
                  label="N/R"
                  checked={props?.showLastNR}
                  onChange={(e) => selectNRLast(e)}
                />
              </div>
              <div style={{
                height: '900px',
                overflowY: 'scroll',
                scrollbarWidth: 'none'
              }}>
                <Last10 />
              </div>

            </Col>
          </Row>

          <Row style={{ paddingLeft: "12.5px", paddingRight: "12.5px" }}>
            <Last10Winners />
          </Row>
        </div>
      );
    } else {
      return (
        <div
          style={{ backgroundColor: "#eef0f8", marginTop: 32, marginBottom: 40 }}>
          <Row>
            <Col xs="12" lg="2" xl="2" style={{ paddingRight: "10px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 5,
                  backgroundColor: "rgb(20, 40, 65)",
                  color: "white",
                  height: 32,
                  paddingTop: "3%",
                  borderRadius: 4,
                }}>
                <h5
                  style={{
                    textAlign: "center",
                    color: "white",
                    fontWeight: "600",
                    fontSize: 14,
                  }}>
                  NEXT RACES TO GO
                </h5>

              </div>
              <div style={{
                height: '900px',
                overflowY: 'scroll',
                scrollbarWidth: 'none'
              }}>
                <Next10 />
              </div>

            </Col>

            {renderRoutes()}

            <Col xs="12" lg="2" xl="2" style={{ paddingLeft: "10px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 5,
                  backgroundColor: "rgb(20, 40, 65)",
                  color: "white",
                  height: 32,
                  paddingTop: "3%",
                  borderRadius: 4,
                }}>
                {/* <CustomInput type="checkbox" id="MYLast" label="My" checked={props?.showLastMy} onChange={(e) => selectNRNext(e)} /> */}
                <h5
                  style={{
                    textAlign: "center",
                    color: "white",
                    fontWeight: "600",
                    fontSize: 14,
                  }}>
                  PREVIOUS RACES
                </h5>

              </div>
              <div style={{
                height: '900px',
                overflowY: 'scroll',
                scrollbarWidth: 'none'
              }}>
                <Last10 />
              </div>

            </Col>
          </Row>

          <Row style={{ paddingLeft: "12.5px", paddingRight: "12.5px" }}>
            <Last10Winners />
          </Row>
        </div>
      );
    }
  };
  const main = () => {
    try {
      if (width < 769) {
        return mobileLayout();
      }
      if (width < 1200 && width > 769) {
        return tabletLayout();
      } else {
        return browserLayout();
      }
    } catch (err) {
      console.log('Error Loading Layout ...', err)
    }
  };

  return <div>{main()}</div>;
};

const mapStateToProps = (state) => ({
  showNextNR: state.selectionReducer.showNextNR,
  showLastNR: state.selectionReducer.showLastNR,
  showCTCN: state.selectionReducer.showCTCN,
  showCTCL: state.selectionReducer.showCTCL,
});

const mapDispatchToProps = (dispatch) => ({
  selectNRNext: (boolean) => dispatch(actions.selectNRNext(boolean)),
  selectCTCN: (boolean) => dispatch(actions.selectCTCN(boolean)),
  selectCTCL: (boolean) => dispatch(actions.selectCTCL(boolean)),
  selectNRLast: (boolean) => dispatch(actions.selectNRLast(boolean)),
  getNextTen: (boolean) => dispatch(actions.getNextTen(boolean)),
  getLastTen: (boolean) => dispatch(actions.getLastTen(boolean)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
