import React from 'react'
import { Link } from 'react-router-dom'
import { Col } from 'reactstrap'

export default function HorseRacingTips() {
  return (
    <>
    <Col xs={12}>
    <h1 style={{ textAlign: "center" }}>
    Free Horse Racing Tips
    </h1>
    <p style={{ textAlign: "justify" }}>
    If you want to go into every horse racing meeting in Australia with confidence, you need stats, data, and
ratings to back you up. Pouring hours of time and research into the Australian Thoroughbred Industry is
one option, but who has the patience for that?
    </p>
    <p style={{ textAlign: "justify" }}>
    There’s an easier way to get ratings on every horse with racing form in Australia on a daily basis! Partner
with Past The Post Tips for free horse racing tips that rank every horse in every race.
    </p>
    <p style={{ textAlign: "justify" }}>
    At PTP Tips, our process is very simple to understand yet highly sophisticated behind the scenes. We
provide the best free horse racing tips by rating every horse in a race with a percentage. The higher the
percentage, the more likely they are to win based on our research.
    </p>
    <p style={{ textAlign: "justify" }}>
    Behind every rating is data about the track condition and each horse’s past performance against others
in the race. We look at everything from the distance they run to the barriers they draw to horse weights
and jockeys. All this information is available to view through <Link to={"/"}>PTP TIPS</Link>, as well as a live odds comparison
table from two Australian licensed bookmakers.
    </p>
    <p style={{ textAlign: "justify" }}>
    Of course, lady luck will always play a role, but with the free horse racing tips from our team, luck is
more likely to land on your side! To put lady luck on your side, get free horse racing tips in Australia
from PTP Tips.
    </p>
    <hr />
  </Col>
  <ul></ul>

  <Col xs={12}>
    <h2 style={{ textAlign: "center" }}>
    Get Your Professional Horse Racing Tips for Free
    </h2>
    <p style={{ textAlign: "justify" }}>
    The free horse racing tips from our team are backed by data and research, and they’re all tips we have
used ourselves to make money on horse racing. When you <Link to={"/register"}>sign up</Link> to Past The Post Tips, you get access
to our professional horse racing tips for free for every thoroughbred meeting in the country.
    </p>
    <p style={{ textAlign: "justify" }}>
    You can sign up for PTP Tips and take advantage of our free horse racing tips and ratings. There are no costs or payment information required for you to access our free horse racing tips.  
    </p>
    <hr />
  </Col>

  <Col xs={12}>
    <h2 style={{ textAlign: "center" }}>
    The Best Free Horse Racing Tips in Australia
    </h2>
    <p style={{ textAlign: "justify" }}>
    Sign up with Past The Post Tips today for the best free horse racing tips and horse racing ratings Australia-wide. 
    </p>
    <p style={{ textAlign: "justify" }}>
    At Past The Post Tips, we proudly publish our success rate right here on our website. We’re happy to say
that our punters are successful a bit more than 21% of the time, which is an average of one in every five
races. If you consider yourself a lucky punter, you might be able to swing the scales even further in your
favour with the comprehensive stats and information available in the best free horse racing tips on the
market.
    </p>
    <p style={{ textAlign: "justify" }}>
    Sign up for FREE with Past The Post Tips and then pay your membership fee and nothing more to
access tips for every race across Australia
    </p>
    <hr />
  </Col>
  </>
  )
}
