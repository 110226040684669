export const { REACT_APP_ENV } = process.env;

export const OLDPTPAPI = 'http://ptpapistaging-env.eba-p6pvum5z.us-east-2.elasticbeanstalk.com'

export const PTPAPI =
  REACT_APP_ENV === "development"
    ? "https://api-staging.ptptips.com.au"
    : REACT_APP_ENV === "staging"
      ? "https://api-staging.ptptips.com.au"
      : "https://api-staging.ptptips.com.au";

export const storage = `https://ptp-storage-images.s3.us-east-2.amazonaws.com/maps/`;

export const storageImages = `https://s3.us-east-2.amazonaws.com/staging.ptptips.com.au/static/media/`;


export const silksURLAWS = 'https://ptp-storage-images.s3.us-east-2.amazonaws.com/silks/';

export const awsIcons = 'https://ptp-storage-images.s3.us-east-2.amazonaws.com/icons/'
// https://ptp-storage-images.s3.us-east-2.amazonaws.com/reviews/getsetbet/

export  const getSetBet = 'https://ptp-storage-images.s3.us-east-2.amazonaws.com/reviews/getsetbet/';
export const country = localStorage.getItem('country') ? localStorage.getItem('country') : localStorage.setItem('country', 'aus')


// export const PTPAPI =
//   REACT_APP_ENV === 'development'
//     ? 'http://localhost:3051/api'
//     : REACT_APP_ENV === 'staging'
//     ? 'http://localhost:3051/api'
//     : 'http://localhost:3051/api';
