import axios from "axios";
import { PublicApiCall } from "./interceptors";

const hostApi =
  process.env.NODE_ENV === "development" ? "http://localhost" : "";
const portApi = process.env.NODE_ENV === "development" ? 3051 : "";
// const serverUrl =
//   process.env.NODE_ENV === "development" ? `http://localhost:4051` : "";
const usersURLApi = `${hostApi}${portApi ? `:${portApi}` : ``}/users`;
const apiURLApi = `${hostApi}${portApi ? `:${portApi}` : ``}/api`;
const updateApi = `${hostApi}${portApi ? `:${portApi}` : ``}/update`;

export const STRIPE_PUBLISHABLE_KEY =
  "pk_test_eb8wQrYMUW2i0PyZiwt3FPhu005L3aAq1b";
export const publicVapidKey =
  "BLhr5SZ93oBs6dbEq0EvUgn72Yxp28hzNLa5_FQG-AS0OUxbwgUQnPGf9W4NFq_0dQ02lxLOIj409zpJMJ4Kd-4"; // web-push key

const headers = {
  headers: { Authorization: `PTPTIPS ` + localStorage.PTPToken },
};

const errorCatch = (error, string) => {
  if (error) {
    if (error.response) {
      if (error.reponse?.data) {
        return error.response?.data;
      }
      return error.response;
    }
    return error;
  } else {
    return string;
  }
};


// Login with Google

async function loginGoogle(data) {
  let object = {
    email: data.email,
    first_name: data.first_name,
    last_name: data.last_name,
    provider: 'google',
    email_verified: true,
  }
  const apiCall = await PublicApiCall.post("/auth/googlelogin", object)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error Login"));
  return apiCall;
}

async function getIpByApi() {
  const api = await axios.get('https://extreme-ip-lookup.com/json/?key=dGOFtHuciABzCucdC920');
  return api
}


async function loginUser(data) {
  const apiCall = await PublicApiCall.post("/auth/login", data, headers)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error Login"));
  return apiCall;
}

async function forgotPass(email) {
  let data = {
    email: email
  }
  const apiCall = await PublicApiCall.post("/auth/forgotpass", data)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error Helping you recoving your password"));
  return apiCall;
}

async function resetPassword(data, token) {
  const apiCall = await PublicApiCall.post(
    `/auth/resetpassword/${token}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error Resetting your password"));
  return apiCall;
}


async function contactUs(data) {
  const apiCall = await PublicApiCall.post("/contactus/message", data, headers)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error Login"));
  return apiCall;
}

async function updateUserName(data, uuid) {
  const apiCall = await PublicApiCall.put(
    `/user/profile/${uuid}`,
    data,
    headers
  )
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error Login"));
  return apiCall;
}
async function updateProfile(data, uuid) {
  const apiCall = await PublicApiCall.put(
    `/user/profile/${uuid}`,
    data,
    headers
  )
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error Login"));
  return apiCall;
}
async function verifyAccount(uuid) {
  const apiCall = await PublicApiCall.put(`/auth/verifyemail/${uuid}`, headers)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error Login"));
  return apiCall;
}
async function updateMailingDates(data, uuid) {
  const apiCall = await PublicApiCall.put(
    `/user/profile/${uuid}`,
    data[0],
    headers
  )
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error Update Mailing Dates"));
  return apiCall;
}
async function getHomeData() {
  const apiCallM = await PublicApiCall.get(`/home/monthlyselections`)
    .then((response) => response?.data)
    .catch((error) => errorCatch(error, "Error getting monthly Selections"));
  const apiCallD = await PublicApiCall.get(`/home/dailyselections`)
    .then((response) => response?.data)
    .catch((error) => errorCatch(error, "Error getting monthly Selections"));
  return { apiCallM, apiCallD };
}
async function getnext10tips(boolean, headers, token) {
  const apiCall = await PublicApiCall.post(
    "/races/next10",
    boolean,
    headers,
    token
  )
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error Getting Next 10 Tips"));
  return apiCall;
}
async function getlast10tips(data) {
  const apiCall = await PublicApiCall.post("/races/last10", data, headers)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error Login"));
  return apiCall;
}
async function getselections(date) {
  const apiCall = await PublicApiCall.get(`/selections/${date}`)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error Selections by date"));
  return apiCall;
}
async function SwitchVenues(data) {
  data = {
    date: data?.data?.selectedDate,
    uuid: data?.data?.uuid
  }
  const apiCall = await PublicApiCall.post(
    `/races/venueswitch`,
    data,
    headers
  )
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error getting inside venue"));
  return apiCall;
}
async function getVenueselectionsOpt(data) {
  const apiCall = await PublicApiCall.post(
    `/venues/date`,
    data
  )
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error getting Venues Information"));
  return apiCall;
}
/********************************Results Page ************************************************************************** */
//
async function getResults(data) {
  const apiCall = await PublicApiCall.get(`/results/${data?.passDate}`)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error getting Result Information"));
  return apiCall;
}
async function getRaceInfoOptimized(data) {

  const apiCall = await PublicApiCall.get(`/races/details/${data?.raceId}`)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error getting Race Information"));
  return apiCall;
}
async function getRaceInsideRace(uuid) {
  const apiCall = await PublicApiCall.get(`/races/racesinsiderace/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error getting Race Information"));
  return apiCall;
}

/******************************************NEXT PREV ARROW FUNCTION******************************************************************* */

async function getRaceInfoOptimizedNextPrev(data) {
  const apiCall = await PublicApiCall.post(`/races/nextlastrace`, data, headers)
    .then((response) => response)
    .catch((error) => errorCatch(error));
  return apiCall;
}

/************************************************************************************************************* */
/* remove after optimisation */
// async function getRaceInfoNextOrLast(data) {
//   const url = `${apiURLApi}/getRaceInfoNextOrLast`;
//   return await axios.post(url, data, headers).then(response => response?.data);
// }
/************************************************************************************************************ */

/************************************************** REGISTER PAGE ******************************************** */

// async function registerAds(data) {
//   const url = `${usersURLApi}/registerAds`;
//   return await axios.post(url, data, headers).then((response) => response.data);
// }

async function registerStep1(data) {
  const apiCall = await PublicApiCall.post("/auth/register", data, headers)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error Login"));
  return apiCall;
}
async function last10Winners() {
  const url = await PublicApiCall.get(`/races/last10winners`)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error last10winners"));
  return url;
}
async function getHorseProfile(id) {
  const apiCall = await PublicApiCall.get(`/profiles/horse/${id}`, headers)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error Getting Horse Profile"));
  return apiCall;
}
async function getJockeyProfile(uuid) {
  const apiCall = await PublicApiCall.get(`/profiles/jockey/${uuid}`, headers)
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error Getting Jockey Profile"));
  return apiCall;
}
async function getVenueProfile(data) {
  const apiCall = await PublicApiCall.get(
    `/profiles/venue/${data?.uuid}`,
    headers
  )
    .then((response) => response)
    .catch((error) => errorCatch(error, "Error Getting Horse Profile"));
  return apiCall;
}
async function search(value) {
  const apiCall = await PublicApiCall.get(`/search?value=${value}`)
    .then((response) => response?.data)
    .catch((error) => errorCatch(error, "Error getting search Selections"));
  return apiCall;
}

/**Used but not yet .... */
async function alreadyLoggedIn(data) {
  const url = `${usersURLApi}/alreadyLoggedIn`;
  return await axios
    .post(url, data, headers)
    .then((response) => response?.data);
}
async function getBlackBookDetails(uuid) {
  return await PublicApiCall.get(`/blackbook/all/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}
async function CreateblackBook(data) {
  return await PublicApiCall.post('/blackbook/create', data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}
async function DeleteBlackBookInfo(uuid) {
  return await PublicApiCall.delete(`/blackbook/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}
async function UpBlkBook(data, uuid) {
  return await PublicApiCall.put(`/blackbook/${uuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

/*==================================================OLD====================================================*/

async function logoutUserAPI(email) {
  const url = `${usersURLApi}/logoutUser`;
  return await axios
    .post(url, email, headers)
    .then((response) => response?.data);
}

async function cancelSubscription(email) {
  const url = `${usersURLApi}/cancelSubscription`;
  return await axios
    .post(url, email, headers)
    .then((response) => response?.data);
}
async function unsubscribeUserEmail(id) {
  const url = `${usersURLApi}/unsubscribe/${id}`;
  return await axios.get(url, headers).then((response) => response?.data);
}

async function subscribeUserEmail(id) {
  const url = `${usersURLApi}/subscribe/${id}`;
  return await axios.get(url, headers).then((response) => response?.data);
}
async function checkUserPromo(id) {
  const url = `${usersURLApi}/checkUserPromo/${id}`;
  return await axios.get(url, headers).then((response) => response?.data);
}
async function seenPromo(data) {
  const url = `${usersURLApi}/seenPromo`;
  return await axios
    .post(url, data, headers)
    .then((response) => response?.data);
}
async function notifyMe(data) {
  const url = `${usersURLApi}/notification`;
  return await axios.post(url, data, headers).then((response) => response);
}
async function getFutureTips() {
  const url = `${apiURLApi}/futureTips`;
  return await axios.get(url, headers).then((response) => response?.data);
}
// async function getRaceInfoOptimizedNextPrev(data) {
//   const url = `${apiURLApi}/getRaceOptNextPrev`;
//   return await axios
//     .post(url, data, headers)
//     .then((response) => response?.data);
// }
async function NextTojump(data) {
  const url = `${apiURLApi}/NextTojump`;
  return await axios
    .post(url, data, headers)
    .then((response) => response?.data.data);
}
async function getGenerationTime(data) {
  const url = `${apiURLApi}/generationTime`;
  return await axios
    .post(url, data, headers)
    .then((response) => response?.data.data);
}
async function updateOdds(data) {
  const url = `${updateApi}/OddsSUpdate/${data.getID}`;
  return await axios.get(url, headers).then((response) => response?.data);
}
async function getRaces(date, trackCode) {
  const url = `${apiURLApi}/races`;
  let data = { date: date, trackCode: trackCode };
  return await axios
    .post(url, data, headers)
    .then((response) => response?.data);
}
async function getRacesByDate(date) {
  const url = `${apiURLApi}/racebydate`;
  let data = { date: date };
  return await axios
    .post(url, data, headers)
    .then((response) => response?.data);
}
async function getFormings(data) {
  const url = `${apiURLApi}/formings`;
  return await axios
    .post(url, data, headers)
    .then((response) => response?.data);
}

async function loadRaceForm(item) {
  const url = `${apiURLApi}/loadRaceForm`;
  return await axios
    .post(url, item, headers)
    .then((response) => response?.data);
}

async function loadSingleRace(data) {
  const url = `${apiURLApi}/loadSingleRace`;
  return await axios
    .post(url, data, headers)
    .then((response) => response?.data);
}

async function GetRaceResultsAnalytics(data) {
  const url = `${apiURLApi}/raceResultAnalytics`;
  return await axios.post(url, data).then((response) => response?.data);
}

async function getAllScratching(data) {
  const url = `${apiURLApi}/allScratchings`;
  return await axios
    .post(url, data, headers)
    .then((response) => response?.data);
}

async function getTrainerProfile(data) {
  const url = `${apiURLApi}/trainerProfile`;
  return await axios
    .post(url, data, headers)
    .then((response) => response?.data);
}

async function getJockeyInForm() {
  const url = `${apiURLApi}/jockeyInForm`;
  return await axios.get(url, headers).then((response) => response?.data);
}

async function getVenueRaces(data) {
  const url = `${apiURLApi}/racesInVenue`;
  return await axios
    .post(url, data, headers)
    .then((response) => response?.data);
}

async function loadVenues() {
  const url = `${apiURLApi}/loadvenues`;
  return await axios.get(url, headers).then((response) => response?.data);
}

async function loadSavedRailPosition(data) {
  const url = `${apiURLApi}/loadsavedrp`;
  return await axios
    .post(url, data, headers)
    .then((response) => response?.data);
}

async function listVenueComments(data) {
  const url = `${apiURLApi}/listCmts`;
  return await axios
    .post(url, data, headers)
    .then((response) => response?.data);
}

async function getHorseJockeyTrainerForAnalytics(data) {
  const url = `${apiURLApi}/getHorseJockeyTrainer`;
  return await axios
    .post(url, data, headers)
    .then((response) => response?.data.data);
}

async function getVenuesForAnalytics() {
  const url = `${apiURLApi}/getVenuesInDateForAnalytics`;
  return await axios.get(url, headers).then((response) => response?.data.data);
}

async function getAnalyticsResults(data) {
  const url = `${apiURLApi}/getAnalyticsBasedOnDate`;
  return await axios
    .post(url, data, headers)
    .then((response) => response?.data.data);
}

async function getHorseJockeyTrainerId() {
  const url = `${apiURLApi}/getHorseJockeyTrainerId`;
  return await axios.get(url, headers).then((response) => response?.data);
}

// async function getTop10HorseJockeyTrainer() {
//   const url = `${apiURLApi}/getTop10HorseJockeyTrainer`;
//   return await axios.get(url, headers).then(response => response?.data);
// }

// async function getTop10PerformingVenues() {
//   const url = `${apiURLApi}/getTop10PerformingVenues`;
//   return await axios.get(url, headers).then(response => response?.data);
// }
// async function getTop10Performingtrainers() {
//   const url = `${apiURLApi}/getTop10Performingtrainers`;
//   return await axios.get(url, headers).then(response => response?.data);
// }
// async function getTop10PerformingJockeys() {
//   const url = `${apiURLApi}/getTop10PerformingJockeys`;
//   return await axios.get(url, headers).then(response => response?.data);
// }
// async function getTop10PerformingHorses() {
//   const url = `${apiURLApi}/getTop10PerformingHorses`;
//   return await axios.get(url, headers).then(response => response?.data);
// }

// async function bestPerformingHorse() {
//   const url = `${apiURLApi}/bestPerformingHorse`;
//   return await axios.get(url, headers).then(response => response?.data);
// }

// async function bestPerformingJockey() {
//   const url = `${apiURLApi}/bestPerformingJockey`;
//   return await axios.get(url, headers).then(response => response?.data);
// }

// async function bestPerformingVenueDetails(data) {
//   const url = `${apiURLApi}/bestPerformingVenueDetails`;
//   return await axios.post(url, data, headers).then(response => response?.data);
// }

// async function bestPerformingHorseDetails(data) {
//   const url = `${apiURLApi}/bestPerformingHorseDetails`;
//   return await axios.post(url, data, headers).then(response => response?.data);
// }

// async function bestPerformingJockeyDetails(data) {
//   const url = `${apiURLApi}/bestPerformingJockeyDetails`;
//   return await axios.post(url, data, headers).then(response => response?.data);
// }

async function bestPerformance(data) {
  const url = `${apiURLApi}/bestPerformance`;
  return await axios
    .post(url, data, headers)
    .then((response) => response?.data);
}

async function bestPerformingVenue(data) {
  const url = `${apiURLApi}/bestPerformingVenue`;
  return await axios
    .post(url, data, headers)
    .then((response) => response?.data);
}
///search?value=

export {
  loginGoogle,
  loginUser,
  alreadyLoggedIn,
  logoutUserAPI,
  registerStep1,
  updateUserName,
  getHomeData,
  getnext10tips,
  getlast10tips,
  getselections,
  getResults,
  // getRaceInfo,
  getRaceInfoOptimized,
  getRaceInfoOptimizedNextPrev,
  // getRaceInfoNextOrLast,
  // getVenueselections,
  SwitchVenues,
  getVenueselectionsOpt,
  last10Winners,
  NextTojump,
  contactUs,
  updateProfile,
  verifyAccount,
  updateOdds,
  forgotPass,
  resetPassword,
  cancelSubscription,
  //checkSessionID,
  //saveSessionID,
  getGenerationTime,
  updateMailingDates,
  unsubscribeUserEmail,
  subscribeUserEmail,
  checkUserPromo,
  seenPromo,
  // getSubscription,
  // registerAds,
  getRacesByDate,
  getFormings,
  loadRaceForm,
  getRaces,
  loadSingleRace,
  GetRaceResultsAnalytics,
  getAllScratching,
  getHorseProfile,
  getJockeyProfile,
  getTrainerProfile,
  getJockeyInForm,
  getVenueRaces,
  notifyMe,
  loadVenues,
  loadSavedRailPosition,
  listVenueComments,
  getHorseJockeyTrainerForAnalytics,
  getVenuesForAnalytics,
  getAnalyticsResults,
  getHorseJockeyTrainerId,
  getVenueProfile,
  getFutureTips,
  getBlackBookDetails,
  CreateblackBook,
  DeleteBlackBookInfo,
  UpBlkBook,
  bestPerformance,
  bestPerformingVenue,
  search,
  getRaceInsideRace,
  getIpByApi
};
