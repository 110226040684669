import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import Last10 from '../last10/Last10'
import Next10 from '../next10/Next10'

export default function Friday() {

  const render = () => {
    return (
      <Col style={{ marginTop: "24px", marginLeft: "15px", marginRight: "15px", fontSize: "15px" }}>
        <div ref={ref}>
          <Col xs={12}>
            <h1 style={{ textAlign: "center" }}>
              Friday’s Free Horse Racing Tips
            </h1>
            <p style={{ textAlign: "justify" }}>
              Are you heading to the track this Friday? Plan your bets in advance with Past The Post Tips. Our team
              provides data for every Australian thoroughbred horse race at every track in the country. If you’re
              looking for horse racing tips for Friday or any other day of the week, you’re in the right place.
            </p>
            <p style={{ textAlign: "justify" }}>
              At PTP Tips, our process is simple. We rate every horse in every race and then rank them in order. The
              horse at the top of each list has the highest likelihood of winning their race according to our system.
              We’ve been using this method ourselves for years, and now you can take advantage of it too.
            </p>
            <p style={{ textAlign: "justify" }}>
              If you’re wondering how we rank the horses, you’ll be happy to know that the data is there for you to
              see. We take a range of factors into consideration, including each horse’s historical performance on the
              track, and how they’ve competed against every other horse in the race. We also look at the barriers
              each horse draws, the jockeys in the race, the condition of the track, and much more.
            </p>
            <p style={{ textAlign: "justify" }}>
              PTP Tips also displays live odds from two bookmakers so you can see how our rankings match up with
              the odds.
            </p>
            <h6 style={{ textAlign: "justify" }}>
              View Friday’s Free Horse Racing Tips Now.
            </h6>
            <hr />
          </Col>
          <ul></ul>

          <Col xs={12}>
            <h2 style={{ textAlign: "center" }}>
              Australian Horse Racing Tips 7 Days a Week, Including Friday!
            </h2>
            <p style={{ textAlign: "justify" }}>
              Our horse racing tips for Friday are up to date and accurate, with all the latest data that we believe
              could affect the outcome of your race. If you want to make sure you’ve accounted for any late changes,
              head to our <Link to={"/horse-racing-tips/today"}>tips for today</Link> on race day. If a horse has been scratched, track conditions have changed, or
              a jockey has pulled out, you’ll find this information and the latest rankings in the today’s tips section.
            </p>
            <p style={{ textAlign: "justify" }}>
              Our rankings for races due to take place today are updated between 9 am and 11 am.
            </p>
            <hr />
          </Col>

          <Col xs={12}>
            <h2 style={{ textAlign: "center" }}>
              Get the Best Free Horse Racing Tips for Friday with Past The Post
            </h2>
            <p style={{ textAlign: "justify" }}>
              Check our free horse racing tips for Friday during the week and ensure you have the latest and most up to date rankings
              on race day. To access all the rankings and data available from PTP Tips, all you need to do is sign up.
            </p>
            <p style={{ textAlign: "justify" }}>
              The data and insights offered by PTP Tips are all-inclusive for every race in Australia. <Link to={"/register"}>Get started now!</Link>
            </p>
            <hr />
          </Col>
        </div>
      </Col>
    )

  }

  const ref = useRef(null)

  const [height, setHeight] = useState(0);

  useEffect(() => {
    console.log(ref)
    setHeight(ref.current?.clientHeight)
  }, [])



  if (window.innerWidth > 1200) {
    return (
      <Row>
        <Col className="d-block col-md-2" style={{ overflowY: "auto", marginTop: "24px", height: height, marginLeft: "10px" }}>
          <h5 style={{
            backgroundColor: "#142841",
            height: 32,
            borderRadius: 4,
            textAlign: "center",
            color: "white",
            fontWeight: "600",
            margin: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: "350px",
            marginLeft: "auto",
            marginRight: "auto",
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}>Next PTP TIPS</h5>
          <Next10 />
        </Col>
        {render()}
        <Col className="d-block col-2" style={{ overflowY: "auto", marginTop: "24px", height: height, marginRight: "10px" }}>
          <h5 style={{
            backgroundColor: "#142841",
            height: 32,
            borderRadius: 4,
            textAlign: "center",
            color: "white",
            fontWeight: "600",
            margin: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: "350px",
            marginLeft: "auto",
            marginRight: "auto",
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}>PREV PTP TIPS</h5>
          <Last10 />
        </Col>

      </Row>
    )


  } else {
    return (
      <Row>

        {render()}

        <Col style={{ marginTop: "24px" }}>
          <h5
            style={{
              backgroundColor: "#142841",
              height: 32,
              borderRadius: 4,
              textAlign: "center",
              color: "white",
              fontWeight: "600",
              margin: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              maxWidth: "350px",
              marginLeft: "auto",
              marginRight: "auto",
            }}>
            NEXT PTP TIPS
          </h5>
          <div
            style={{
              display: "flex",
              overflowX: "scroll",
              overflowY: "hidden",
              marginBottom: "8px",
            }}>
            <Next10 />
          </div>
        </Col>

        <Col style={{ marginTop: "24px" }}>
          <h5
            style={{
              backgroundColor: "#142841",
              height: 32,
              borderRadius: 4,
              textAlign: "center",
              color: "white",
              fontWeight: "600",
              margin: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              maxWidth: "350px",
              marginLeft: "auto",
              marginRight: "auto",
            }}>
            PREV PTP TIPS
          </h5>
          <div
            style={{
              display: "flex",
              overflowX: "scroll",
              overflowY: "hidden",
              marginBottom: "10px",
            }}>
            <Last10 />
          </div>
        </Col>

      </Row>
    )
  }
}
