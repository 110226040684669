import { last10Winners } from "../../config/config";

export const last10Winners_FETCH_STARTED = "last10Winners_FETCH_STARTED";
export const last10Winners_FETCH_SUCCESS = "last10Winners_FETCH_SUCCESS";
export const last10Winners_FETCH_ERROR = "last10Winners_FETCH_ERROR";

const actions = {
  fetchWinners: () => async (dispatch) => {
    try {
      dispatch({
        type: last10Winners_FETCH_STARTED,
      });
      await last10Winners().then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: last10Winners_FETCH_SUCCESS,
            payload: {
              info: response?.data?.data,
            },
          });
        } else {
          dispatch({
            type: last10Winners_FETCH_ERROR,
          });
        }
      });
    } catch (error) {
      dispatch({
        type: last10Winners_FETCH_ERROR,
      });
    }
  },
};

export default actions;
