import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";

const Lock = (props) => {
  const renderPercentages = (val) => {
    if (props?.currentUser) {
      return val;
    } else {
      return (
        <div onClick={()=>props.history.push('/register')} title="Sign in or Register To View Selections" >
            <FontAwesomeIcon
              icon={faLock}
              size="1x"
              style={{ marginRight: 4, color: "rgb(20, 40, 65)" }}
            />
        </div>
      );
    }
  };
  return renderPercentages(props?.data);
};

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
});

export default withRouter(connect(mapStateToProps)(Lock));
